// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map {
  width: 100%;
}
.map-container {
  margin: 0 90px 40px 90px;
}
.map-container img {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/map/styles/styles.less","webpack://./src/components/map/styles/styles.css"],"names":[],"mappings":"AAAA;EAEE,WAAA;ACAF;ADCE;EACE,wBAAA;ACCJ;ADFE;EAGI,WAAA;ACEN","sourcesContent":[".map{\n  //display: none;\n  width: 100%;\n  &-container{\n    margin: 0 90px 40px 90px;\n    img{\n      width: 100%;\n    }\n  }\n}\n",".map {\n  width: 100%;\n}\n.map-container {\n  margin: 0 90px 40px 90px;\n}\n.map-container img {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
