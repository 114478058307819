import React from 'react';
import PropTypes from 'prop-types';
import TabList from "./tab_list";

const DropdownTabs = ({ activeTab, handleTabOptionClick }) => {
    const tabs = [
        { id: 'OBJECTS', label: 'Objects' },
        { id: 'SEARCH', label: 'Search' },
    ];

    return <TabList tabs={tabs} activeTab={activeTab} handleTabClick={handleTabOptionClick} />;
};

DropdownTabs.propTypes = {
    activeTab: PropTypes.string.isRequired,
    handleTabOptionClick: PropTypes.func.isRequired,
};

export default DropdownTabs;
