/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {getPropertyType, searchByCity} from '../../action/object_api';
import LocationFilter from './location';
import RoomFilter from './rooms';
import PropertyFilter from './property';
import PriceFilter from './price';
import AreaFilter from './area';
import Down from '../../assets/images/icon/down.svg';
import SearchBtnIcon from '../../assets/images/btns/search_btn.svg';
import MapMobile from '../../assets/images/icon/map_btn.svg';
import Sort from '../../assets/images/icon/sort.svg';
import './styles/styles.css';
import './styles/@styles.css';
import {useFilters} from "../../context/SearchContext";
import {useTranslation} from "react-i18next";
import {usePageContext} from "../../context/PageContext";

const Filters = ({ onSearch, onSaveFiltersChange }) => {
    const { t } = useTranslation();
    const { setCurrentPage } = usePageContext()
    const { savedSearch, selectedFilters, setSelectedFilters } = useFilters();
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationSearchResults, setLocationSearchResults] = useState([]);
    const [isLocationActive, setIsLocationActive] = useState(false);
    const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
    const [locationSearchValue, setLocationSearchValue] = useState('');
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [propertyTypeSearchResults, setPropertyTypeSearchResults] = useState([]);
    const [isPropertyTypeDropdownOpen, setIsPropertyTypeDropdownOpen] = useState(false);
    const [selectedPropertyTypeStatus, setSelectedPropertyTypeStatus] = useState({});
    const [isRoomDropdownOpen, setIsRoomDropdownOpen] = useState(false);
    const [selectedRoomOptions, setSelectedRoomOptions] = useState([]);
    const [selectedPropertyTypeOptions, setSelectedPropertyTypeOptions] = useState([]);
    const [isPropertyTypeActive, setIsPropertyTypeActive] = useState(false);
    const [isRoomActive, setIsRoomActive] = useState(false);
    const [isPriceDropdownOpen, setIsPriceDropdownOpen] = useState(false);
    const [isPriceActive, setIsPriceActive] = useState(false);
    const [priceFrom, setPriceFrom] = useState('');
    const [priceTo, setPriceTo] = useState('');
    const [isAreaDropdownOpen, setIsAreaDropdownOpen] = useState(false);
    const [isAreaActive, setIsAreaActive] = useState(false);
    const [areaFrom, setAreaFrom] = useState('');
    const [areaTo, setAreaTo] = useState('');
    const [isDropdownOpen] = useState(false);
    const [isDropdownUserOpen, setDropdownUserOpen] = useState(false);
    const [selectedSortOption, setSelectedSortOption] = useState(
        localStorage.getItem('selectedSortOption') || 'Lowest Price'
    );
    const [savedFilters, setSavedFilters] = useState({
        propertyType: [],
        beds: '',
        baths: '',
        priceFrom: '',
        priceTo: '',
        areaFrom: '',
        areaTo: '',
        city: '',
    });
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [localSelectedPropertyTypeStatus, setLocalSelectedPropertyTypeStatus] = useState({});

    const dropDownRef = useRef(null);
    const sortingDropdownRef = useRef(null);

    useEffect(() => {
        if (savedSearch && Object.keys(savedSearch).length > 0) {
            setLocationSearchValue(savedSearch.city || '');

            const savedPropertyTypes = savedSearch.propertyType || [];
            setSelectedPropertyTypeOptions(savedPropertyTypes);

            const propertyTypeStatus = {};
            propertyTypes.forEach(type => {
                propertyTypeStatus[type] = savedPropertyTypes.includes(type);
            });
            setSelectedPropertyTypeStatus(propertyTypeStatus);
            if (savedSearch.beds !== null) {
                setSelectedRoomOptions(prevOptions => ({
                    ...prevOptions,
                    Beds: String(savedSearch.beds || ''),
                    Baths: String(savedSearch.baths || ''),
                }));
            }
            setPriceTo(String(savedSearch.priceTo || ''));
            setPriceFrom(String(savedSearch.priceFrom || ''));
            setAreaTo(String(savedSearch.areaTo || ''));
            setAreaFrom(String(savedSearch.areaFrom || ''));

            if (onSearch) {
                onSearch(savedSearch);
            }
        }
    }, [savedSearch]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
                setIsPropertyTypeDropdownOpen(false);
                setIsPropertyTypeActive(false);
                setIsRoomDropdownOpen(false);
                setIsRoomActive(false);
                setIsPriceDropdownOpen(false);
                setIsPriceActive(false);
                setIsAreaDropdownOpen(false);
                setIsAreaActive(false);
                setIsLocationDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropDownRef]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sortingDropdownRef.current && !sortingDropdownRef.current.contains(event.target)) {
                setDropdownUserOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getPropertyType();
                if (Array.isArray(response.data) && response.data.length > 0) {
                    setPropertyTypes(response.data);
                    setPropertyTypeSearchResults(response.data);
                } else {
                    console.error('Empty or undefined propertyTypes in the response:', response);
                }
            } catch (error) {
                console.error('Error fetching property types:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (locationSearchValue) {
            handleLocationSearch();
        } else {
            setLocationSearchResults([]);
        }
    }, [locationSearchValue]);

    useEffect(() => {
        localStorage.setItem('selectedSortOption', selectedSortOption);
    }, [selectedSortOption]);

    useEffect(() => {
        setSavedFilters({
            propertyType: selectedPropertyTypeOptions,
            beds: selectedRoomOptions.Beds || '',
            baths: selectedRoomOptions.Baths || '',
            priceFrom: priceFrom,
            priceTo: priceTo,
            areaFrom: areaFrom,
            areaTo: areaTo,
            city: locationSearchValue,
        });
        onSaveFiltersChange({
            propertyType: selectedPropertyTypeOptions,
            beds: selectedRoomOptions.Beds || '',
            baths: selectedRoomOptions.Baths || '',
            priceFrom: priceFrom,
            priceTo: priceTo,
            areaFrom: areaFrom,
            areaTo: areaTo,
            city: locationSearchValue,
        });
    }, [selectedPropertyTypeOptions, selectedRoomOptions, priceFrom, priceTo, areaFrom, areaTo, locationSearchValue, onSaveFiltersChange]);

    useEffect(() => {
        const hasActiveFilter = Object.entries(savedFilters).some(([key, value]) => {
            if (key === 'propertyType') {
                return selectedPropertyTypeOptions.length > 0;
            }
            return parseFloat(value) > 0;
        });
        setIsFilterActive(hasActiveFilter);
    }, [savedFilters, selectedPropertyTypeOptions]);

    useEffect(() => {
        setLocalSelectedPropertyTypeStatus(getCombinedPropertyTypeStatus());
    }, [selectedPropertyTypeStatus, savedSearch, selectedFilters]);

    const getCombinedPropertyTypeStatus = () => {
        const combinedStatus = { ...localSelectedPropertyTypeStatus };
        if (savedSearch && savedSearch.propertyType) {
            savedSearch.propertyType.forEach((type) => {
                combinedStatus[type] = true;
            });

        }
        if (selectedFilters && selectedFilters.propertyType) {
            selectedFilters.propertyType.forEach((type) => {
                combinedStatus[type] = true;
            });

        }
        return combinedStatus;
    };
    const handleLocationSearch = async () => {
        try {
            const response = await searchByCity(locationSearchValue);
            if (response && response.content) {
                setLocationSearchResults(response.content);
            }
        } catch (error) {
            console.error('Error searching by city:', error);
        }
    };
    const handleLocationSelect = (location) => {
        setLocationSearchValue(location.city);
        setSelectedLocation(location);

        setSavedFilters((prevFilters) => ({
            ...prevFilters,
            location: location.city,
        }));

        setLocationSearchResults([]);
    };
    const toggleLocationDropdown = () => {
        setIsLocationDropdownOpen(!isLocationDropdownOpen);
        setIsLocationActive(!isLocationActive);
        setIsRoomActive(false);
        setIsRoomDropdownOpen(false);
        setIsPropertyTypeDropdownOpen(false);
        setIsPropertyTypeActive(false);
        setIsPriceActive(false);
        setIsPriceDropdownOpen(false);
        setIsAreaActive(false);
        setIsAreaDropdownOpen(false);
    }
    const togglePropertyTypeDropdown = () => {
        setIsPropertyTypeDropdownOpen(!isPropertyTypeDropdownOpen);
        setIsPropertyTypeActive(!isPropertyTypeActive);
        setIsLocationDropdownOpen(false);
        setIsLocationActive(false);
        setIsRoomActive(false);
        setIsRoomDropdownOpen(false);
        setIsPriceActive(false);
        setIsPriceDropdownOpen(false);
        setIsAreaActive(false);
        setIsAreaDropdownOpen(false);
    };
    const handlePropertyTypeOptionClick = (selectedPropertyType, event) => {
        const isSelected = selectedPropertyTypeOptions.includes(selectedPropertyType);

        setSelectedPropertyTypeOptions((prevOptions) =>
            isSelected
                ? prevOptions.filter((option) => option !== selectedPropertyType)
                : [...prevOptions, selectedPropertyType]
        );

        setSelectedPropertyTypeStatus((prevStatus) => ({
            ...prevStatus,
            [selectedPropertyType]: !isSelected,
        }));

        setSavedFilters((prevFilters) => {
            const updatedPropertyType = isSelected
                ? (prevFilters.propertyType || []).filter((type) => type !== selectedPropertyType)
                : [...(prevFilters.propertyType || []), selectedPropertyType];

            return {
                ...prevFilters,
                propertyType: updatedPropertyType,
            };
        });

        if (setSelectedFilters) {
            setSelectedFilters((prevFilters) => {
                const updatedPropertyType = isSelected
                    ? (prevFilters.propertyType || []).filter((type) => type !== selectedPropertyType)
                    : [...(prevFilters.propertyType || []), selectedPropertyType];

                return {
                    ...prevFilters,
                    propertyType: updatedPropertyType,
                };
            });
        }

        event.stopPropagation();
    };
    const toggleRoomDropdown = () => {
        setIsRoomDropdownOpen(!isRoomDropdownOpen);
        setIsRoomActive(!isRoomActive);
        setIsLocationDropdownOpen(false);
        setIsLocationActive(false);
        setIsPropertyTypeDropdownOpen(false);
        setIsPropertyTypeActive(false);
        setIsPriceActive(false);
        setIsPriceDropdownOpen(false);
        setIsAreaActive(false);
        setIsAreaDropdownOpen(false);
    };
    const handleRoomOptionClick = (selectedRoomType, selectedValue) => {
        setSelectedRoomOptions((prevOptions) => {
            if (prevOptions[selectedRoomType] === selectedValue) {
                const { [selectedRoomType]: removedOption, ...newOptions } = prevOptions;
                return newOptions;
            }
            return {
                ...prevOptions,
                [selectedRoomType]: selectedValue,
            };
        });

        setSavedFilters((prevFilters) => ({
            ...prevFilters,
            [selectedRoomType]: selectedValue,
        }));
    };
    const handleRoomDropdownClick = (event) => {
        event.stopPropagation();
    };
    const togglePriceDropdown = (event) => {
        event.stopPropagation();
        setIsPriceDropdownOpen(!isPriceDropdownOpen);
        setIsPriceActive(!isPriceActive);
        setIsLocationDropdownOpen(false);
        setIsLocationActive(false);
        setIsRoomActive(false);
        setIsRoomDropdownOpen(false);
        setIsPropertyTypeDropdownOpen(false);
        setIsPropertyTypeActive(false);
        setIsAreaActive(false);
        setIsAreaDropdownOpen(false);
    };
    const handlePriceInputClick = (event) => {
        event.stopPropagation();
    };
    const handlePriceInputChange = (inputType, event) => {
        const value = event.target.value;

        if (inputType === 'from') {
            setPriceFrom(value);
            setSavedFilters((prevFilters) => ({
                ...prevFilters,
                priceFrom: value,
            }));
        } else if (inputType === 'to') {
            setPriceTo(value);
            setSavedFilters((prevFilters) => ({
                ...prevFilters,
                priceTo: value,
            }));
        }
    };
    const toggleAreaDropdown = () => {
        setIsAreaDropdownOpen(!isAreaDropdownOpen);
        setIsAreaActive(!isAreaActive);
        setIsLocationDropdownOpen(false);
        setIsLocationActive(false);
        setIsPropertyTypeActive(false);
        setIsPropertyTypeDropdownOpen(false);
        setIsRoomActive(false);
        setIsRoomDropdownOpen(false);
        setIsPriceActive(false);
        setIsPriceDropdownOpen(false);
    };
    const handleAreaInputClick = (event) => {
        event.stopPropagation();
    };
    const handleAreaInputChange = (inputType, event) => {
        const value = event.target.value;

        if (inputType === 'from') {
            setAreaFrom(value);
            setSavedFilters((prevFilters) => ({
                ...prevFilters,
                areaFrom: value,
            }));
        } else if (inputType === 'to') {
            setAreaTo(value);
            setSavedFilters((prevFilters) => ({
                ...prevFilters,
                areaTo: value,
            }));
        }
    };
    const toggleSortDropdown = () => {
        setDropdownUserOpen(!isDropdownUserOpen);
        setIsLocationDropdownOpen(false);
        setIsLocationActive(false);
        setIsPropertyTypeActive(false);
        setIsPropertyTypeDropdownOpen(false);
        setIsRoomActive(false);
        setIsRoomDropdownOpen(false);
        setIsPriceActive(false);
        setIsPriceDropdownOpen(false);
        setIsAreaDropdownOpen(false);
        setIsAreaActive(false);
    };
    const handleSortOptionClick = (option) => {
        setSelectedSortOption(option);
        setDropdownUserOpen(false);
    };
    const handleSearch = () => {
        setIsAreaDropdownOpen(false);
        setIsAreaActive(false);
        setIsLocationDropdownOpen(false);
        setIsLocationActive(false);
        setIsPropertyTypeActive(false);
        setIsPropertyTypeDropdownOpen(false);
        setIsRoomActive(false);
        setIsRoomDropdownOpen(false);
        setIsPriceActive(false);
        setIsPriceDropdownOpen(false);

        if (onSearch) {
            const params = {
                propertyType: selectedPropertyTypeOptions,
                beds: selectedRoomOptions.Beds || '',
                baths: selectedRoomOptions.Baths || '',
                priceFrom: priceFrom,
                priceTo: priceTo,
                areaFrom: areaFrom,
                areaTo: areaTo,
                city: locationSearchValue,
            };
            onSearch(params);
            setCurrentPage(0);

        }
    };

    return (
        <div className='filters'>
            <div className='filters-container' ref={dropDownRef}>
                <LocationFilter
                    locationSearchValue={locationSearchValue}
                    isLocationDropdownOpen={isLocationDropdownOpen}
                    locationSearchResults={locationSearchResults}
                    toggleLocationDropdown={toggleLocationDropdown}
                    handleLocationSelect={handleLocationSelect}
                    setLocationSearchValue={setLocationSearchValue}
                />
                <div className='filters-container__map'>
                    <button><img src={MapMobile} alt=''/></button>
                </div>
                <PropertyFilter
                    isPropertyTypeActive={isPropertyTypeActive}
                    isPropertyTypeDropdownOpen={isPropertyTypeDropdownOpen}
                    selectedPropertyTypeOptions={selectedPropertyTypeOptions}
                    propertyTypes={propertyTypes}
                    selectedPropertyTypeStatus={localSelectedPropertyTypeStatus}
                    togglePropertyTypeDropdown={togglePropertyTypeDropdown}
                    handlePropertyTypeOptionClick={handlePropertyTypeOptionClick}
                    selectedFilters={selectedFilters}
                    savedSearch={savedSearch}
                />
                <RoomFilter
                    isRoomActive={isRoomActive}
                    isRoomDropdownOpen={isRoomDropdownOpen}
                    selectedRoomOptions={selectedRoomOptions}
                    toggleRoomDropdown={toggleRoomDropdown}
                    handleRoomOptionClick={handleRoomOptionClick}
                    handleRoomDropdownClick={handleRoomDropdownClick}
                />
                <PriceFilter
                    isPriceActive={isPriceActive}
                    isPriceDropdownOpen={isPriceDropdownOpen}
                    priceFrom={priceFrom}
                    priceTo={priceTo}
                    handlePriceInputClick={handlePriceInputClick}
                    handlePriceInputChange={handlePriceInputChange}
                    togglePriceDropdown={togglePriceDropdown}
                />
                <AreaFilter
                    isAreaActive={isAreaActive}
                    isAreaDropdownOpen={isAreaDropdownOpen}
                    areaFrom={areaFrom}
                    areaTo={areaTo}
                    handleAreaInputClick={handleAreaInputClick}
                    handleAreaInputChange={handleAreaInputChange}
                    toggleAreaDropdown={toggleAreaDropdown}
                />
                <div className='filters-container__sorting'>
                    <div
                        className={`filters-container__sorting-dropdown ${isDropdownOpen ? 'open' : ''}`}
                        onClick={toggleSortDropdown}
                        ref={sortingDropdownRef}
                    >
                        <img src={Sort} alt="" />
                        <span>{t(selectedSortOption)}</span>
                        <img src={Down} alt='' className={`down ${isDropdownUserOpen ? 'rotate-sorting' : ''}`} />
                        {isDropdownUserOpen && (
                            <div className='filters-container__sorting-dropdown__list'>
                                <div className='filters-container__sorting-dropdown__list-item' onClick={() => handleSortOptionClick(t('Lowest Price'))}>
                                    <span>{t('Lowest Price')}</span>
                                </div>
                                <div className='filters-container__sorting-dropdown__list-item' onClick={() => handleSortOptionClick(t('Highest Price'))}>
                                    <span>{t('Highest Price')}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='filters-container__btn'>
                    <button onClick={handleSearch}><img src={SearchBtnIcon} alt=''/>{t('Search')}</button>
                </div>
            </div>
        </div>
    );
};

Filters.propTypes = {
    onSearch: PropTypes.func,
};

export default Filters;
