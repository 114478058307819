import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '../../assets/images/icon/world.svg';
import Down from '../../assets/images/icon/down.svg';

const LanguageDropdown = () => {
    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('language') || 'en');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const languageDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (languageDropdownRef.current && !languageDropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
            setCurrentLanguage(storedLanguage);
        }
    }, [i18n]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setCurrentLanguage(language);
        setIsDropdownOpen(false);
        localStorage.setItem('language', language);
    };

    const languageList = ['en', 'ru'];

    return (
        <div
            ref={languageDropdownRef}
            className={`header-container__bar-content language ${isDropdownOpen ? 'open' : ''}`}
            onClick={toggleDropdown}
        >
            <img src={LanguageIcon} alt="" />
            {t(currentLanguage.toUpperCase())}
            <img src={Down} alt='' className={`down ${isDropdownOpen ? 'rotate-header' : ''}`} />
            {isDropdownOpen && (
                <div className='header-container__bar-content__dropdown'>
                    {languageList.map((lang) => (
                        <div
                            className='header-container__bar-content__dropdown-list'
                            key={lang}
                            onClick={() => changeLanguage(lang)}
                        >
                            <span>{t(lang)}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LanguageDropdown;
