/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { getObjectsByStatus } from '../../../action/object_api';
import { getUser } from '../../../action/user_api';
import { MoonLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import Card from '../../../components/my_objects/card';
import Pagination from "../../../components/pagination";
import Add from '../../../assets/images/btns/add_objects.svg';
import Down from '../../../assets/images/icon/down.svg';
import './styles/styles.css';
import './styles/@styles.css';
import {useTranslation} from "react-i18next";
import Title from "../../../components/title";
import AddButton from "../../../components/add_button";

const Main = () => {
    const { t } = useTranslation();
    const [userId, setUserId] = useState(null);
    const [activeTab, setActiveTab] = useState('CONSIDERATION');
    const [objects, setObjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const user = await getUser();
                setUserId(user.id);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                if (userId) {
                    const result = await getObjectsByStatus(activeTab, userId, currentPage);
                    setObjects(result.content);
                    setTotalPages(result.totalPages);
                }
            } catch (error) {
                console.error('Error fetching property objects:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [activeTab, userId, currentPage]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        setCurrentPage(0);
    }, [activeTab]);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen)
    };
    const handleTabOptionClick = (option) => {
        setActiveTab(option);
        setCurrentPage(0);
        setDropdownOpen(false);
    };
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleObjectDelete = async (objectId) => {
        setObjects(objects.filter(obj => obj.id !== objectId));
        const result = await getObjectsByStatus(activeTab, userId, currentPage);
        setObjects(result.content);
        setTotalPages(result.totalPages);
    };

    return(
        <div className='my_objects'>
            <div className='my_objects-container'>
                <div className='my_objects-container__header'>
                    <Title text='My objects'/>
                    <div className='my_objects-container__header-btn'>
                        <AddButton to='/my_objects/create' text='Add object'/>
                    </div>
                </div>
                <div className='my_objects-container__tabs'>
                    <div className='my_objects-container__tabs-container'>
                        <div
                            className={`my_objects-container__tabs-container__item ${activeTab === 'CONSIDERATION' ? 'active-tab' : ''}`}
                            onClick={() => setActiveTab('CONSIDERATION')}
                        >
                            {t('Under consideration')}
                        </div>
                        <div
                            className={`my_objects-container__tabs-container__item ${activeTab === 'ACTIVE' ? 'active-tab' : ''}`}
                            onClick={() => setActiveTab('ACTIVE')}
                        >
                            {t('Active')}
                        </div>
                        <div
                            className={`my_objects-container__tabs-container__item ${activeTab === 'DRAFT' ? 'active-tab' : ''}`}
                            onClick={() => setActiveTab('DRAFT')}
                        >
                            {t('Drafts')}
                        </div>
                        <div
                            className={`my_objects-container__tabs-container__item ${activeTab === 'ARCHIVE' ? 'active-tab' : ''}`}
                            onClick={() => setActiveTab('ARCHIVE')}
                        >
                            {t('Archive')}
                        </div>
                        <div
                            className={`my_objects-container__tabs-container__item ${activeTab === 'DISMISSED' ? 'active-tab' : ''}`}
                            onClick={() => setActiveTab('DISMISSED')}
                        >
                            {t('Dismissed')}
                        </div>
                    </div>
                    <div className='my_objects-container__tabs-mobile'>
                        <div className='my_objects-container__tabs-mobile__dropdown' onClick={toggleDropdown} ref={dropdownRef}>
                            <span>
                                {activeTab === 'CONSIDERATION' && t('Under consideration')}
                                {activeTab === 'ACTIVE' && t('Active')}
                                {activeTab === 'DRAFT' && t('Drafts')}
                                {activeTab === 'ARCHIVE' && t('Archive')}
                                {activeTab === 'DISMISSED' && t('Dismissed')}
                            </span>
                            <img src={Down} alt='' className={isDropdownOpen ? 'active-rotate' : ''}/>
                            {isDropdownOpen && (
                                <div className='my_objects-container__tabs-mobile__dropdown-list'>
                                    <div
                                        className={`my_objects-container__tabs-mobile__dropdown-list__item ${activeTab === 'CONSIDERATION'}`}
                                        onClick={() => handleTabOptionClick('CONSIDERATION')}
                                    >
                                        {t('Under consideration')}
                                    </div>
                                    <div
                                        className={`my_objects-container__tabs-mobile__dropdown-list__item ${activeTab === 'ACTIVE'}`}
                                        onClick={() => handleTabOptionClick('ACTIVE')}
                                    >
                                        {t('Active')}
                                    </div>
                                    <div
                                        className={`my_objects-container__tabs-mobile__dropdown-list__item ${activeTab === 'DRAFT'}`}
                                        onClick={() => handleTabOptionClick('DRAFT')}
                                    >
                                        {t('Drafts')}
                                    </div>
                                    <div
                                        className={`my_objects-container__tabs-mobile__dropdown-list__item ${activeTab === 'ARCHIVE'}`}
                                        onClick={() => handleTabOptionClick('ARCHIVE')}
                                    >
                                        {t('Archive')}
                                    </div>
                                    <div
                                        className={`my_objects-container__tabs-mobile__dropdown-list__item ${activeTab === 'DISMISSED'}`}
                                        onClick={() => handleTabOptionClick('DISMISSED')}
                                    >
                                        {t('Dismissed')}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='my_objects-container__tabs-mobile__btn'>
                            <AddButton to='/my_objects/create' text='Add object'/>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className='load'>
                            <MoonLoader color={'#E7C78B'} loading={isLoading} size={30} />
                        </div>
                    ) : (
                        <>
                            <div className='my_objects-container__tabs-content'>
                                {objects.length > 0 ? (
                                    <>
                                        {objects.map((object) => (
                                            <div className='my_objects-container__tabs-content__item' key={object.id}>
                                                <Card object={object} activeTab={activeTab} onDelete={handleObjectDelete} />
                                            </div>
                                        ))}
                                        {[8, 5, 2].map((count) => (
                                            Array.from({ length: count }, (_, index) => (
                                                <div key={index} className='empty-item'></div>
                                            ))
                                        ))}

                                    </>
                                ) : (
                                    <>
                                        {activeTab === 'CONSIDERATION' && <p>{t('No objects under consideration')}</p>}
                                        {activeTab === 'ACTIVE' && <p>{t('You have no objects')}</p>}
                                        {activeTab === 'DRAFT' && <p>{t('No drafts')}</p>}
                                        {activeTab === 'ARCHIVE' && <p>{t('No objects in the archive')}</p>}
                                        {activeTab === 'DISMISSED' && <p>{t('No rejected objects')}</p>}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageClick={handlePageClick}
            />
        </div>
    )
}

export default Main;
