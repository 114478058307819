import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TabItem = ({ tabLabel, selectedTabOption, handleTabOptionClick }) => {
    const { t } = useTranslation();

    return (
        <div
            className={`view-container__sliderMobile-tabs__list-item ${selectedTabOption === tabLabel ? 'active-mobile' : ''}`}
            onClick={() => handleTabOptionClick(tabLabel)}
        >
            <p>{t(tabLabel)}</p>
        </div>
    );
};

TabItem.propTypes = {
    tabLabel: PropTypes.string.isRequired,
    selectedTabOption: PropTypes.string.isRequired,
    handleTabOptionClick: PropTypes.func.isRequired,
};

export default TabItem;
