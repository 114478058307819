/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import Tabs from '../objects/view/tabs_desctop';
import {useTranslation} from 'react-i18next';
import {confirmFileUpload, generateUploadLinks, uploadFileToTempStorage} from "../../action/media_api";
import {getUser} from '../../action/user_api';
import {getAgent, getAgentLanguages, updateAgent} from '../../action/agent_api';
import Edit from '../../assets/images/btns/edit_yellow.svg';
import Avatar from '../../assets/images/btns/avatar.svg';
import './styles/styles.css';
import './styles/@styles.css';

const PersonalData = () => {
    const { t } = useTranslation();
    const [id, setId] = useState('');
    const [userId, setUserId] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [name, setName] = useState('');
    const [licenseNumber, setLicenseNumber] = useState('');
    const [agency, setAgency] = useState('');
    const [languages, setLanguages] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [activeTab, setActiveTab] = useState('PersonalData');
    const TABS = {
        DATA: 'PersonalData',
        SUB: 'SubscriptionManagement',
    };
    const TAB_LABELS = {
        DATA: t('Personal data'),
        SUB: t('Subscription Management'),
    };
    const tabs = [
        { key: TABS.DATA, label: t(TAB_LABELS.DATA) },
        { key: TABS.SUB, label: t(TAB_LABELS.SUB) },
    ];
    const [isChanged, setIsChanged] = useState(false);
    const [initialAgentData, setInitialAgentData] = useState(null);
    const [showLanguages, setShowLanguages] = useState(false);
    const [agentLanguages, setAgentLanguages] = useState([]);
    const [avatar, setAvatar] = useState(null);
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        agency: '',
        languages: '',
        email: '',
        phone: ''
    });

    const dropdownRef = useRef(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userData = await getUser();
                const userId = userData.id;
                setUserId(userId);
                const agentData = await getAgent(userId);

                setInitialAgentData(agentData);

                setId(agentData.id);
                setFirstName(agentData.firstName);
                setLastName(agentData.lastName);
                setName(agentData.displayName);
                setLicenseNumber(agentData.licenseNumber);
                setAgency(agentData.agency);
                setLanguages(agentData.languages.join(', '));
                setEmail(agentData.email);
                setPhone('+' + agentData.phone);
                setWhatsapp(agentData.whatsapp ? '+' + agentData.whatsapp : '');
                setAvatar(agentData.avatar)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowLanguages(false);
        }
    };
    const handleAvatarClick = () => {
        const input = document.getElementById('avatar-input');
        input.click();
    };
    const handleInputChange = () => {
        setErrors(prevErrors => ({ ...prevErrors, email: '' }));
        setIsChanged(true);
    };
    const handleCancel = () => {
        if (initialAgentData) {
            const {
                id: initialId,
                firstName : initialFirstName,
                lastName: initialLastName,
                displayName: initialDisplayName,
                licenseNumber: initialLicenseNumber,
                agency: initialAgency,
                languages: initialLanguages,
                email: initialEmail,
                phone: initialPhone,
                whatsapp: initialWhatsapp,
                avatar: initialAvatar
            } = initialAgentData;

            setId(initialId);
            setFirstName(initialFirstName);
            setLastName(initialLastName);
            setName(initialDisplayName);
            setLicenseNumber(initialLicenseNumber);
            setAgency(initialAgency);
            setLanguages(initialLanguages.join(', '));
            setEmail(initialEmail);
            setPhone('+' + initialPhone);
            setWhatsapp('+' + initialWhatsapp);
            setAvatar(initialAvatar);
        }
        setErrors(prevErrors => ({ ...prevErrors, firstName: '', lastName: '' }));
        setErrors(prevErrors => ({ ...prevErrors, languages: '' }));
        setErrors(prevErrors => ({ ...prevErrors, email: '' }));
        setErrors(prevErrors => ({ ...prevErrors, phone: '' }));
        setErrors(prevErrors => ({ ...prevErrors, whatsapp: '' }));
        setIsChanged(false);
    };
    const handleNameChange = (e) => {
        const fullName = e.target.value;
        setName(fullName);
        const spaceIndex = fullName.indexOf(' ');
        const updatedFirstName = spaceIndex !== -1 ? fullName.slice(0, spaceIndex) : fullName;
        const updatedLastName = spaceIndex !== -1 ? fullName.slice(spaceIndex + 1) : '';
        setFirstName(updatedFirstName);
        setLastName(updatedLastName);
        setErrors(prevErrors => ({ ...prevErrors, firstName: '', lastName: '' }));
        handleInputChange();
    };
    const validateForm = () => {
        const fieldsToValidate = [
            { field: firstName, errorKey: 'firstName', errorMessage: t('Please enter your name') },
            { field: lastName, errorKey: 'lastName', errorMessage: t('Please enter your last name') },
            { field: languages, errorKey: 'languages', errorMessage: t('Please select languages') },
            { field: email, errorKey: 'email', errorMessage: t('Please enter your e-mail') },
            { field: phone, errorKey: 'phone', errorMessage: t('Please enter your phone number') },
        ];

        const newErrors = {};

        fieldsToValidate.forEach(({ field, errorKey, errorMessage }) => {
            newErrors[errorKey] = field.trim() === '' ? errorMessage : '';
        });

        setErrors(newErrors);

        return Object.values(newErrors).every(error => error === '');
    };
    const handleSaveChanges = async () => {
        try {
            if (!validateForm()) {
                return;
            }
            const updatedUserData = {
                id,
                displayName: `${firstName} ${lastName}`,
                firstName,
                lastName,
                agency,
                licenseNumber,
                languages: languages.split(',').map(lang => lang.trim()),
                email,
                phone: phone.replace(/\+/g, ''),
                whatsapp: whatsapp.replace(/\+/g, ''),
            };

            if (avatar) {
                updatedUserData.avatar = avatar;
            }

            await updateAgent(id, updatedUserData);
            setIsChanged(false);
        } catch (error) {
            console.error('Error updating agent:', error);
        }
    };
    const handlePhoneChange = (e) => {
        const formattedPhone = e.target.value.replace(/[^+\d]/g, '');
        setPhone(formattedPhone);
        setErrors(prevErrors => ({ ...prevErrors, phone: '' }));
        handleInputChange();
    };
    const handleWhatsappChange = (e) => {
        const formattedWhatsapp = e.target.value.replace(/[^+\d]/g, '');
        setWhatsapp(formattedWhatsapp);
        handleInputChange();
    };
    const handleLanguagesClick = async () => {
        if (!showLanguages) {
            try {
                const languages = await getAgentLanguages();
                setAgentLanguages(languages);
                setShowLanguages(true);
            } catch (error) {
                console.error('Error loading languages:', error);
            }
        } else {
            setShowLanguages(false);
        }
    };
    const handleLanguageClick = (language) => {
        const isLanguageSelected = languages.includes(language);

        if (isLanguageSelected) {
            setLanguages(prevLanguages => {
                return prevLanguages
                    .split(', ')
                    .filter(lang => lang !== language)
                    .join(', ');
            });
        } else {
            setLanguages(prevLanguages => {
                return prevLanguages ? `${prevLanguages}, ${language}` : language;
            });
        }
        setErrors(prevErrors => ({ ...prevErrors, languages: '' }));
        handleInputChange();
    };
    const isInputFilled = (value) => {
        return value !== null && value !== undefined && value.toString().trim() !== '';
    };
    const handleAvatarChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        try {
            const uploadLinks = await generateUploadLinks([{ contentType: 'image/jpeg', extension: file.name.split('.').pop() }]);
            const uploadLink = uploadLinks[0];

            await uploadFileToTempStorage(uploadLink.uploadURL, file);

            const avatarObject = await confirmFileUpload({
                uploadURL: uploadLink.uploadURL,
                originalFilename: uploadLink.originalFilename,
                mediaType: 'USER_AVATAR',
                token: uploadLink.token,
                entityId: userId
            });

            setAvatar(avatarObject);
            handleInputChange();
        } catch (error) {
            console.error('Error uploading avatar:', error);
        }
    };

    return(
        <div className='profile'>
            <div className='profile-container'>
                <div className='profile-container__title'>
                    <h2>{t('Profile settings')}</h2>
                </div>
                <>
                    <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                </>
                {activeTab === 'PersonalData' && (
                    <div className='profile-container__form'>
                        <div className='profile-container__form-item__avatar'>
                            {avatar ? (
                                <div className='profile-container__form-item__avatar-image' onClick={handleAvatarClick}>
                                    <img src={avatar.storageFileName} alt=''/>
                                    <input
                                        type='file'
                                        accept='image/*'
                                        id='avatar-input'
                                        style={{ display: 'none' }}
                                        onChange={handleAvatarChange}
                                    />
                                    <div className='profile-container__form-item__avatar-image__add'>
                                        <img src={Avatar} alt='' />
                                    </div>
                                </div>
                            ) : (
                                <div className='profile-container__form-item__avatar-input' onClick={handleAvatarClick}>
                                    <img src={Avatar} alt='' />
                                    <input
                                        type='file'
                                        accept='image/*'
                                        id='avatar-input'
                                        style={{ display: 'none' }}
                                        onChange={handleAvatarChange}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={`profile-container__form-item id ${isInputFilled(id) ? 'field' : ''}`}>
                            {id && <span>ID</span>}
                            <input
                                placeholder='ID'
                                value={id}
                                onChange={(e) => setId(e.target.value)}
                                disabled
                            />
                        </div>
                        <div className={`profile-container__form-item name ${isInputFilled(name) ? 'field' : ''}`}>
                            {name && <span>{t('Name')}</span>}
                            <input
                                placeholder={t('Name')}
                                value={name}
                                onChange={handleNameChange}
                            />
                            {errors.firstName && <div className='error-message'>{errors.firstName}</div>}
                            {errors.lastName && <div className='error-message'>{errors.lastName}</div>}
                            <img src={Edit} alt=''/>
                        </div>
                        <div className={`profile-container__form-item license ${isInputFilled(licenseNumber) ? 'field' : ''}`}>
                            {licenseNumber && <span>{t('License number')}</span>}
                            <input
                                placeholder={t('License number')}
                                value={licenseNumber}
                                onChange={(e) => {setLicenseNumber(e.target.value); handleInputChange();}}
                                disabled
                            />
                        </div>
                        <div className={`profile-container__form-item agency ${isInputFilled(agency) ? 'field' : ''}`}>
                            {agency && <span>{t('Agency')}</span>}
                            <input
                                placeholder={t('Agency')}
                                value={agency}
                                onChange={(e) => setAgency(e.target.value)}
                                disabled
                            />
                        </div>
                        <div className={`profile-container__form-item languages ${isInputFilled(languages) ? 'field' : ''}`} onClick={handleLanguagesClick}>
                            {languages && <span>{t('Speaks')}</span>}
                            <input
                                placeholder={t('Speaks')}
                                value={languages}
                                onChange={(e) => {setLanguages(e.target.value); handleInputChange();}}
                            />
                            {errors.languages && <div className='error-message'>{errors.languages}</div>}
                            <img src={Edit} alt=''/>
                            {showLanguages && (
                                <div className='lng-dropdown' ref={dropdownRef}>
                                    <div className='lng-dropdown-list'>
                                        {agentLanguages.map((language, index) => (
                                            <div className='lng-dropdown-list__item'>
                                                <p
                                                    onClick={() => handleLanguageClick(language)}
                                                    key={index}
                                                >
                                                    {language}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={`profile-container__form-item email ${isInputFilled(email) ? 'field' : ''}`}>
                            {email && <span>{t('E-mail')}</span>}
                            <input
                                placeholder={t('E-mail')}
                                value={email}
                                onChange={(e) => {setEmail(e.target.value); handleInputChange();}}
                            />
                            {errors.email && <div className='error-message'>{errors.email}</div>}
                            <img src={Edit} alt=''/>
                        </div>
                        <div className={`profile-container__form-item phone ${isInputFilled(phone) ? 'field' : ''}`}>
                            {phone && <span>{t('Phone')}</span>}
                            <input
                                placeholder={t('Phone')}
                                value={phone}
                                onChange={handlePhoneChange}
                            />
                            {errors.phone && <div className='error-message'>{errors.phone}</div>}
                            <img src={Edit} alt=''/>
                        </div>
                        <div className={`profile-container__form-item whatsapp ${isInputFilled(whatsapp) ? 'field' : ''}`}>
                            {whatsapp && <span>WhatsApp</span>}
                            <input
                                type="tel"
                                placeholder='WhatsApp'
                                value={whatsapp}
                                onChange={handleWhatsappChange}
                            />
                            <img src={Edit} alt=''/>
                        </div>
                        <div className='profile-container__form-item btn' style={{ display: isChanged ? 'flex' : 'none' }}>
                            <button onClick={handleSaveChanges}>{t('Save changes')}</button>
                            <button onClick={handleCancel}>{t('Cancel')}</button>
                        </div>
                    </div>
                )}
                {activeTab === 'SubscriptionManagement' && (
                    <div className='profile-container__sub'>
                        <span>
                            {t('No information')}
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PersonalData;
