/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {getObjectById, getPropertyType} from "../../../../action/object_api";
import Down from "../../../../assets/images/icon/down.svg";
import CustomCheckbox from "../../../../components/chekbox";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Title from "../../../../components/title";

const StepTwo = ({ onNext, onFormChange, stepData }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [formValue, setFormValue] = useState(stepData[2]);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [isPropertyTypeDropdownOpen, setIsPropertyTypeDropdownOpen] = useState(false);
    const [selectedPropertyType, setSelectedPropertyType] = useState(stepData[2].propertyType || null);
    const [isBedsListOpen, setIsBedsListOpen] = useState(false);
    const [selectedBeds, setSelectedBeds] = useState(null);
    const [isBathsListOpen, setIsBathsListOpen] = useState(false);
    const [selectedBaths, setSelectedBaths] = useState(null);
    const [errors, setErrors] = useState({
        propertyType: '',
        description: '',
        furnishing: '',
        parking: '',
        advantages: '',
        beds: '',
        baths: '',
        registrationNumber: '',
        area: '',
        price: '',
    });
    const facilities = [
        { id: 1, name: 'Gym' },
        { id: 2, name: 'Parking' },
        { id: 3, name: 'Barbeque Area' },
        { id: 4, name: 'Jacuzzi' },
        { id: 5, name: 'Cleaning Service' },
        { id: 6, name: 'Reception/Waiting Room' },
        { id: 7, name: 'Lawn or Garden' },
        { id: 8, name: 'Swimming' },
        { id: 9, name: 'Security Staff' },
        { id: 10, name: 'Steam Room' },
        { id: 11, name: 'Lobby in Building' },
        { id: 12, name: 'Conference Room' },
        { id: 13, name: 'Kids Play Area' },
        { id: 14, name: 'Sauna' },
        { id: 15, name: 'Balcony or Terrace' },
        { id: 16, name: '24 Hours Concierge' },
        { id: 17, name: 'Business Center' },
        { id: 18, name: 'Maintenance Staff' },
        { id: 19, name: 'Cafeteria or Canteen' },
        { id: 20, name: 'Restaurants' },
        { id: 21, name: 'First Aid Medical Center' },
        { id: 22, name: 'View' },
        { id: 23, name: 'Prayer Room' },
        { id: 24, name: 'CCTV Security' },
    ];
    const [selectedFacilities, setSelectedFacilities] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getPropertyType();
                setPropertyTypes(response.data);
            } catch (error) {
                console.error('Error fetching property types:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const object = await getObjectById(id);
                if (object) {
                    setFormValue({
                        propertyType: object.propertyType || '',
                        description: object.description || '',
                        furnishing: object.furnishing || '',
                        parking: object.parking || '',
                        advantages: object.advantages || '',
                        beds: object.beds || null,
                        baths: object.baths || null,
                        registrationNumber: object.registrationNumber || '',
                        links: object.links || '',
                        area: object.area || null,
                        price: object.price || null,
                        // phone: (object.user && object.user.phone) ? '+' + object.user.phone : '',
                        facilities: object.facilities || []
                    });
                    setSelectedPropertyType(object.propertyType);
                    setSelectedBeds(object.beds);
                    setSelectedBaths(object.baths);
                }
            } catch (error) {
                console.error('Error fetching object:', error);
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        if (formValue.facilities && formValue.facilities.length > 0) {
            const initialSelectedFacilities = {};
            formValue.facilities.forEach(facility => {
                initialSelectedFacilities[facility.id] = true;
            });
            setSelectedFacilities(Object.keys(initialSelectedFacilities));
        }
    }, [formValue.facilities]);

    const toggleDropdownBed = () => {
        setIsBedsListOpen(!isBedsListOpen);
    };
    const toggleDropdownBath = () => {
        setIsBathsListOpen(!isBathsListOpen);
    };
    const validateForm = () => {
        const newErrors = {
            propertyType: selectedPropertyType ? '' : t('Select a property type'),
            description: formValue.description.trim() === '' ? t('Fill in the description field') : '',
            beds: formValue.beds <= 0 ? t('Select the number of bedrooms') : '',
            baths: formValue.baths <= 0 ? t('Select the number of bathrooms') : '',
            registrationNumber: formValue.registrationNumber.trim() === '' ? t('Fill in the registration number field') : '',
            area: formValue.area <= 0 ? t('Enter a valid area') : '',
            price: formValue.price <= 0 ? t('Enter a valid price') : '',
        };
        setErrors(newErrors);
        return (
            !Object.values(newErrors).some((error) => error !== '') &&
            selectedPropertyType !== null &&
            formValue.beds > 0 &&
            formValue.baths > 0 &&
            formValue.price > 0 &&
            formValue.area > 0
        );
    };
    const handleChange = (field, value) => {
        setFormValue((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));

        if (typeof value === 'string') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: value.trim() === '' ? `Fill in the ${field} field` : '',
            }));

        } else {
            if (field === 'beds' || field === 'baths' || field === 'area' || field === 'price') {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [field]: value <= 0 ? `Enter a valid ${field}` : '',
                }));
            }
        }
    };
    const handleFacilityChange = (facilityId) => {
        const isFacilitySelected = formValue.facilities.some(facility => facility.id === facilityId);
        if (isFacilitySelected) {
            setFormValue(prevValues => ({
                ...prevValues,
                facilities: prevValues.facilities.filter(facility => facility.id !== facilityId),
            }));
        } else {
            setFormValue(prevValues => ({
                ...prevValues,
                facilities: [
                    ...prevValues.facilities,
                    { id: facilityId, name: facilities.find(facility => facility.id === facilityId)?.name || '' },
                ],
            }));
        }
        onFormChange({ ...stepData[2], facilities: formValue });
    };
    const togglePropertyTypeDropdown = () => {
        setIsPropertyTypeDropdownOpen(!isPropertyTypeDropdownOpen);
    };
    const handlePropertyTypeSelect = (type) => {
        setFormValue((prevValues) => ({
            ...prevValues,
            propertyType: type,
        }));
        setSelectedPropertyType(type);
        setIsPropertyTypeDropdownOpen(false);
        setErrors((prevErrors) => ({
            ...prevErrors,
            propertyType: '',
        }));
    };
    const handleNext = () => {
        const isValid = validateForm();
        if (isValid) {
            setErrors({
                propertyType: '',
                description: '',
                furnishing: '',
                parking: '',
                advantages: '',
                beds: '',
                baths: '',
                registrationNumber: '',
                links: '',
                area: '',
                price: '',
            });
            onFormChange(formValue);
            onNext();
        }
    };

    return (
        <div className='two'>
            <Title text='About object'/>
            <div className='two-form'>
                <div className='two-form__property'>
                    <div className='two-form__property-dropdown' onClick={togglePropertyTypeDropdown}>
                        <span className={selectedPropertyType ? 'selected-property' : ''}>
                          {selectedPropertyType ? t(selectedPropertyType) : t('Property type')}
                        </span>
                        <img src={Down} alt='' className={`down-option ${isPropertyTypeDropdownOpen ? 'rotate' : ''}`} />
                        {isPropertyTypeDropdownOpen && (
                            <div className='two-form__property-dropdown__list'>
                                {propertyTypes.map((type) => (
                                    <div
                                        key={type}
                                        className='two-form__property-dropdown__list-item'
                                        onClick={() => handlePropertyTypeSelect(type)}
                                    >
                                        <span>{t(type)}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className='error-message'>{errors.propertyType}</div>
                </div>
                <div className='two-form__rooms'>
                    <div className='two-form__rooms-bed'>
                        <span>{t('Number of bedrooms')}</span>
                        <div className='two-form__rooms-bed__numbers'>
                            {Array.from({ length: 8 }, (_, index) => (
                                <span
                                    key={index + 1}
                                    className={formValue.beds === index + 1 ? 'selected-room' : ''}
                                    onClick={() => handleChange('beds', index + 1)}
                                >
                                    {index + 1}
                                </span>
                            ))}
                        </div>
                        <div className='error-message'>{errors.beds}</div>
                    </div>
                    <div className='two-form__rooms-bath'>
                        <span>{t('Number of bathrooms')}</span>
                        <div className='two-form__rooms-bath__numbers'>
                            {Array.from({ length: 6 }, (_, index) => (
                                <span
                                    key={index + 1}
                                    className={formValue.baths === index + 1 ? 'selected-room' : ''}
                                    onClick={() => handleChange('baths', index + 1)}
                                >
                                    {index + 1}
                                </span>
                            ))}
                        </div>
                        <div className='error-message'>{errors.baths}</div>
                    </div>
                </div>
                <div className='two-form__roomsMobile'>
                    <div className='two-form__roomsMobile-bed' onClick={toggleDropdownBed}>
                        <div className='two-form__roomsMobile-bed__dropdown'>
                            {selectedBeds !== null && <p>{t('Beds')}</p>}
                            <span className={selectedBeds !== null ? 'selected-property' : ''}>
                              {selectedBeds !== null ? selectedBeds : t('Beds')}
                            </span>
                            <img src={Down} alt='' className={isBedsListOpen ? 'active-rotate' : ''} />
                        </div>
                        {isBedsListOpen && (
                            <div className='two-form__roomsMobile-bed__list'>
                                <div className='two-form__roomsMobile-bed__list-numbers'>
                                    {Array.from({ length: 8 }, (_, index) => (
                                        <p
                                            key={index + 1}
                                            className={formValue.beds === index + 1 ? 'selected-room' : ''}
                                            onClick={() => {
                                                handleChange('beds', index + 1);
                                                setSelectedBeds(index + 1);
                                                setIsBedsListOpen(false);
                                            }}
                                        >
                                            {index + 1}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className='error-message'>{errors.beds}</div>
                    </div>
                    <div className='two-form__roomsMobile-bath' onClick={toggleDropdownBath}>
                        <div className='two-form__roomsMobile-bath__dropdown'>
                            {selectedBaths !== null && <p>{t('Baths')}</p>}
                            <span className={selectedBaths !== null ? 'selected-property' : ''}>
                              {selectedBaths !== null ? selectedBaths : t('Baths')}
                            </span>
                            <img src={Down} alt='' className={isBathsListOpen ? 'active-rotate' : ''} />
                        </div>
                        {isBathsListOpen && (
                            <div className='two-form__roomsMobile-bath__list'>
                                <div className='two-form__roomsMobile-bath__list-numbers'>
                                    {Array.from({ length: 6 }, (_, index) => (
                                        <p
                                            key={index + 1}
                                            className={formValue.baths === index + 1 ? 'selected-room' : ''}
                                            onClick={() => {
                                                handleChange('baths', index + 1);
                                                setSelectedBaths(index + 1);
                                                setIsBathsListOpen(false);
                                            }}
                                        >
                                            {index + 1}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className='error-message'>{errors.baths}</div>
                    </div>
                </div>
                <div className='two-form__area'>
                    {formValue.area && <span>{t('Area (m²)')}</span>}
                    <input
                        value={formValue.area}
                        type='number'
                        placeholder={t('Area (m²)')}
                        onChange={(e) => {
                            const parsedValue = parseInt(e.target.value, 10);
                            handleChange('area', isNaN(parsedValue) || parsedValue === 0 ? '' : parsedValue);
                        }}
                    />
                    <div className='error-message'>{errors.area}</div>
                </div>
                <div className='two-form__info'>
                    <span>{t('Information')}</span>
                    <div className='two-form__info-text'>
                        <div className='two-form__info-text__area general'>
                            {formValue.description && <span>{t('General information')}</span>}
                            <textarea
                                value={formValue.description}
                                placeholder={t('General information')}
                                onChange={(e) => handleChange('description', e.target.value)}
                            />
                            <div className='error-message'>{errors.description}</div>
                        </div>
                        <div className='two-form__info-text__area furnishing'>
                            {formValue.furnishing && <span>{t('Furnishing')}</span>}
                            <textarea
                                value={formValue.furnishing}
                                placeholder={t('Furnishing')}
                                onChange={(e) => handleChange('furnishing', e.target.value)}
                            />
                        </div>
                        <div className='two-form__info-text__area parking'>
                            {formValue.parking && <span>{t('Parking')}</span>}
                            <textarea
                                value={formValue.parking}
                                placeholder={t('Parking')}
                                onChange={(e) => handleChange('parking', e.target.value)}
                            />
                        </div>
                        <div className='two-form__info-text__area advantages'>
                            {formValue.advantages && <span>{t('Advantages')}</span>}
                            <textarea
                                value={formValue.advantages}
                                placeholder={t('Advantages')}
                                onChange={(e) => handleChange('advantages', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='two-form__regnumber'>
                    {formValue.registrationNumber && <span>{t('Registration number')}</span>}
                    <input
                        value={formValue.registrationNumber}
                        placeholder={t('Registration number')}
                        onChange={(e) => handleChange('registrationNumber', e.target.value)}
                    />
                    <div className='error-message'>{errors.registrationNumber}</div>
                </div>
                <div className='two-form__linktoyoutube'>
                    {formValue.links && <span>{t('Link to YouTube URL')}</span>}
                    <input
                        value={formValue.links}
                        placeholder={t('Link to YouTube URL')}
                        onChange={(e) => handleChange('links', e.target.value)}
                    />
                </div>
                <div className='two-form__price'>
                    {formValue.price && <span>{t('Price (AED)')}</span>}
                    <input
                        value={formValue.price}
                        type='number'
                        placeholder={t('Price (AED)')}
                        onChange={(e) => {
                            const parsedValue = parseInt(e.target.value, 10);
                            handleChange('price', isNaN(parsedValue) || parsedValue === 0 ? '' : parsedValue);
                        }}
                    />
                    <div className='error-message'>{errors.price}</div>
                </div>
                {/*<div className='two-form__phone'>*/}
                {/*    <PhoneInput*/}
                {/*        international*/}
                {/*        defaultCountry="AE"*/}
                {/*        value={formValue.phone}*/}
                {/*        onChange={(e) => handleChange('phone', e)}*/}
                {/*        placeholder= '+971'*/}
                {/*    />*/}
                {/*    <div className='error-message'>{errors.phone}</div>*/}
                {/*</div>*/}
                <div className='two-form__facilities'>
                    <span>{t('Facilities')}</span>
                    <div className='two-form__facilities-container'>
                        <div className='two-form__facilities-container__content'>
                            {facilities.map((facility) => (
                                <div
                                    key={facility.id}
                                    className={`two-form__facilities-container__content-item ${facility.name.toLowerCase()}`}
                                >
                                    <CustomCheckbox
                                        isChecked={formValue.facilities.some(f => f.id === facility.id)}
                                        onChange={() => handleFacilityChange(facility.id)}
                                    />
                                    <div className='two-form__facilities-container__content-item__text'>
                                        <span>{t(facility.name)}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='two-btn'>
                <button type='button' onClick={handleNext}>{t('Continue')}</button>
            </div>
        </div>
    );
};
StepTwo.propTypes = {
    onNext: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
};

export default StepTwo;
