import React, { createContext, useContext, useState } from 'react';

const PageContext = createContext();

// eslint-disable-next-line react/prop-types
export const PageProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(0);

    return (
        <PageContext.Provider value={{ currentPage, setCurrentPage }}>
            {children}
        </PageContext.Provider>
    );
};

export const usePageContext = () => useContext(PageContext);
