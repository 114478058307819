import React from 'react';
import PropTypes from 'prop-types';

const RoomInfo = ({ icon, label, value }) => {
    const formattedValue = label.toLowerCase() === 'area' ? `${value} m²` : value;

    return (
        <div className={`mycard-container__card-content__body-rooms__info ${label.toLowerCase()}`}>
            <img src={icon} alt="" />
            <span>{formattedValue}</span>
        </div>
    );
};

RoomInfo.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
};

export default RoomInfo;
