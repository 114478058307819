// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-dots{
    position: absolute;
    bottom: 10px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    z-index: 1000000;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 15px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .75;
    color: #FFFFFF;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant-numeric: lining-nums;
    font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum=1';
    -ms-font-feature-settings: 'lnum';
    -webkit-font-feature-settings: 'lnum';
    -o-font-feature-settings: 'lnum';
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #E7C78B;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/custom-dots.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,WAAW;IACX,UAAU;IACV,SAAS;IACT,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,oBAAoB;IACpB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,mCAAmC;IACnC,kCAAkC;IAClC,iCAAiC;IACjC,6BAA6B;IAC7B,oCAAoC;IACpC,iCAAiC;IACjC,qCAAqC;IACrC,gCAAgC;AACpC;AACA;IACI,UAAU;IACV,cAAc;AAClB;AACA;;IAEI,aAAa;AACjB","sourcesContent":[".slick-dots{\n    position: absolute;\n    bottom: 10px;\n    display: block;\n    width: 100%;\n    padding: 0;\n    margin: 0;\n    list-style: none;\n    text-align: center;\n    z-index: 1000000;\n}\n.slick-dots li button:before {\n    font-family: 'slick';\n    font-size: 15px;\n    line-height: 20px;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 20px;\n    height: 20px;\n    content: '•';\n    text-align: center;\n    opacity: .75;\n    color: #FFFFFF;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    font-variant-numeric: lining-nums;\n    font-feature-settings: 'lnum';\n    -moz-font-feature-settings: 'lnum=1';\n    -ms-font-feature-settings: 'lnum';\n    -webkit-font-feature-settings: 'lnum';\n    -o-font-feature-settings: 'lnum';\n}\n.slick-dots li.slick-active button:before {\n    opacity: 1;\n    color: #E7C78B;\n}\n.slick-dots li button:hover,\n.slick-dots li button:focus {\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
