import React from 'react';
import PropTypes from 'prop-types';
import Down from '../../../assets/images/icon/down.svg';
import {useTranslation} from "react-i18next";

const AreaFilter = (
    {
        isAreaActive,
        isAreaDropdownOpen,
        areaFrom,
        areaTo,
        handleAreaInputClick,
        handleAreaInputChange,
        toggleAreaDropdown,
    }) => {
    const isAreaFilled = areaFrom.length > 0 || areaTo.length > 0;
    const { t } = useTranslation();
    return (
        <div className={`filters-container__area ${isAreaActive ? 'active' : ''}`} onClick={toggleAreaDropdown}>
            <img src={Down} alt='' className={`down-option ${isAreaDropdownOpen ? 'rotate' : ''}`} />
            <div className='filled'>
                {(areaFrom.length > 0 || areaTo.length > 0) && <p>{t('Area (m²)')}</p>}
            </div>
            <div className={`filters-container__area-dropdown ${isAreaFilled ? 'field-filled' : ''}`}>
                <span>{isAreaFilled ? `${areaFrom} - ${areaTo}` : t('Area (m²)')}</span>
            </div>
            {isAreaDropdownOpen && (
                <div className='filters-container__area-dropdown__list' onClick={handleAreaInputClick}>
                    <div className='filters-container__area-dropdown__list-container'>
                        <div className='filters-container__area-dropdown__list-container__from'>
                            <p>{t('from')}</p>
                            <input
                                placeholder=''
                                value={areaFrom}
                                onChange={(event) => handleAreaInputChange('from', event)}
                            />
                        </div>
                        <div className='filters-container__area-dropdown__list-container__to'>
                            <p>{t('to')}</p>
                            <input
                                placeholder=''
                                value={areaTo}
                                onChange={(event) => handleAreaInputChange('to', event)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
AreaFilter.propTypes = {
    isAreaActive: PropTypes.bool.isRequired,
    isAreaDropdownOpen: PropTypes.bool.isRequired,
    areaFrom: PropTypes.string.isRequired,
    areaTo: PropTypes.string.isRequired,
    handleAreaInputClick: PropTypes.func.isRequired,
    handleAreaInputChange: PropTypes.func.isRequired,
    toggleAreaDropdown: PropTypes.func.isRequired,
};

export default AreaFilter;
