/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Link, useParams} from 'react-router-dom';
import { getPropertyObjects } from '../../../action/object_api';
import { MoonLoader } from 'react-spinners';
import {useFilters} from "../../../context/SearchContext";
import {useTranslation} from "react-i18next";
import {usePageContext} from "../../../context/PageContext";
import Sorting from "../../../components/sorting";
import Card from "../../../components/objects/card";
import Pagination from "../../../components/pagination";

const ListObjectsAgent = ({ selectedFilters }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { savedSearch } = useFilters();
    const { currentPage, setCurrentPage } = usePageContext()
    const [objects, setObjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [sortParam, setSortParam] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const combinedFilters = {
                    ...savedSearch,
                    ...selectedFilters,
                    ...Object.entries(savedSearch || {}).reduce((acc, [key, value]) => (value ? { ...acc, [key]: value } : acc), {}),
                    ...Object.entries(selectedFilters || {}).reduce((acc, [key, value]) => (value ? { ...acc, [key]: value } : acc), {})
                };
                const response = await getPropertyObjects({ ...combinedFilters, users: [id] }, currentPage, sortParam);
                if (!response) {
                    console.error('Error fetching property objects: Response is undefined');
                    return;
                }

                const selectedCity = selectedFilters.location ? selectedFilters.location.city : null;
                if (selectedCity && response.location && response.location.city !== selectedCity) {
                    console.warn('Warning: The response city does not match the selected city.');
                    return;
                }

                const objectsData = response.content;
                if (!objectsData || !Array.isArray(objectsData)) {
                    console.error('Error fetching property objects: Invalid content in response data');
                    return;
                }

                setTotalPages(response.totalPages);
                setObjects(objectsData);
            } catch (error) {
                console.error('Error fetching property objects:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [savedSearch, selectedFilters, currentPage, setCurrentPage, sortParam, id]);

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };
    const handleSortChange = (newSortParam) => {
        if (newSortParam !== sortParam) {
            setSortParam(newSortParam);
            setCurrentPage(0);
        }
    };

    return (
        <div className='list'>
            <Sorting
                onSortChange={handleSortChange}
                currentPage={currentPage}
            />
            {loading ? (
                <div className='load'>
                    <MoonLoader color={'#E7C78B'} loading={loading} size={30} />
                </div>
            ) : (
                <div className='list-container'>
                    {objects.length > 0 ? (
                        <>
                            {objects.map((object) => (
                                <Link key={object.id} to={`/${object.id}`} target='_blank' rel='noopener noreferrer'>
                                    <Card object={object} isFavorite={object.isFavourite} />
                                </Link>
                            ))}
                            {[8, 5, 2].map((count) => (
                                Array.from({ length: count }, (_, index) => (
                                    <div key={index} className='empty-item'></div>
                                ))
                            ))}
                        </>
                    ) : (
                        <p>{t('Nothing found')}</p>
                    )}
                </div>
            )}
            <Pagination key={currentPage} totalPages={totalPages} currentPage={currentPage} onPageClick={handlePageClick} />
        </div>
    );
};

ListObjectsAgent.propTypes = {
    selectedFilters: PropTypes.shape({
        location: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                city: PropTypes.string,
            }),
        ]),
    }),
};

export default ListObjectsAgent;
