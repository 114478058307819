import React from 'react';
import PropTypes from 'prop-types';

const AgentButton = ({ icon, label, onClick }) => (
    <div className={`card-container__card-content__agent-btns__btn ${label ? label.toLowerCase() : ''}`}>
        <button onClick={onClick}>
            <img src={icon} alt='' />
            {label && <span>{label}</span>}
        </button>
    </div>
);


AgentButton.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export default AgentButton;
