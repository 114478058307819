import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './styles/styles.css';

const Title = ({ text }) => {
    const { t } = useTranslation();

    return (
        <h2>{t(text)}</h2>
    );
};

Title.propTypes = {
    text: PropTypes.string.isRequired,
};

export default Title;
