/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
    dismissObjectById,
    getObjectById,
    getPropertyObjectsActiveAdmin,
    getPropertyObjectsConsiderationAdmin
} from '../../../action/object_api';
import CardAdmin from '../card';
import Pagination from '../../pagination';
import {MoonLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Modal from "../../modal";

const Active = ({ searchId }) => {
    const { t } = useTranslation();
    const [objects, setObjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (searchId) {
                    const response = await getObjectById(searchId);
                    const filteredResponse = response && response.status === "ACTIVE" ? [response] : [];
                    setObjects(filteredResponse);
                    setLoading(false);
                } else {
                    const response = await getPropertyObjectsActiveAdmin(currentPage);
                    const filteredResponse = response.content.filter(object => object.status === "ACTIVE");
                    setObjects(filteredResponse);
                    setTotalPages(response.totalPages);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                setObjects([]);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentPage, searchId]);

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };
    const handleDeleteObject = (objectId) => {
        setObjects(objects.filter(obj => obj.id !== objectId));
    }

    const filteredObjects = objects;
    const shouldDisplayPagination = filteredObjects.length > 0 && !loading;

    return (
        <div className='list'>
            {loading ? (
                <div className='load'>
                    <MoonLoader color={'#E7C78B'} loading={loading} size={30} />
                </div>
            ) : (
                <div className='list-container'>
                    {filteredObjects.length > 0 ? (
                        <>
                            {filteredObjects.map((object) => (
                                <Link to={`/${object.id}`} target='_blank' rel='noopener noreferrer'>
                                    <CardAdmin key={object.id} object={object} onDelete={handleDeleteObject}/>
                                </Link>
                            ))}
                            {[8, 5, 2].map((count) => (
                                Array.from({ length: count }, (_, index) => (
                                    <div key={index} className='empty-item'></div>
                                ))
                            ))}
                        </>
                    ) : (
                        <p>{t('Nothing found')}</p>
                    )}
                </div>
            )}
            {shouldDisplayPagination && (
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageClick={handlePageClick} />
            )}
        </div>
    );
};

export default Active;
