/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useObjectCount } from "../../../context/ObjectCountContext";
import { archiveObjectById, deleteObjectById, publishObjectById } from '../../../action/object_api';
import Modal from '../../modal';
import RoomInfo from "./room_info";
import CustomButton from "./custom_buttom";
import {useTranslation} from "react-i18next";
import Slider from 'react-slick';
import Bed from '../../../assets/images/icon/bed_yellow.svg';
import Bath from '../../../assets/images/icon/bath_yellow.svg';
import SQFT from '../../../assets/images/icon/sqft.svg';
import Geo from '../../../assets/images/icon/pin_yellow.svg';
import Edit from '../../../assets/images/btns/edit.svg';
import Archive from '../../../assets/images/btns/archive.svg';
import PDF from '../../../assets/images/btns/add_pdf.svg';
import Delete from '../../../assets/images/btns/delete.svg';
import Restore from '../../../assets/images/btns/restore.svg';
import ErrorIcon from '../../../assets/images/icon/warning.svg';
import PropTypes from 'prop-types';
import './styles/styles.css';
import './styles/@styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {getUser} from "../../../action/user_api";
import {addToFavorites, removeFromFavorites} from "../../../action/favourite_api";
import {useFavouriteCount} from "../../../context/FavouriteCountContext";

const TAB_DRAFT = 'DRAFT';
const TAB_ARCHIVE = 'ARCHIVE';
const TAB_ACTIVE = 'ACTIVE';
const TAB_CONSIDERATION = 'CONSIDERATION';
const TAB_DISMISSED = 'DISMISSED';

const Card = ({ object, activeTab, onDelete }) => {
    const { t } = useTranslation();
    const { updateFavouriteCount } = useFavouriteCount();
    const isFavourite = object.hasOwnProperty('isFavourite') ? object.isFavourite : false;
    const [isDeleting, setIsDeleting] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);
    const [isRestore, setIsRestore] = useState(false);
    const [isPublish, setIsPublish] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDeleteArchiveConfirmation, setShowDeleteArchiveConfirmation] = useState(false);
    const [showArchiveConfirmationArchive, setShowArchiveConfirmationArchive] = useState(false);
    const [showRestoreConfirmation, setShowRestoreConfirmation] = useState(false);
    const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
    const [images, setImages] = useState([]);
    const [favorite, setFavorite] = useState(isFavourite);
    const [isHandlingClick, setIsHandlingClick] = useState(false);
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const { updateObjectCount } = useObjectCount();

    useEffect(() => {
        const fetchImages = async () => {
            try {
                // eslint-disable-next-line react/prop-types
                const imageArray = object.media
                    // eslint-disable-next-line react/prop-types
                    .filter((media) => media.mediaType === 'ACCOMMODATION_INTERIOR')
                    .map((media) => ({
                        id: media.id,
                        storageFileName: media.storageFileName
                    }));

                setImages(imageArray.slice(0, 5));
            } catch (error) {
                console.error('Error fetching property images:', error);
            }
        };

        // eslint-disable-next-line react/prop-types
        if (object.media.length > 0) {
            fetchImages();
        }
        // eslint-disable-next-line react/prop-types
    }, [object.media, object.id]);

    const handleEdit = () => {
        window.open(`/my_objects/edit/${object.id}`, '_blank');
    };
    const handleDelete = async () => {
        if (activeTab === TAB_DRAFT) {
            setShowDeleteConfirmation(true);
        } else if (activeTab === TAB_ARCHIVE) {
            setShowDeleteArchiveConfirmation(true);
        }
    };
    const handleConfirmDelete = async () => {
        try {
            setIsDeleting(true);
            await deleteObjectById(object.id);
            onDelete(object.id);
            updateObjectCount((prevCount) => prevCount - 1);
        } catch (error) {
            console.error('Error deleting object:', error);
        } finally {
            setIsDeleting(false);
            setShowDeleteConfirmation(false);
            setShowDeleteArchiveConfirmation(false);
        }
    };
    const handleCancelDelete = () => {
        setShowDeleteConfirmation(false);
        setShowDeleteArchiveConfirmation(false);
    };
    const handleConfirmArchive = async () => {
        try {
            setIsArchiving(true);
            await archiveObjectById(object.id);
            onDelete(object.id);
            setShowArchiveConfirmationArchive(true);
        } catch (error) {
            console.error('Error archiving object:', error);
        } finally {
            setIsArchiving(false);
            setShowDeleteConfirmation(false);
            setShowDeleteArchiveConfirmation(false);
        }
    };
    const handleArchive = () => {
        setShowArchiveConfirmationArchive(true);
    };
    const handleCancelArchive = () => {
        setShowArchiveConfirmationArchive(false);
    };
    const handleRestore = () => {
        setShowRestoreConfirmation(true);
    };
    const handleCancelRestore = () => {
        setShowRestoreConfirmation(false);
    };
    const handleConfirmRestore = async () => {
        try {
            setIsRestore(true);
            await publishObjectById(object.id);
            setShowRestoreConfirmation(true);
            onDelete(object.id);
        } catch (error) {
            console.error('Error restoring object:', error);
        } finally {
            setIsRestore(false);
            setShowRestoreConfirmation(false);
        }
    };
    const handlePublish = () => {
        setShowPublishConfirmation(true);
    };
    const handlePublishRestore = async () => {
        try {
            setIsPublish(true);
            await publishObjectById(object.id);
            setShowPublishConfirmation(true);
            onDelete(object.id);
        } catch (error) {
            console.error('Error restoring object:', error);
        } finally {
            setIsPublish(false);
            setShowPublishConfirmation(false);
        }
    };
    const handleCancelPublish = () => {
        setShowPublishConfirmation(false);
    };
    const handlePresentationClick = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (isHandlingClick) {
            return;
        }
        try {
            setIsHandlingClick(true);

            const currentUser = await getUser();
            if (!object.isFavourite) {
                await addToFavorites(object.id, currentUser.id);
                updateFavouriteCount((prevCount) => prevCount + 1);
            }
        } catch (error) {
            console.error('Error toggling favorites:', error);
        } finally {
            setIsHandlingClick(false);
        }
    };

    return(
        <div className='mycard'>
            <div className='mycard-container'>
                <div className='mycard-container__card'>
                    {object.status === "DISMISSED" &&(
                        <div className='mycard-container__card-comment'>
                            <div className='mycard-container__card-comment__error'>
                                <img src={ErrorIcon} alt=''/>
                            </div>
                            <div className='mycard-container__card-comment__text'>
                                <span>
                                    {t('Ad didnt pass verification.')}<br/><br/>{t('Detailed error description sent to you by mail.')}
                                </span>
                            </div>
                        </div>
                    )}
                    <div className='mycard-container__card-carousel'>
                        <Slider {...sliderSettings}>
                            {Array.isArray(images) && images.length > 0 ? (
                                images.map((image, index) => {
                                    return (
                                        <img
                                            key={index}
                                            src={image.storageFileName}
                                            alt={`Image ${index + 1}`}
                                        />
                                    );
                                })
                            ) : (
                                <p>{t('No images available')}</p>
                            )}
                        </Slider>
                    </div>
                    <div className='mycard-container__card-content'>
                        <div className='mycard-container__card-content__body'>
                            <div className='mycard-container__card-content__body-rooms'>
                                <RoomInfo icon={Bed} label="Bed" value={object.beds} />
                                <RoomInfo icon={Bath} label="Bath" value={object.baths} />
                                <RoomInfo icon={SQFT} label="Area" value={object.area} />
                            </div>
                            <div className='mycard-container__card-content__body-id'>
                                <span className='mycard-container__card-content__body-id__text id'>id:</span>
                                <span className='mycard-container__card-content__body-id__text number'>{object.id}</span>
                            </div>
                        </div>
                        <div className='mycard-container__card-content__price'>
                            <span>{object.currency}</span>
                            <span>{object.price}</span>
                        </div>
                        <div className='mycard-container__card-content__location'>
                            <div className='mycard-container__card-content__location-name' title={object.name}>
                                <span>{object.name}</span>
                            </div>
                            <div className='mycard-container__card-content__location-geo'>
                                <img src={Geo} alt=''/>
                                <span>{object.city} - {object.developer.name}</span>
                            </div>
                        </div>
                        <div className='mycard-container__card-content__btns'>
                            <div className='mycard-container__card-content__btns-control'>
                                {activeTab === TAB_CONSIDERATION && (
                                    <>
                                        <CustomButton icon={Edit} text="Edit" onClick={handleEdit} title={t('Edit')}/>
                                        <CustomButton icon={Archive} text="toArchive" onClick={handleArchive} disabled={isArchiving} title={t('To archive')}/>
                                    </>
                                )}
                                {activeTab === TAB_ACTIVE && (
                                    <>
                                        <CustomButton icon={Edit} text="Edit" onClick={handleEdit} title={t('Edit')}/>
                                        <CustomButton icon={Archive} text="toArchive" onClick={handleArchive} disabled={isArchiving} title={t('To archive')}/>
                                    </>
                                )}
                                {activeTab === TAB_DRAFT && (
                                    <>
                                        <CustomButton text="Publish" onClick={handlePublish} disabled={isPublish} title={t('Publish')} icon=''/>
                                        <CustomButton icon={Edit} text="Edit" onClick={handleEdit} title={t('Edit')}/>
                                        <CustomButton icon={Delete} text="Delete" onClick={handleDelete} disabled={isDeleting} title={t('toDelete')}/>
                                    </>
                                )}
                                {activeTab === TAB_ARCHIVE && (
                                    <>
                                        <CustomButton icon={Restore} text="Restore" onClick={handleRestore} disabled={isRestore} title={t('Restore')}/>
                                        <CustomButton icon={Delete} text="Delete" onClick={handleDelete} disabled={isDeleting} title={t('toDelete')}/>
                                    </>
                                )}
                                {activeTab === TAB_DISMISSED && (
                                    <>
                                        <CustomButton icon={Edit} text="toEdit" onClick={handleEdit} title={t('Edit')}/>
                                        <CustomButton icon={Delete} text="Delete" onClick={handleDelete} disabled={isDeleting} title={t('toDelete')}/>
                                    </>
                                )}
                            </div>
                            <div>
                                {activeTab === TAB_ACTIVE && (
                                    <div className='mycard-container__card-content__btns-presentation'>
                                        <button onClick={handlePresentationClick}><img src={PDF} alt=''/></button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showDeleteConfirmation && (
                <Modal
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                    showModal
                    showCancel
                    showConfirm
                    content={t('Do you want to permanently delete a draft?')}
                    confirmText={t('Delete')}
                    cancelText={t('Cancel')}
                />
            )}
            {showDeleteArchiveConfirmation && (
                <Modal
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                    showModal
                    showCancel
                    showConfirm
                    content={t('Do you want to permanently delete an object?')}
                    confirmText={t('Delete')}
                    cancelText={t('Cancel')}
                />
            )}
            {showArchiveConfirmationArchive && (
                <Modal
                    onCancel={handleCancelArchive}
                    onArchive={handleConfirmArchive}
                    showCancel
                    showArchive
                    showModal
                    content={t('Do you want to unpublish an object and move it to the Archive?')}
                    confirmText={t('To archive')}
                    cancelText={t('Cancel')}
                />
            )}
            {showRestoreConfirmation && (
                <Modal
                    onCancel={handleCancelRestore}
                    onRestore={handleConfirmRestore}
                    showModal
                    showCancel
                    showRestore
                    content={t('Do you want to publish an object?')}
                    confirmText={t('Restore')}
                    cancelText={t('Cancel')}
                />
            )}
            {showPublishConfirmation && (
                <Modal
                    onCancel={handleCancelPublish}
                    onPublish={handlePublishRestore}
                    showModal
                    showCancel
                    showPublish
                    content={t('Do you want to publish an object?')}
                    confirmText={t('Publish')}
                    cancelText={t('Cancel')}
                />
            )}
        </div>
    );
};

Card.propTypes = {
    object: PropTypes.shape({
        id: PropTypes.number.isRequired,
        beds: PropTypes.number.isRequired,
        baths: PropTypes.number.isRequired,
        area: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        moderatorComment: PropTypes.string.isRequired,
        developer: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    activeTab: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default Card;

