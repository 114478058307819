import axios from 'axios';
import { getTokens } from './auth_token';

// Create developer
export const createDeveloper = async (developerData) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const response = await axios.put(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + '/api/v1/developers',
            developerData,
            config
        );

        return response.data;
    } catch (error) {
        console.error("Error when performing a PUT request to /api/v1/developers:", error);
        throw error;
    }
};

// Get Developers
export const getAllDevelopers = async (searchItem = "") => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const requestBody = {
            searchItem,
        };

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + '/api/v1/developers',
            JSON.stringify(requestBody),
            config
        );

        return response.data;
    } catch (error) {
        console.error("Error when performing a POST request to /api/v1/developers:", error);
        throw error;
    }
};



