import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import enTranslation from './language/en.json';
import arTranslation from './language/ar.json';
import './custom-dots.css';
import './custom.css';
import './index.css';
import './assets/fonts/font.css';
import './phone-input-custom.css';
import './main.css';
import {AuthProvider} from "./context/AuthContext";
import {ObjectCountProvider} from "./context/ObjectCountContext";
import {FiltersProvider} from "./context/SearchContext";
import {FavouriteCountProvider} from "./context/FavouriteCountContext";
import ruTranslation from './language/ru.json';
import {PageProvider} from "./context/PageContext";
import '../src/assets/fonts/font.css';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslation,
            },
            ar: {
                translation: arTranslation,
            },
            ru: {
                translation: ruTranslation,
            }
        },
        lng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <AuthProvider>
          <ObjectCountProvider>
              <FavouriteCountProvider>
                  <FiltersProvider>
                      <PageProvider>
                          <App/>
                      </PageProvider>
                  </FiltersProvider>
              </FavouriteCountProvider>
          </ObjectCountProvider>
      </AuthProvider>
  </React.StrictMode>
);
reportWebVitals();
