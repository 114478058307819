/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {getUser} from "../../action/user_api";
import {useTranslation} from 'react-i18next';
import {useAuth} from '../../context/AuthContext';
import {useObjectCount} from '../../context/ObjectCountContext';
import {PulseLoader} from "react-spinners";
import {Link} from "react-router-dom";
import SearchIcon from '../../assets/images/btns/search.svg';
import SearchIconMobile from '../../assets/images/btns/search_black.svg';
import ObjectsIcon from '../../assets/images/icon/home.svg';
import ObjectsIconMobile from '../../assets/images/icon/home_black.svg';
import FavouriteIcon from '../../assets/images/icon/favourites.svg';
import FavouriteIconMobile from '../../assets/images/icon/favourites_black.svg';
import PresentationsIcon from '../../assets/images/btns/presentation.svg';
import PresentationsIconMobile from '../../assets/images/icon/presentation_black.svg';
// import Req from '../../assets/images/icon/request.svg';
// import ReqMob from '../../assets/images/icon/request_black.svg';
import UserIconMobile from '../../assets/images/icon/user_black.svg';
import Logo from '../../assets/images/icon/logo.svg';
import Admin from '../../assets/images/icon/admin.svg';
import {useFavouriteCount} from "../../context/FavouriteCountContext";
import { getFavoriteObjectsCount} from "../../action/favourite_api";
import './styles/styles.css';
import './styles/@styles.css';
import UserDropdown from "../user_dropdown";
import LanguageDropdown from "../language_dropdown";
import LanguageDropdownMobile from "../language_dropdown_mobile";

const Header = () => {
    const { t } = useTranslation();
    const { logout } = useAuth();
    const { objectCount, updateObjectCount } = useObjectCount();
    const { favouriteCount, searchCount, updateFavouriteCount, updateSearchCount } = useFavouriteCount();
    const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [totalFavoritesAndSearches, setTotalFavoritesAndSearches] = useState(favouriteCount + searchCount);

    useEffect(() => {
        if (isBurgerMenuOpen) {
            document.body.classList.add('burger-menu-open');
        } else {
            document.body.classList.remove('burger-menu-open');
        }
        return () => {
            document.body.classList.remove('burger-menu-open');
        };
    }, [isBurgerMenuOpen]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = await getUser();
                setUserRole(user.role);

                const response = await getFavoriteObjectsCount(user.id);
                const totalObjectCount =
                    response.archiveAccommodations +
                    response.draftAccommodations +
                    response.activeAccommodations +
                    response.considerationAccommodations;

                updateObjectCount(totalObjectCount);

                const totalFavoritesAndSearches = response.favouriteAccommodations + response.searchSubscriptions;
                setTotalFavoritesAndSearches(totalFavoritesAndSearches);

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [updateObjectCount, updateFavouriteCount, updateSearchCount]);

    const toggleBurgerMenu = () => {
        setIsBurgerMenuOpen(!isBurgerMenuOpen);
    };
    const handleLogout = () => {
        logout();
        window.location.reload();
    };

    return (
        <div className={`header ${isBurgerMenuOpen ? 'open' : ''}`}>
            <div className='header-container'>
                <div className={`header-container__logo ${isBurgerMenuOpen ? 'open-logo' : ''}`}>
                    <a href='https://connect.tw' target='_blank' rel='noreferrer'>
                        <img src={Logo} alt=''/>
                        <span>connect.</span>
                    </a>
                </div>
                <div className='header-container__bar'>
                    <div className='header-container__bar-content search'>
                        <Link to='/'>
                            <img src={SearchIcon} alt="" />
                            <span>{t('Find housing')}</span>
                        </Link>
                    </div>
                    <div className='header-container__bar-content objects'>
                        <img src={ObjectsIcon} alt="" />
                        <Link to='/my_objects'><span>{t('My objects')}</span></Link>
                        {isLoading ? (
                            <div>
                                <PulseLoader color={'#E7C78B'} loading={isLoading} size={5} />
                            </div>
                        ) : (
                            <h3>{objectCount}</h3>
                        )}
                    </div>
                    <div className='header-container__bar-content favourite'>
                        <img src={FavouriteIcon} alt="" />
                        <Link to='/favourite'>
                            <span>{t('My Favourite')}</span>
                            {isLoading ? (
                                <div>
                                    <PulseLoader color={'#E7C78B'} loading={isLoading} size={5} />
                                </div>
                            ) : (
                                <h3>{totalFavoritesAndSearches}</h3>
                            )}
                        </Link>
                    </div>
                    {/*<div className='header-container__bar-content requests'>*/}
                    {/*    <img src={Req} alt="" />*/}
                    {/*    <Link to='/requests'>*/}
                    {/*        <span>{t('Requests')}</span>*/}
                    {/*        {isLoading ? (*/}
                    {/*            <div>*/}
                    {/*                <PulseLoader color={'#E7C78B'} loading={isLoading} size={5} />*/}
                    {/*            </div>*/}
                    {/*        ) : (*/}
                    {/*            <h3>{totalFavoritesAndSearches}</h3>*/}
                    {/*        )}*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                    <div className='header-container__bar-content presentations'>
                        <img src={PresentationsIcon} alt="" />
                        <span>{t('My presentations')}</span>
                    </div>
                    <LanguageDropdown/>
                    <UserDropdown/>
                </div>
                <div className='burger-menu' onClick={toggleBurgerMenu}>
                    <div className={`burger-menu__line ${isBurgerMenuOpen ? 'open-line' : ''}`}></div>
                    <div className={`burger-menu__line ${isBurgerMenuOpen ? 'open-line' : ''}`}></div>
                    <div className={`burger-menu__line ${isBurgerMenuOpen ? 'open-line' : ''}`}></div>
                </div>
            </div>
            {isBurgerMenuOpen && (
                <div className='burger'>
                    <div className='burger-container'>
                        <div className='burger-container__bar'>
                            <div className='burger-container__bar-content user'>
                                <img src={UserIconMobile} alt="" />
                                <Link to='/my_profile'>
                                    <span>{t('Profile')}</span>
                                </Link>
                            </div>
                            <div className='burger-container__bar-content search'>
                                <Link to='/'>
                                    <img src={SearchIconMobile} alt="" />
                                    <span>{t('Find housing')}</span>
                                </Link>
                            </div>
                            <div className='burger-container__bar-content objects'>
                                <img src={ObjectsIconMobile} alt="" />
                                <Link to='/my_objects'><span>{t('My objects')}</span></Link>
                            </div>
                            <div className='burger-container__bar-content favourite'>
                                <img src={FavouriteIconMobile} alt="" />
                                <Link to='/favourite'><span>{t('My Favourite')}</span></Link>
                            </div>
                            {/*<div className='burger-container__bar-content favourite'>*/}
                            {/*    <img src={ReqMob} alt="" />*/}
                            {/*    <Link to='/requests'><span>{t('Requests')}</span></Link>*/}
                            {/*</div>*/}
                            <div className='burger-container__bar-content presentations'>
                                <img src={PresentationsIconMobile} alt="" />
                                <span>{t('My presentations')}</span>
                            </div>
                            <LanguageDropdownMobile/>
                            {userRole !== 'ROLE_USER' && (
                                <div className='burger-container__bar-content moderation'>
                                    <Link to='/moderation/objects'>
                                        <img src={Admin} alt=''/>
                                        <span>{t('Moderation')}</span>
                                    </Link>
                                </div>
                            )}
                            <div className='burger-container__bar-content logout'>
                                <span onClick={handleLogout}>
                                    {t('Sign out')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;
