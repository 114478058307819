import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const ModalContacts = ({
                           onCancel,
                           showModal,
                           content,
                           cancelText,
                           showCancel,
                       }) => {
    const { t } = useTranslation();

    useEffect(() => {
        document.documentElement.classList.toggle(
            'modal-open',
            showModal
        );

        return () => {
            document.documentElement.classList.remove('modal-open');
        };
    }, [showModal]);

    const handleClick = (event) => {
        if (event && event.target.classList.contains('overlay')) {
            event.stopPropagation();
            event.preventDefault();
            if (onCancel) {
                onCancel(event);
            }
        }
    };
    const handleClickModal = (event) => {
        if (event && event.currentTarget.classList.contains('modal-visible')) {
            event.stopPropagation();
            event.preventDefault();
        }
    };
    const handleCallClick = () => {
        window.location.href = `tel:${content}`;
    };

    return (
        <>
            <div>
                <div
                    className={`overlay ${showModal ? 'overlay-visible' : ''}`}
                    onClick={handleClick}
                ></div>
                <div
                    className={`modal ${showModal ? 'modal-visible' : ''}`}
                    onClick={handleClickModal}
                >
                    <div className='modal-content'>
                        <p>{t('Agent phone number:')}</p>
                        <a href="#" onClick={handleCallClick} target="_self">+{content}</a>
                        <span>{t('Click on the number to call')}</span>
                    </div>
                    <div className='modal-btns'>
                        {showCancel && (
                            <button onClick={onCancel}>{cancelText}</button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

ModalContacts.propTypes = {
    onCancel: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    content: PropTypes.node.isRequired,
    cancelText: PropTypes.string.isRequired,
    showCancel: PropTypes.bool,
};

export default ModalContacts;
