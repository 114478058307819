import React from 'react';
import MapImg from '../../assets/images/icon/map.svg'
import './styles/styles.css';
import './styles/@styles.css';

const Map = () => {

    return(
        <div className='map'>
            <div className='map-container'>
                <img src={MapImg} alt=''/>
            </div>
        </div>
    )
}

export default Map;
