// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-dots {
    /*opacity: 0;*/
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-slide img {
    display: inline-block;
    box-sizing: border-box;
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/custom.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,cAAc;IACd,WAAW;IACX,UAAU;IACV,SAAS;IACT,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":[".slick-dots {\n    /*opacity: 0;*/\n    position: absolute;\n    bottom: -25px;\n    display: block;\n    width: 100%;\n    padding: 0;\n    margin: 0;\n    list-style: none;\n    text-align: center;\n}\n.slick-slide img {\n    display: inline-block;\n    box-sizing: border-box;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
