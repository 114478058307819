import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Title from '../../title';
import Tabs from '../../objects/view/tabs_desctop';
import HeaderModeration from '../header';
import Footer from '../../footer';
import UnderConsiderations from '../under_consideration';
import Active from '../active';
import SearchIcon from '../../../assets/images/btns/search.svg'
import './styles/styles.css';
import './styles/@styles.css';

const ObjectsModeration = () =>{
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('UnderConsideration');
    const [searchId, setSearchId] = useState('');
    const TABS = {
        UNDER_CONSIDERATION: 'UnderConsideration',
        ACTIVE: 'Active',
    };
    const TAB_LABELS = {
        UNDER_CONSIDERATION: 'Under consideration',
        ACTIVE: 'Active',
    };
    const tabs = [
        { key: TABS.UNDER_CONSIDERATION, label: t(TAB_LABELS.UNDER_CONSIDERATION) },
        { key: TABS.ACTIVE, label: t(TAB_LABELS.ACTIVE) },
    ];

    const handleSearchChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d+$/.test(inputValue)) {
            setSearchId(inputValue);
        } else if (inputValue === '' || inputValue === '-') {
            setSearchId(inputValue);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
        }
    };

    return(
        <div className='main' onKeyDown={handleKeyDown}>
            <HeaderModeration />
            <div className='object-moderation'>
                <div className='object-moderation-container'>
                    <div className='object-moderation-container__title'>
                        <Title text='Objects'/>
                    </div>
                    <div className='object-moderation-container__tabs'>
                        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <div className='object-moderation-container__tabs-search'>
                            <div className={`object-moderation-container__tabs-search__input ${searchId.length > 0 ? 'field' : ''}`}>
                                {searchId.length > 0 && <span>{t('Search by ID')}</span>}
                                <input
                                    type='number'
                                    placeholder={t('Search by ID')}
                                    onChange={handleSearchChange}
                                    value={searchId}
                                />
                                <img src={SearchIcon} alt=''/>
                            </div>
                        </div>
                    </div>
                    {activeTab === 'UnderConsideration' && (
                        <UnderConsiderations searchId={searchId}/>
                    )}
                    {activeTab === 'Active' && (
                        <Active searchId={searchId}/>
                    )}
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ObjectsModeration;
