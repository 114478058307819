/* eslint-disable */
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { getPropertyObjects, searchByCity } from "../../../action/object_api";
import Filters from '../../../components/filters';
import ListObjectsAgent from "../list";
import './styles/styles.css';

const AgentObject = () => {
    const { t } = useTranslation();
    const [objects, setObjects] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        selectedPropertyTypeOptions: [],
        selectedRoomOptions: {},
        priceFrom: '',
        priceTo: '',
        areaFrom: '',
        areaTo: '',
    });
    const [savedFilters, setSavedFilters] = useState({
        propertyType: [],
        beds: '',
        baths: '',
        priceFrom: '',
        priceTo: '',
        areaFrom: '',
        areaTo: '',
        city: '',
    });
    const [isFilterActive, setIsFilterActive] = useState(false);

    const handleSearch = async (searchParams) => {
        setSelectedFilters(searchParams);
        try {
            let response;
            if (searchParams.location) {
                response = await searchByCity(searchParams.location);
            } else {
                response = await getPropertyObjects(searchParams);
            }
            if (!response) {
                console.error('Error fetching property objects: Response is undefined');
                return;
            }
            const objectsData = response.content;

            if (!objectsData || !Array.isArray(objectsData)) {
                console.error('Error fetching property objects: Invalid content in response data');
                return;
            }
            setObjects(objectsData);
        } catch (error) {
            console.error('Error fetching property objects:', error);
        }
    };
    const handleSaveFiltersChange = useCallback((newFilters) => {
        setSavedFilters(newFilters);
        const hasSelectedFilters = (
            newFilters.propertyType.length > 0 ||
            (typeof newFilters.beds === 'number' && newFilters.beds > 0) ||
            (typeof newFilters.baths === 'number' && newFilters.baths > 0) ||
            (typeof newFilters.priceFrom === 'string' && newFilters.priceFrom.trim() !== '') ||
            (typeof newFilters.priceTo === 'string' && newFilters.priceTo.trim() !== '') ||
            (typeof newFilters.areaFrom === 'string' && newFilters.areaFrom.trim() !== '') ||
            (typeof newFilters.areaTo === 'string' && newFilters.areaTo.trim() !== '') ||
            (typeof newFilters.city === 'string' && newFilters.city.trim() !== '')
        );
        setIsFilterActive(hasSelectedFilters);
    }, []);

    return (
        <>
            <div className='container-listening'>
                <span>{t('Active listings')}</span>
            </div>
            <div className='container-filters'>
                <Filters
                    onSearch={handleSearch}
                    onSaveFiltersChange={handleSaveFiltersChange}
                />
            </div>
            <div className='container-objects'>
                <ListObjectsAgent selectedFilters={selectedFilters} objects={objects} />
            </div>
        </>
    );
};

export default AgentObject;
