export const getTokens = () => {
    const cookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('token='));
    const token = cookie ? cookie.split('=')[1] : null;

    if (!token) {
        throw new Error('No token');
    }

    return token;
};
