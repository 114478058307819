import React, {useEffect, useState} from 'react';
import { AuthProvider } from './context/AuthContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {getUser} from "./action/user_api";
import Objects from './pages/objects';
import CardView from './components/objects/view';
import MyObject from './pages/my_objects';
import CreateObject from './pages/objects/create_object';
import Favorite from './pages/favorite';
import EditObject from './pages/objects/edit_object';
import NotFound from './components/not_found';
import Agent from './pages/agent';
import MyProfile from './pages/my_profile';
import ObjectsModeration from './components/moderation/objects';
import UsersModeration from './components/moderation/users';
import MainUserModeration from "./components/moderation/users/user";

function App() {
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                const user = await getUser();
                setUserRole(user.role);
            } catch (error) {
                console.error('Error fetching user role:', error);
            }
        };

        fetchUserRole();
    }, []);

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    const handleBeforeUnload = () => {
        sessionStorage.removeItem('mediaFiles');
    };
    const handlePopState = () => {
        sessionStorage.removeItem('mediaFiles');
    };

    if (userRole === null) {
        return null;
    }

    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path='/' element={<Objects />} />
                    <Route path='/:id' element={<CardView />} />
                    <Route path='/my_objects' element={<MyObject />} />
                    <Route path='/my_objects/create' element={<CreateObject />} />
                    <Route path='/my_objects/edit/:id' element={<EditObject />} />
                    <Route path='/favourite' element={<Favorite />} />
                    <Route path='/agent/:id' element={<Agent />} />
                    <Route path='/my_profile' element={<MyProfile />} />
                    {userRole !== 'ROLE_USER' && (
                        <>
                            <Route path='/moderation/objects' element={<ObjectsModeration />} />
                            <Route path='/moderation/users' element={<UsersModeration />} />
                            <Route path='/moderation/users/:id' element={<MainUserModeration/>} />
                        </>
                    )}
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
