import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import SearchIcon from '../../../assets/images/btns/search.svg';
import GeoIcon from '../../../assets/images/icon/pin.svg';

const LocationFilter = (
    {
        locationSearchValue,
        isLocationDropdownOpen,
        locationSearchResults,
        toggleLocationDropdown,
        handleLocationSelect,
        setLocationSearchValue
    }) => {
    const { t } = useTranslation();
    const isInputFilled = (value) => {
        return value !== null && value !== undefined && value.toString().trim() !== '';
    };
    return (
        <div className={`filters-container__location ${isInputFilled(locationSearchValue) ? 'field' : ''}`} onClick={toggleLocationDropdown}>
            {locationSearchValue.length > 0 && <span>{t('Location')}</span>}
            <img className='filters-container__location-location' src={SearchIcon} alt='' />
            <img className='filters-container__location-geo' src={GeoIcon} alt='' />
            <input
                id='location'
                name='location'
                placeholder={t('Enter Location')}
                value={locationSearchValue}
                onChange={(e) => setLocationSearchValue(e.target.value)}
                autoComplete='off'
            />
            {isLocationDropdownOpen && locationSearchValue.length > 0 && (
                <div className='filters-container__location-dropdown'>
                    <div className='filters-container__location-dropdown__results'>
                        {locationSearchResults.map((result) => (
                            <div
                                key={result.id}
                                className='filters-container__location-dropdown__results-item'
                                onClick={() => handleLocationSelect(result)}
                            >
                                <p>{result.city}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

LocationFilter.propTypes = {
    locationSearchValue: PropTypes.string,
    isLocationDropdownOpen: PropTypes.bool,
    locationSearchResults: PropTypes.array,
    toggleLocationDropdown: PropTypes.func,
    handleLocationSelect: PropTypes.func,
    setLocationSearchValue: PropTypes.func,
};

export default LocationFilter;
