import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const TabList = ({ tabs, activeTab, handleTabClick }) => {
    const { t } = useTranslation();
    return (
        <div className='favourites-container__tabs-mobile__dropdown-list'>
            {tabs.map((tab) => (
                <div
                    key={tab.id}
                    className={`favourites-container__tabs-mobile__dropdown-list__item ${activeTab === tab.id ? 'active-tab' : ''}`}
                    onClick={() => handleTabClick(tab.id)}
                >
                    {t(tab.label)}
                </div>
            ))}
        </div>
    );
};

TabList.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    activeTab: PropTypes.string.isRequired,
    handleTabClick: PropTypes.func.isRequired,
};

export default TabList;
