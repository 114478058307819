// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Raleway', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum=1';
  -ms-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  -o-font-feature-settings: 'lnum';
}

html {
  overflow-x: hidden;
  background: #141414;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum=1';
  -ms-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  -o-font-feature-settings: 'lnum';
}

body::-webkit-scrollbar {
  width: 7px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

body.burger-menu-open {
  overflow: hidden;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,SAAS;EACT,UAAU;EACV;;YAEU;EACV,mCAAmC;EACnC,kCAAkC;EAClC,iCAAiC;EACjC,6BAA6B;EAC7B,oCAAoC;EACpC,iCAAiC;EACjC,qCAAqC;EACrC,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE;WACS;EACT,iCAAiC;EACjC,6BAA6B;EAC7B,oCAAoC;EACpC,iCAAiC;EACjC,qCAAqC;EACrC,gCAAgC;AAClC;;AAEA;EACE,UAAU;EACV,eAAe;EACf,MAAM;EACN,QAAQ;EACR,SAAS;EACT,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["body {\n  min-height: 100vh;\n  margin: 0;\n  padding: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Raleway', 'Oxygen',\n  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n  sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-variant-numeric: lining-nums;\n  font-feature-settings: 'lnum';\n  -moz-font-feature-settings: 'lnum=1';\n  -ms-font-feature-settings: 'lnum';\n  -webkit-font-feature-settings: 'lnum';\n  -o-font-feature-settings: 'lnum';\n}\n\nhtml {\n  overflow-x: hidden;\n  background: #141414;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n  monospace;\n  font-variant-numeric: lining-nums;\n  font-feature-settings: 'lnum';\n  -moz-font-feature-settings: 'lnum=1';\n  -ms-font-feature-settings: 'lnum';\n  -webkit-font-feature-settings: 'lnum';\n  -o-font-feature-settings: 'lnum';\n}\n\nbody::-webkit-scrollbar {\n  width: 7px;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 999;\n}\n\nbody::-webkit-scrollbar-thumb {\n  background-color: #888;\n  border-radius: 6px;\n}\n\nbody::-webkit-scrollbar-thumb:hover {\n  background-color: #555;\n}\n\nbody.burger-menu-open {\n  overflow: hidden;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
