/* eslint-disable */
import React, { createContext, useContext, useState } from 'react';

const FiltersContext = createContext();

export const useFilters = () => {
    const context = useContext(FiltersContext);
    if (!context) {
        throw Error;
    }
    return context;
};

export const FiltersProvider = ({ children }) => {
    const [savedSearch, setSavedSearch] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState(null);
    const [listObjectsFilters, setListObjectsFilters] = useState(null);

    const updateFilters = (newFilters) => {
        setSavedSearch(newFilters);
        setListObjectsFilters(newFilters);
    };

    return (
        <FiltersContext.Provider value={{ savedSearch, selectedFilters, updateFilters, listObjectsFilters }}>
            {children}
        </FiltersContext.Provider>
    );
};

