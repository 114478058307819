/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import {createDeveloper, getAllDevelopers} from "../../../../action/developers_api";
import PropTypes, {object} from 'prop-types';
import Map from '../../../../assets/images/map.svg';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {getObjectById} from "../../../../action/object_api";
import {getUser} from "../../../../action/user_api";
import Forbidden from "../../../../components/forbidden";
import Title from "../../../../components/title";

const StepOne = ({ onNext, onFormChange,onDeveloperCreated, stepData }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [formValue, setFormValue] = useState(stepData[1]);
    const [developers, setDevelopers] = useState([]);
    const [errors, setErrors] = useState({
        name: '',
        developer: '',
        district: '',
        city: '',
        setFullAddress: '',
    });
    const [isDeveloperListOpen, setIsDeveloperListOpen] = useState(false);
    const [inputDeveloperText, setInputDeveloperText] = useState('');
    const [filteredDevelopers, setFilteredDevelopers] = useState([]);
    const [selectedDeveloper, setSelectedDeveloper] = useState(null);

    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchDevelopers = async () => {
            try {
                const allDevelopers = await getAllDevelopers();
                setDevelopers(allDevelopers.content);
            } catch (error) {
                console.error("Error fetching developers:", error);
            }
        };

        fetchDevelopers();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDeveloperListOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        setFilteredDevelopers(
            inputDeveloperText
                ? developers?.filter((dev) =>
                dev.name?.toLowerCase().startsWith(inputDeveloperText.toLowerCase())
            ) || []
                : []
        );
    }, [inputDeveloperText, developers]);

    useEffect(() => {
        const fetchDevelopers = async () => {
            try {
                const allDevelopers = await getAllDevelopers(inputDeveloperText);
                setDevelopers(allDevelopers.content);
            } catch (error) {
                console.error("Error fetching developers:", error);
            }
        };

        fetchDevelopers();
    }, [inputDeveloperText]);

    useEffect(() => {
        const fetchObject = async () => {
            try {
                const object = await getObjectById(id);
                setFormValue({
                    name: object.name,
                    developer: object.developer,
                    district: object.district,
                    city: object.city,
                    fullAddress: object.fullAddress,
                });
            } catch (error) {
                console.error('Error fetching object:', error);
            }
        };

        fetchObject();
    }, [id]);

    const handleDeveloperSelection = (selectedDeveloper) => {
        setFormValue((prevValues) => ({
            ...prevValues,
            developer: {
                id: selectedDeveloper.id,
                name: selectedDeveloper.name,
            },
        }));
        setSelectedDeveloper(selectedDeveloper);
        setIsDeveloperListOpen(false);
    };
    const validateForm = () => {
        const newErrors = {
            name: formValue.name.trim() === '' ? t('Fill in the complex name field') : '',
            developer: formValue.developer.name.trim() === '' ? t('Fill in the developer name field') : '',
            district: formValue.district.trim() === '' ? t('Fill in the district field') : '',
            city: formValue.city.trim() === '' ? t('Fill in the city field') : '',
            fullAddress: formValue.fullAddress.trim() === '' ? t('Fill in the full address field') : '',
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some((error) => error !== '');
    };
    const handleFieldChange = (fieldName, value) => {
        if (fieldName === 'developer') {
            if (developers && developers.length > 0) {
                setFormValue((prevValues) => ({
                    ...prevValues,
                    developer: {
                        id: developers.find((dev) => dev.name === value)?.id || 0,
                        name: value,
                    },
                }));
            } else {
                setFormValue((prevValues) => ({
                    ...prevValues,
                    developer: {
                        id: 0,
                        name: value,
                    },
                }));
            }
        } else {
            setFormValue((prevValues) => ({
                ...prevValues,
                [fieldName]: value,
            }));
        }
        setErrors({
            ...errors,
            [fieldName]: '',
        });
    };
    const handleNext = async () => {
        const isValid = validateForm();

        if (isValid) {
            const existingDeveloper = developers.find((dev) => dev.name === formValue.developer.name);

            if (!existingDeveloper && formValue.developer.name.trim() !== '') {
                try {
                    const createdDeveloper = await createDeveloper({
                        name: formValue.developer.name,
                    });
                    console.log("Developer created:", createdDeveloper);

                    setDevelopers((prevDevelopers) => [
                        ...prevDevelopers,
                        createdDeveloper,
                    ]);

                    setFormValue((prevValues) => ({
                        ...prevValues,
                        developer: {
                            id: createdDeveloper.id,
                            name: createdDeveloper.name,
                        },
                    }));

                    setSelectedDeveloper(createdDeveloper);
                    onDeveloperCreated(createdDeveloper);

                    console.log("Developers after update:", developers);
                } catch (error) {
                    console.error("Error creating developer:", error);
                }
            }
            setErrors({
                name: '',
                developer: '',
                district: '',
                city: '',
                fullAddress: '',
            });
            onFormChange(formValue);
            onNext();
        }
    };

    return (
        <div className='one'>
            <Title text='Location'/>
            <div className='one-form'>
                <div className='one-form__names'>
                    <div className='one-form__names-item complex'>
                        {formValue.name && <span>{t('Name of accommodation')}</span>}
                        <input
                            placeholder={t('Name of accommodation')}
                            value={formValue.name}
                            onChange={(e) => handleFieldChange('name', e.target.value)}
                        />
                        <div className='error-message'>{errors.name}</div>
                    </div>
                    <div className='one-form__names-item developer' onClick={() => setIsDeveloperListOpen(!isDeveloperListOpen)} ref={dropdownRef}>
                        {formValue.developer.name && <span>{t('Developer name')}</span>}
                        <input
                            placeholder={t('Developer name')}
                            value={formValue.developer.name}
                            onChange={(e) => {
                                setInputDeveloperText(e.target.value);
                                handleFieldChange('developer', e.target.value);
                            }}
                        />
                        {isDeveloperListOpen && filteredDevelopers.length > 0 && (
                            <div className='one-form__names-item__list'>
                                <div className='one-form__names-item__list-container'>
                                    {filteredDevelopers.map((dev) => (
                                        <div key={dev.id} onClick={() => handleDeveloperSelection(dev)}>
                                            <p>{dev.name}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className='error-message'>{errors.developer}</div>
                    </div>
                </div>
                <div className='one-form__adress'>
                    <div className='one-form__adress-item district'>
                        {formValue.district && <span>{t('District')}</span>}
                        <input
                            placeholder={t('District')}
                            value={formValue.district}
                            onChange={(e) => handleFieldChange('district', e.target.value)}
                        />
                        <div className='error-message'>{errors.district}</div>
                    </div>
                    <div className='one-form__adress-item city'>
                        {formValue.city && <span>{t('City')}</span>}
                        <input
                            placeholder={t('City')}
                            value={formValue.city}
                            onChange={(e) => handleFieldChange('city', e.target.value)}
                        />
                        <div className='error-message'>{errors.city}</div>
                    </div>
                    <div className='one-form__adress-item fullAddress'>
                        {formValue.fullAddress && <span>{t('Full address')}</span>}
                        <input
                            placeholder={t('Full address')}
                            value={formValue.fullAddress}
                            onChange={(e) => handleFieldChange('fullAddress', e.target.value)}
                        />
                        <div className='error-message'>{errors.fullAddress}</div>
                    </div>
                </div>
                <div className='one-form__map'>
                    <img src={Map} alt='' />
                </div>
            </div>
            <div className='one-btn'>
                <button type='button' onClick={handleNext}>{t('Continue')}</button>
            </div>
        </div>
    );
};

StepOne.propTypes = {
    onNext: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
};

export default StepOne;
