/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import { MoonLoader } from 'react-spinners';
import { getFavoriteObjects } from '../../../action/favourite_api';
import {
    deleteSavedSearch,
    getAllSavedSearches,
    getSavedSearchById
} from '../../../action/save_search_api';
import { getUser } from '../../../action/user_api';
import {Link, useNavigate} from 'react-router-dom';
import Card from '../../../components/objects/card';
import Down from '../../../assets/images/icon/down.svg';
import Delete from '../../../assets/images/btns/delete_red.svg';
import Next from '../../../assets/images/btns/next.svg';
import {useFilters} from "../../../context/SearchContext";
import DropdownTabs from "../dropdown_tabs";
import TabsContainer from "../tabs_container";
import {useTranslation} from "react-i18next";
import {useFavouriteCount} from "../../../context/FavouriteCountContext";
import './styles/styles.css';
import './styles/@styles.css';
import Title from "../../../components/title";

const MainFavourites = () => {
    const { t } = useTranslation();
    const { updateFilters } = useFilters();
    const { updateFavouriteCount } = useFavouriteCount();
    const [activeTab, setActiveTab] = useState('OBJECTS');
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [favoriteObjects, setFavoriteObjects] = useState([]);
    const [favoriteSearch, setFavoriteSearch] = useState([]);
    const [favouriteCount, setFavouriteCount] = useState(0);
    const [searchCount, setSearchCount] = useState(0);
    const [isSearchDeleted, setIsSearchDeleted] = useState(false);

    const navigate = useNavigate();

    const tabs = [
        { id: 'OBJECTS', label: t('Objects') },
        { id: 'SEARCH', label: t('Search') },
    ];

    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen)
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const user = await getUser();
                const response = await getFavoriteObjects(user.id);

                const favouriteObjectsWithFavorite = response.map(obj => ({ ...obj, isFavourite: true }));

                setFavoriteObjects(favouriteObjectsWithFavorite);
                setFavouriteCount(favouriteObjectsWithFavorite.length);
            } catch (error) {
                console.error('Error fetching favourite objects:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchAllSavedSearches = async () => {
            try {
                setIsLoading(true);

                const user = await getUser();
                const filterData = {
                    users: [user.id],
                };
                const response = await getAllSavedSearches(filterData);
                setFavoriteSearch(response.content);
                setSearchCount(response.content.length);
            } catch (error) {
                console.error('Error fetching all saved searches:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAllSavedSearches();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef]);

    const handleObjectDelete = (objectId) => {
        const updatedFavorites = favoriteObjects.filter(obj => obj.id !== objectId);
        setFavoriteObjects(updatedFavorites);
        setFavouriteCount(updatedFavorites.length);
    };
    const handleSearchDelete = async subscriptionId => {
        try {
            await deleteSavedSearch(subscriptionId);
            const updatedSearches = favoriteSearch.filter(search => search.id !== subscriptionId);
            setFavoriteSearch(updatedSearches);
            setSearchCount(updatedSearches.length);
            updateFavouriteCount((prevCount) => prevCount - 1);
            setIsSearchDeleted(true);

            setTimeout(() => {
                setIsSearchDeleted(false);
            }, 2000);
        } catch (error) {
            console.error('Error deleting saved search:', error);
        }
    };
    const handleSearchClick = async (subscriptionId) => {
        try {
            const searchDetails = await getSavedSearchById(subscriptionId);
            updateFilters(searchDetails.searchAttributes);
            navigate('/');
        } catch (error) {
            console.error('Error fetching search details:', error);
        }
    };
    const handleTabOptionClick = (tab) => {
        setActiveTab(tab);
        setDropdownOpen(false);
    };

    return(
        <div className='favourites'>
            <div className='favourites-container'>
                <div className='favourites-container__header'>
                    <Title text='Favourite'/>
                </div>
                <div className='favourites-container__tabs'>
                    <div className='favourites-container__tabs-container'>
                        <TabsContainer
                            activeTab={activeTab}
                            handleTabChange={setActiveTab}
                            tabs={tabs}
                        />
                    </div>
                    <div className='favourites-container__tabs-mobile'>
                        <div className='favourites-container__tabs-mobile__dropdown' onClick={toggleDropdown} ref={dropdownRef}>
                            <span>
                                {activeTab === 'OBJECTS' && t('Objects')}
                                {activeTab === 'SEARCH' && t('Search')}
                            </span>
                            <img src={Down} alt='' className={isDropdownOpen ? 'active-rotate' : ''} />
                            {isDropdownOpen && (
                                <DropdownTabs activeTab={activeTab} handleTabOptionClick={handleTabOptionClick} />
                            )}
                        </div>
                    </div>
                    {isLoading ? (
                        <div className='load'>
                            <MoonLoader color={'#E7C78B'} loading={isLoading} size={30} />
                        </div>
                    ) : (
                        <>
                            {activeTab === 'OBJECTS' && (
                                <div className='favourites-container__tabs-content'>
                                    {favoriteObjects.length > 0 ? (
                                        <>
                                            {favoriteObjects.map((object) => (
                                                <div className='favourites-container__tabs-content__item' key={object.id}>
                                                    <Link to={`/${object.id}`} target='_blank'>
                                                        <Card
                                                            object={object}
                                                            activeTab={activeTab}
                                                            onDelete={() => handleObjectDelete(object.id)}
                                                        />
                                                    </Link>
                                                </div>
                                            ))}
                                            {[8, 5, 2].map((count) => (
                                                Array.from({ length: count }, (_, index) => (
                                                    <div key={index} className='empty-item'></div>
                                                ))
                                            ))}
                                        </>
                                    ) : (
                                        <p>{t('You don\'t have any favorites objects')}</p>
                                    )}
                                </div>
                            )}
                            {activeTab === 'SEARCH' && (
                                <div className='favourites-container__tabs-search'>
                                    {favoriteSearch.length > 0 ? (
                                        <>
                                            {favoriteSearch.map((savedSearch) => (
                                                <div className='favourites-container__tabs-search__container' key={savedSearch.id}>
                                                    <div className='favourites-container__tabs-search__container-content'>
                                                        <div className='favourites-container__tabs-search__container-content__items-header'>
                                                            <span>{savedSearch.searchAttributes.city}</span>
                                                            <button onClick={() => handleSearchDelete(savedSearch.id)}>
                                                                <img src={Delete} alt=''/>
                                                            </button>
                                                        </div>
                                                        <div className='favourites-container__tabs-search__container-content__items-body'>
                                                            <div className='favourites-container__tabs-search__container-content__items-body__container'>
                                                                {savedSearch.searchAttributes.propertyType && savedSearch.searchAttributes.propertyType.length > 0 && (
                                                                    <div className='favourites-container__tabs-search__container-content__items-body__container-item'>
                                                                        <span>
                                                                            {savedSearch.searchAttributes.propertyType.map((propertyType, index) => (
                                                                                <React.Fragment key={index}>
                                                                                    {index > 0 && ', '}
                                                                                    {t(propertyType)}
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </span>
                                                                        {/*<button onClick={() => handleFilterRemove(savedSearch.id, 'propertyType')}>*/}
                                                                        {/*    <img src={Remove} alt=''/>*/}
                                                                        {/*</button>*/}
                                                                    </div>
                                                                )}
                                                                {(savedSearch.searchAttributes.priceFrom !== null || savedSearch.searchAttributes.priceTo !== null) && (
                                                                    <div className='favourites-container__tabs-search__container-content__items-body__container-item'>
                                                                        <span>{savedSearch.searchAttributes.priceFrom} - {savedSearch.searchAttributes.priceTo} (AED)</span>
                                                                    </div>
                                                                )}
                                                                {(savedSearch.searchAttributes.areaFrom !== null || savedSearch.searchAttributes.areaTo !== null) && (
                                                                    <div className='favourites-container__tabs-search__container-content__items-body__container-item'>
                                                                        <span>{savedSearch.searchAttributes.areaFrom} - {savedSearch.searchAttributes.areaTo} {t('(m²)')}</span>
                                                                    </div>
                                                                )}
                                                                {savedSearch.searchAttributes.beds !== null && (
                                                                    <div className='favourites-container__tabs-search__container-content__items-body__container-item'>
                                                                        <span>{t('Beds')}: {savedSearch.searchAttributes.beds}</span>
                                                                    </div>
                                                                )}
                                                                {savedSearch.searchAttributes.baths !== null && (
                                                                    <div className='favourites-container__tabs-search__container-content__items-body__container-item'>
                                                                        <span>{t('Baths')}: {savedSearch.searchAttributes.baths}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='favourites-container__tabs-search__container-content__items-body__search'>
                                                                <button onClick={() => handleSearchClick(savedSearch.id)}>
                                                                    <span>{t('Search')}</span>
                                                                    <img src={Next} alt=''/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {isSearchDeleted && <h5>{t('Saved Search deleted successfully')}</h5>}
                                        </>
                                    ) : (
                                        <p>{t('You don\'t have any saved searches')}</p>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default MainFavourites;
