import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Header from '../../components/header';
import Footer from "../../components/footer";
import Main from './main';

const Objects = () => {
    return(
        <div className='main'>
            <Header />
            <Routes>
                <Route path='/' element={<Main />} />
            </Routes>
            <Footer/>
        </div>
    )
}

export default Objects;
