import React, { createContext, useContext, useState } from 'react';

const FavouriteCountContext = createContext();

// eslint-disable-next-line react/prop-types
export const FavouriteCountProvider = ({ children }) => {
    const [favouriteCount, setFavouriteCount] = useState(0);
    const [searchCount, setSearchCount] = useState(0);

    const updateFavouriteCount = (count) => {
        // console.log('favourite', count);
        setFavouriteCount(count);
    };

    const updateSearchCount = (count) => {
        // console.log('search', count);
        setSearchCount(count);
    };

    return (
        <FavouriteCountContext.Provider
            value={{ favouriteCount, searchCount, updateFavouriteCount, updateSearchCount }}
        >
            {children}
        </FavouriteCountContext.Provider>
    );
};

export const useFavouriteCount = () => {
    return useContext(FavouriteCountContext);
};
