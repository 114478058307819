/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoonLoader } from "react-spinners";
import { getUserById, getUsers } from "../../../action/user_api";
import { getFavoriteObjectsCount } from "../../../action/favourite_api";
import Pagination from "../../pagination";
import Title from '../../title';
import HeaderModeration from '../header';
import Footer from '../../footer';
import SearchIcon from '../../../assets/images/btns/search.svg';
import './styles/styles.css';

const UsersModeration = ({ userId }) => {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [searchId, setSearchId] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (searchId) {
                    const response = await getUserById(searchId);
                    setUsers(response ? [response] : []);
                    setLoading(false);
                } else {
                    const usersData = await getUsers(currentPage);
                    const usersWithCounts = await Promise.all(usersData.content.map(async (user) => {
                        const countResponse = await getFavoriteObjectsCount(user.id);
                        const totalObjectsCount =
                            countResponse.archiveAccommodations +
                            countResponse.draftAccommodations +
                            countResponse.activeAccommodations +
                            countResponse.considerationAccommodations;
                        return { ...user, totalObjectsCount };
                    }));
                    setUsers(usersWithCounts.filter(Boolean));
                    setTotalPages(usersData.totalPages);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                setUsers([]);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentPage, searchId]);

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };
    const handleSearchChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d+$/.test(inputValue)) {
            setSearchId(inputValue);
        } else if (inputValue === '' || inputValue === '-') {
            setSearchId(inputValue);
        }
    };
    const handleClickUser = (userId) => {
        window.open(`/moderation/users/${userId}`, '_blank');
    };
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
        }
    };

    return (
        <div className='main' onKeyDown={handleKeyDown}>
            <HeaderModeration />
            <div className='users-moderation'>
                <div className='users-moderation-container'>
                    <div className='users-moderation-container__title'>
                        <Title text='Users'/>
                    </div>
                    <div className='users-moderation-container__search'>
                        <div className={`users-moderation-container__search-input ${searchId.length > 0 ? 'field' : ''}`}>
                            {searchId.length > 0 && <span>{t('Search by ID')}</span>}
                            <input
                                type='number'
                                placeholder={t('Search by ID')}
                                onChange={handleSearchChange}
                                value={searchId}
                            />
                            <img src={SearchIcon} alt=''/>
                        </div>
                    </div>
                    <div className='users-moderation-container__table'>
                        {loading ? (
                            <div className='load'>
                                <MoonLoader color={'#E7C78B'} loading={loading} size={30} />
                            </div>
                        ) : (
                            <div className='users-moderation-container__table-container'>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>{t('Name')}</th>
                                        <th>E-mail</th>
                                        <th>{t('License number')}</th>
                                        <th>{t('Agency')}</th>
                                        <th>{t('Number of objects')}</th>
                                        <th>{t('Phone')}</th>
                                        {/*<th>{t('Subscription')}</th>*/}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.length > 0 ? (
                                        users.map((user) => (
                                            <tr key={user.id} onClick={() => handleClickUser(user.id)}>
                                                <td>{user.id}</td>
                                                <td>{user.displayName}</td>
                                                <td>{user.email}</td>
                                                <td>{user.licenseNumber}</td>
                                                <td>{user.agency}</td>
                                                <td>{user.totalObjectsCount || null}</td>
                                                <td>{user.phone}</td>
                                                {/*<td></td>*/}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="8">{t('No user found')}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
                <div className='users-moderation-pagination'>
                    <Pagination totalPages={totalPages} currentPage={currentPage} onPageClick={handlePageClick} />
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default UsersModeration;
