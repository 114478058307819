import React, {createContext, useContext, useEffect, useState} from "react";

const AuthContext = createContext();

const getAuthTokenFromCookie = () => {
    const cookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('token='));
    return cookie ? cookie.split('=')[1] : null;
};

// eslint-disable-next-line react/prop-types
export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState(null);

    useEffect(() => {
        const authToken = getAuthTokenFromCookie();
        if (authToken) {
            setIsAuthenticated(true);
            setToken(authToken);
        } else {
            // eslint-disable-next-line no-undef
            window.location.href = process.env.REACT_APP_LANDING_URL;
        }
    }, []);

    const login = (authToken) => {
        setIsAuthenticated(true);
        setToken(authToken);
        console.log('isAuthenticatedLogin', isAuthenticated)
    };

    const logout = () => {
        setIsAuthenticated(false);
        setToken(null);
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.connect.tw';
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=192.168.0.222';
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=185.233.187.80';
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, token, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
