/* eslint-disable */
import React, {useCallback, useState} from 'react';
import { getPropertyObjects, searchByCity } from "../../../action/object_api";
import Filters from '../../../components/filters';
import Map from '../../../components/map/index';
import ListObjects from '../../../components/objects/list';
import SavedFilters from "../../../components/filters/saved_filters";
import {getUser} from "../../../action/user_api";
import {createSavedSearch} from "../../../action/save_search_api";
import {useFavouriteCount} from "../../../context/FavouriteCountContext";

const Main = () => {
    const { updateFavouriteCount } = useFavouriteCount();
    const [selectedFilters, setSelectedFilters] = useState({
        selectedPropertyTypeOptions: [],
        selectedRoomOptions: {},
        priceFrom: '',
        priceTo: '',
        areaFrom: '',
        areaTo: '',
    });
    const [savedFilters, setSavedFilters] = useState({
        propertyType: [],
        beds: '',
        baths: '',
        priceFrom: '',
        priceTo: '',
        areaFrom: '',
        areaTo: '',
        city: '',
    });
    const [objects, setObjects] = useState([]);
    const [isFilterActive, setIsFilterActive] = useState(false);

    const handleSearch = async (searchParams) => {
        setSelectedFilters(searchParams);
        try {
            let response;
            if (searchParams.location) {
                response = await searchByCity(searchParams.location);
            } else {
                response = await getPropertyObjects(searchParams);
            }
            if (!response) {
                console.error('Error fetching property objects: Response is undefined');
                return;
            }
            const objectsData = response.content;

            if (!objectsData || !Array.isArray(objectsData)) {
                console.error('Error fetching property objects: Invalid content in response data');
                return;
            }
            setObjects(objectsData);
        } catch (error) {
            console.error('Error fetching property objects:', error);
        }
    };
    const handleSaveFiltersChange = useCallback((newFilters) => {
        setSavedFilters(newFilters);
        const hasSelectedFilters = (
            newFilters.propertyType.length > 0 ||
            (typeof newFilters.beds === 'number' && newFilters.beds > 0) ||
            (typeof newFilters.baths === 'number' && newFilters.baths > 0) ||
            (typeof newFilters.priceFrom === 'string' && newFilters.priceFrom.trim() !== '') ||
            (typeof newFilters.priceTo === 'string' && newFilters.priceTo.trim() !== '') ||
            (typeof newFilters.areaFrom === 'string' && newFilters.areaFrom.trim() !== '') ||
            (typeof newFilters.areaTo === 'string' && newFilters.areaTo.trim() !== '') ||
            (typeof newFilters.city === 'string' && newFilters.city.trim() !== '')
        );
        setIsFilterActive(hasSelectedFilters);
    }, []);
    const handleSaveSearch = async () => {
        try {
            const loggedInUser = await getUser();
            await createSavedSearch(loggedInUser, savedFilters);
            updateFavouriteCount((prevCount) => prevCount + 1);
            console.log('Filters saved successfully!');
        } catch (error) {
            console.error('Error saving filters:', error);
        }
    };
    const removeFilter = (key) => {
        setSavedFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters, [key]: '' };
            const allFiltersEmpty = Object.values(updatedFilters).every(value => value === '');
            setIsFilterActive(!allFiltersEmpty);
            console.log(`Filter ${key} removed. isFilterActive: ${!allFiltersEmpty}`);
            return updatedFilters;
        });
    };
    const clearAllFilters = () => {
        setSavedFilters({
            selectedPropertyTypeOptions: [],
            beds: '',
            baths: '',
            priceFrom: '',
            priceTo: '',
            areaFrom: '',
            areaTo: '',
            city: '',
        });
        setIsFilterActive(false);
    };

    return (
        <>
            <Filters
                onSearch={handleSearch}
                onSaveFiltersChange={handleSaveFiltersChange}
            />
            <Map />
            <SavedFilters
                isFilterActive={isFilterActive}
                savedFilters={savedFilters}
                selectedPropertyTypeOptions={selectedFilters.selectedPropertyTypeOptions}
                priceTo={selectedFilters.priceTo}
                areaTo={selectedFilters.areaTo}
                removeFilter={removeFilter}
                clearAllFilters={clearAllFilters}
                handleSaveSearch={handleSaveSearch}
            />
            <ListObjects selectedFilters={selectedFilters} objects={objects} />
        </>
    );
};

export default Main;
