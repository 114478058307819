/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {deleteUserById, getUser, getUserById, updateUserById} from "../../../../../action/user_api";
import {useTranslation} from "react-i18next";
import Delete from "../../../../../assets/images/btns/delete_white.svg";
import Edit from "../../../../../assets/images/btns/edit_yellow.svg";
import Modal from "../../../../modal";
import Title from "../../../../title";
import './styles/styles.css';

const UserModeration = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [name, setName] = useState('');
    const [licenseNumber, setLicenseNumber] = useState('');
    const [agency, setAgency] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        licenseNumber: '',
        agency: '',
        email: '',
        phone: ''
    });
    const [showAlert, setShowAlert] = useState(false)
    const [showAlertUpdate, setShowAlertUpdate] = useState(false)
    const [buttonSaved, setButtonSaved] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            const userData = await getUser();
            console.log('userData', userData)
            try {
                const agentData = await getUserById(id);
                setUser(agentData);
                setAvatar(agentData.avatar);
                setUserId(agentData.id);
                setFirstName(agentData.firstName);
                setLastName(agentData.lastName);
                setName(agentData.displayName);
                setLicenseNumber(agentData.licenseNumber);
                setAgency(agentData.agency);
                setEmail(agentData.email);
                setPhone( '+' +agentData.phone);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUser();
    }, [id]);

    useEffect(() => {
        setName(`${firstName} ${lastName}`);
    }, [firstName, lastName]);

    const isInputFilled = (value) => {
        return value !== null && value !== undefined && value.toString().trim() !== '';
    };
    const handleInputChange = () => {
        setErrors(prevErrors => ({ ...prevErrors, email: '' }));
    };
    const handleNameChange = (e) => {
        const fullName = e.target.value;
        setName(fullName);
        const spaceIndex = fullName.indexOf(' ');
        const updatedFirstName = spaceIndex !== -1 ? fullName.slice(0, spaceIndex) : fullName;
        const updatedLastName = spaceIndex !== -1 ? fullName.slice(spaceIndex + 1) : '';
        setFirstName(updatedFirstName);
        setLastName(updatedLastName);
        setErrors(prevErrors => ({ ...prevErrors, firstName: '', lastName: '' }));
        handleInputChange();
    };
    const handlePhoneChange = (e) => {
        const formattedPhone = e.target.value.replace(/[^+\d]/g, '');
        setPhone(formattedPhone);
        setErrors(prevErrors => ({ ...prevErrors, phone: '' }));
        handleInputChange();
    };
    const handleAgencyChange = (e) => {
        const value = e.target.value;
        setAgency(value);
        setErrors(prevErrors => ({ ...prevErrors, agency: '' }));
        handleInputChange();
    };
    const handleLicenseNumberChange = (e) => {
        const value = e.target.value;
        setLicenseNumber(value);
        setErrors(prevErrors => ({ ...prevErrors, licenseNumber: '' }));
        handleInputChange();
    };
    const validateForm = () => {
        const fieldsToValidate = [
            { field: firstName, errorKey: 'firstName', errorMessage: t('Please enter agent first name') },
            { field: lastName, errorKey: 'lastName', errorMessage: t('Please enter agent last name') },
            { field: licenseNumber, errorKey: 'licenseNumber', errorMessage: t('Please enter agent license number') },
            { field: agency, errorKey: 'agency', errorMessage: t('Please enter agent agency') },
            { field: email, errorKey: 'email', errorMessage: t('Please enter agent e-mail') },
            { field: phone, errorKey: 'phone', errorMessage: t('Please enter agent phone number') },
        ];

        const newErrors = {};

        fieldsToValidate.forEach(({ field, errorKey, errorMessage }) => {
            newErrors[errorKey] = !isInputFilled(field) ? errorMessage : '';
        });

        setErrors(newErrors);

        return Object.values(newErrors).every(error => error === '');
    };

    const handleSaveChanges = async () => {
        try {
            if (!validateForm()) {
                return;
            }

            const userData = await getUser();
            const agentData = await getUserById(id);

            if ((userData.role === 'ROLE_MANAGER' || userData.role === 'ROLE_USER') &&
                (agentData.role === 'ROLE_MANAGER' || agentData.role === 'ROLE_ADMIN')) {
                setShowDeleteModal(false);
                setShowAlertUpdate(true);
                return;
            }
            const updatedUserData = {
                avatar,
                id,
                displayName: `${firstName} ${lastName}`,
                firstName,
                lastName,
                agency,
                licenseNumber,
                email,
                phone: phone.replace(/\+/g, ''),
            };
            await updateUserById(id, updatedUserData);
            setButtonSaved(true);
            setTimeout(() => {
                setButtonSaved(false);
            }, 2000);
        } catch (error) {
            console.error('Error updating agent:', error);
        }
    };
    const handleDeleteUserClick = () => {
        setShowDeleteModal(true);
    }
    const handleDeleteUser = async () => {
        try {
            const userData = await getUser();
            if (userData.role === 'ROLE_MANAGER' || userData.role === 'ROLE_USER') {
                const agentData = await getUserById(user.id);
                if (agentData.role === 'ROLE_MANAGER' || agentData.role === 'ROLE_ADMIN') {
                    setShowDeleteModal(false);
                    setShowAlert(true);
                } else if (userData.role === 'ROLE_MANAGER' && agentData.role === 'ROLE_USER') {
                    await deleteUserById(user.id);
                    setShowDeleteModal(false);
                    window.close();
                } else {
                    setShowDeleteModal(false);
                    setShowAlert(true);
                }
            } else if (userData.role === 'ROLE_ADMIN') {
                await deleteUserById(user.id);
                setShowDeleteModal(false);
                window.close();
            }
        } catch (error) {
            console.error('Error deleting object:', error);
        }
    };
    const handleCancel = () => {
        setShowDeleteModal(false);
        setShowAlert(false)
        setShowAlertUpdate(false);
    };
    const handleDeleteAvatar = () => {
        setAvatar(null);
    };

    return(
        <div className='user-moderation'>
            <div className='user-moderation-container'>
                <div className='profile-container__title'>
                    <Title text='Profile settings'/>
                </div>
                <div className='profile-container__form'>
                    <div className="profile-container__form-item__avatar">
                        {avatar ? (
                            <div className='profile-container__form-item__avatar-image'>
                                <img src={avatar.storageFileName} alt='' />
                                <input type='file' accept='image/*' id='avatar-input' style={{ display: 'none' }} />
                                <div className="profile-container__form-item__avatar-image__add" onClick={handleDeleteAvatar}>
                                    <img src={Delete} alt='' />
                                </div>
                            </div>
                        ) : (
                            <div className='profile-container__form-item__avatar-input'>
                                <div className='avatar-placeholder'>
                                    {firstName && lastName && (
                                        <div className='agent-container__content-avatar__not-photo admin'>
                                            <span>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</span>
                                        </div>
                                    )}
                                </div>
                                <input type='file' accept='image/*' id='avatar-input' style={{ display: 'none' }} />
                            </div>
                        )}
                    </div>
                    <div className={`profile-container__form-item id ${isInputFilled(id) ? 'field' : ''}`}>
                        {userId && <span>ID</span>}
                        <input
                            placeholder='ID'
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                            disabled
                        />
                    </div>
                    <div className={`profile-container__form-item name ${isInputFilled(name) ? 'field' : ''}`}>
                        {name && <span>{t('Name')}</span>}
                        <input
                            placeholder={t('Name')}
                            value={name}
                            onChange={handleNameChange}
                        />
                        {errors.firstName && <div className='error-message'>{errors.firstName}</div>}
                        {errors.lastName && <div className='error-message'>{errors.lastName}</div>}
                        <img src={Edit} alt=''/>
                    </div>
                    <div className={`profile-container__form-item license-admin ${isInputFilled(licenseNumber) ? 'field' : ''}`}>
                        {licenseNumber && <span>{t('License number')}</span>}
                        <input
                            placeholder={t('License number')}
                            value={licenseNumber}
                            onChange={handleLicenseNumberChange}
                        />
                        {errors.licenseNumber && <div className='error-message'>{errors.licenseNumber}</div>}
                        <img src={Edit} alt=''/>
                    </div>
                    <div className={`profile-container__form-item agency-admin ${isInputFilled(agency) ? 'field' : ''}`}>
                        {agency && <span>{t('Agency')}</span>}
                        <input
                            placeholder={t('Agency')}
                            value={agency}
                            onChange={handleAgencyChange}
                        />
                        {errors.agency && <div className='error-message'>{errors.agency}</div>}
                        <img src={Edit} alt=''/>
                    </div>
                    <div className={`profile-container__form-item email ${isInputFilled(email) ? 'field' : ''}`}>
                        {email && <span>{t('E-mail')}</span>}
                        <input
                            placeholder={t('E-mail')}
                            value={email}
                            onChange={(e) => {setEmail(e.target.value); handleInputChange();}}
                        />
                        {errors.email && <div className='error-message'>{errors.email}</div>}
                        <img src={Edit} alt=''/>
                    </div>
                    <div className={`profile-container__form-item phone ${isInputFilled(phone) ? 'field' : ''}`}>
                        {phone && <span>{t('Phone')}</span>}
                        <input
                            placeholder={t('Phone')}
                            value={phone}
                            onChange={handlePhoneChange}
                        />
                        {errors.phone && <div className='error-message'>{errors.phone}</div>}
                        <img src={Edit} alt=''/>
                    </div>
                    <div className='profile-container__form-item btn'>
                        <button onClick={handleSaveChanges}>
                            {buttonSaved ? t('Saved') : t('Save')}
                        </button>
                        <button onClick={handleDeleteUserClick}>
                            {t('toDelete')}
                        </button>
                    </div>
                </div>
            </div>
            {showDeleteModal && (
                <Modal
                    onCancel={handleCancel}
                    onPublish={handleDeleteUser}
                    showModal
                    showCancel
                    showPublish
                    content={t('Do you want to delete a user?')}
                    confirmText={t('toDelete')}
                    cancelText={t('Cancel')}
                />
            )}
            {showAlert && (
                <Modal
                    onCancel={handleCancel}
                    showModal
                    showCancel
                    content={t('You cannot delete this user.')}
                    cancelText={t('Cancel')}
                />
            )}
            {showAlertUpdate && (
                <Modal
                    onCancel={handleCancel}
                    showModal
                    showCancel
                    content={t('You cannot edit this user.')}
                    cancelText={t('Cancel')}
                />
            )}
        </div>
    )
}

export default UserModeration;
