import React from 'react';
import Logo from "../../assets/images/icon/logo.svg";
import Header from "../header";

const NotFound = () => {
    return(
        <>
            <Header/>
            <div className='not_found'>
                <div className='not_found-logo'>
                    <span>4<img src={Logo} alt=''/>4</span>
                </div>
                <div className='not_found-text'>
                    <span>Page not found!</span>
                </div>
            </div>
        </>
    )
}

export default NotFound;
