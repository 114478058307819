import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Delete from '../../assets/images/btns/delete_black.svg';
import Archive from '../../assets/images/btns/archive_black.svg';
import Restore from '../../assets/images/btns/restore.svg';
import './styles/styles.css';

const Modal = (
    {
        onCancel,
        onConfirm,
        onArchive,
        showModal,
        content,
        confirmText,
        cancelText,
        showCancel,
        showConfirm,
        showArchive,
        showRestore,
        onRestore,
        onPublish,
        showPublish
    }) => {

    useEffect(() => {
        document.documentElement.classList.toggle('modal-open', showModal);

        return () => {
            document.documentElement.classList.remove('modal-open');
        };
    }, [showModal]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                onArchive();
            } else if (event.key === 'Escape') {
                event.preventDefault();
                onCancel();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onCancel, onArchive]);

    const handleClick = (event) => {
        if (event && event.target.classList.contains('overlay')) {
            event.stopPropagation();
            event.preventDefault();
            if (onCancel) {
                onCancel(event);
            }
        }
    };
    const handleClickModal = (event) => {
        if (event && event.currentTarget.classList.contains('modal-visible')) {
            event.stopPropagation();
            event.preventDefault();
        }
    };

    return (
        <>
            <div>
                <div className={`overlay ${showModal ? 'overlay-visible' : ''}`} onClick={handleClick}></div>
                <div className={`modal ${showModal ? 'modal-visible' : ''}`} onClick={handleClickModal}>
                    <div className='modal-content'>
                        <p>{content}</p>
                    </div>
                    <div className='modal-btns'>
                        {showCancel && <button onClick={onCancel}>{cancelText}</button>}
                        {showConfirm && <button onClick={onConfirm}><img src={Delete} alt=''/>{confirmText}</button>}
                        {showArchive && <button onClick={onArchive}><img src={Archive} alt=''/>{confirmText}</button>}
                        {showRestore && <button onClick={onRestore}><img src={Restore} alt=''/>{confirmText}</button>}
                        {showPublish && <button onClick={onPublish}>{confirmText}</button>}
                    </div>
                </div>
            </div>
        </>
    );
};

Modal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onPublish: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
    onRestore: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    content: PropTypes.node.isRequired,
    confirmText: PropTypes.string.isRequired,
    cancelText: PropTypes.string.isRequired,
    showCancel: PropTypes.bool,
    showConfirm: PropTypes.bool,
    showArchive: PropTypes.bool,
    showRestore: PropTypes.bool,
    showPublish: PropTypes.bool,
};

export default Modal
