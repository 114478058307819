/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {getObjectById, getPropertyObjectsConsiderationAdmin} from '../../../action/object_api';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {MoonLoader} from 'react-spinners';
import Pagination from '../../pagination';
import CardAdmin from '../card';

const UnderConsiderations = ({ searchId }) => {
    const { t } = useTranslation();
    const [objects, setObjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [linksActive, setLinksActive] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (searchId) {
                    const response = await getObjectById(searchId);
                    const filteredResponse = response && response.status === "CONSIDERATION" ? [response] : [];
                    setObjects(filteredResponse);
                    setLoading(false);
                } else {
                    const response = await getPropertyObjectsConsiderationAdmin(currentPage);
                    const filteredResponse = response.content.filter(object => object.status === "CONSIDERATION");
                    setObjects(filteredResponse);
                    setTotalPages(response.totalPages);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                setObjects([]);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentPage, searchId]);

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };
    const handleLinkClick = (event) => {
        if (!linksActive) {
            event.preventDefault();
        }
    };
    const handleDeleteObject = (objectId) => {
        setObjects(objects.filter(obj => obj.id !== objectId));
    }
    const filteredObjects = objects;
    const shouldDisplayPagination = filteredObjects.length > 0 && !loading;

    return (
        <div className='list'>
            {loading ? (
                <div className='load'>
                    <MoonLoader color={'#E7C78B'} loading={loading} size={30} />
                </div>
            ) : (
                <div className='list-container'>
                    {filteredObjects.length > 0 ? (
                        <>
                            {filteredObjects.map((object) => (
                                <Link
                                    key={object.id}
                                    to={`/${object.id}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    onClick={handleLinkClick}
                                    className={!linksActive ? 'disabled-link' : ''}
                                >
                                    <CardAdmin key={object.id} object={object} onDelete={handleDeleteObject}/>
                                </Link>
                            ))}
                            {[8, 5, 2].map((count) => (
                                Array.from({ length: count }, (_, index) => (
                                    <div key={index} className='empty-item'></div>
                                ))
                            ))}
                        </>
                    ) : (
                        <p>{t('Nothing found')}</p>
                    )}
                </div>
            )}
            {shouldDisplayPagination && (
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageClick={handlePageClick} />
            )}
        </div>
    );
};

export default UnderConsiderations;
