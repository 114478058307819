import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const CustomButton = ({ icon, text, onClick, disabled, title }) => {
    const { t } = useTranslation();

    return (
        <div className={`mycard-container__card-content__btns-control__btn ${text.toLowerCase()}`}>
            <button onClick={onClick} disabled={disabled} title={t(title)}>
                <img src={icon} alt='' />
                <span>{t(text)}</span>
            </button>
        </div>
    );
};

CustomButton.propTypes = {
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default CustomButton;
