import React from 'react';
import PropTypes from 'prop-types';

const CheckIcon = ({ color = '#E7C78B', size = '20' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        width={size}
        height={size}
        color={color}
    >
        <polyline points="20 6 9 17 4 12" />
    </svg>
);

CheckIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string,
};

export default CheckIcon;
