import React from 'react';
import PropTypes from 'prop-types';
import FilterOptions from './options';
import Bed from '../../../assets/images/icon/bed.svg';
import Bath from '../../../assets/images/icon/bath.svg';
import Down from '../../../assets/images/icon/down.svg';
import {useTranslation} from "react-i18next";

const getDisplayString = (selectedRoomOptions, t) => {
    return `${selectedRoomOptions.Beds || 0} ${t('Beds')} / ${selectedRoomOptions.Baths || 0} ${t('Baths')}`;
};
const RoomFilter = ({ isRoomActive, isRoomDropdownOpen, selectedRoomOptions, toggleRoomDropdown, handleRoomOptionClick, handleRoomDropdownClick }) => {
    const { t } = useTranslation();
    return (
        <div className={`filters-container__rooms ${isRoomActive ? 'active' : ''}`} onClick={toggleRoomDropdown}>
            <div className={`filters-container__rooms-dropdown ${isRoomDropdownOpen ? 'open-dropdown' : ''}`}>
                {Object.keys(selectedRoomOptions).length > 0 ? (
                    <div className='filters-container__rooms-dropdown__selected'>
                        <span onClick={toggleRoomDropdown}>{getDisplayString(selectedRoomOptions, t)}</span>
                    </div>
                ) : (
                    <span onClick={toggleRoomDropdown}>{t('Beds & Baths')}</span>
                )}
                <img src={Down} alt='' className={`down-option ${isRoomDropdownOpen ? 'rotate' : ''}`}/>
                {isRoomDropdownOpen && (
                    <div className='filters-container__rooms-dropdown__list' onClick={handleRoomDropdownClick}>
                        <div className='filters-container__rooms-dropdown__list-item'>
                            <div className='filters-container__rooms-dropdown__list-item__bed'>
                                <div className='filters-container__rooms-dropdown__list-item__bed-title'>
                                    <img src={Bed} alt=''/>
                                    <span>{t('Beds')}</span>
                                </div>
                                <FilterOptions
                                    length={8}
                                    selectedValue={selectedRoomOptions.Beds}
                                    handleOptionClick={(value) => handleRoomOptionClick('Beds', value)}
                                />
                            </div>
                            <div className='filters-container__rooms-dropdown__list-item__bath'>
                                <div className='filters-container__rooms-dropdown__list-item__bath-title'>
                                    <img src={Bath} alt=''/>
                                    <span>{t('Baths')}</span>
                                </div>
                                <FilterOptions
                                    length={6}
                                    selectedValue={selectedRoomOptions.Baths}
                                    handleOptionClick={(value) => handleRoomOptionClick('Baths', value)}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

RoomFilter.propTypes = {
    isRoomActive: PropTypes.bool.isRequired,
    isRoomDropdownOpen: PropTypes.bool.isRequired,
    selectedRoomOptions: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    toggleRoomDropdown: PropTypes.func.isRequired,
    handleRoomOptionClick: PropTypes.func.isRequired,
    handleRoomDropdownClick: PropTypes.func.isRequired,
};

export default RoomFilter;
