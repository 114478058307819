import React from 'react';
import AgentInfo from "../../../components/agent";
import AgentObject from "../objects";


const Main = () => {

    return(
        <div>
            <AgentInfo/>
            <AgentObject/>
        </div>
    )
}

export default Main;
