import React from 'react';
import PropTypes from 'prop-types';

const Tabs = ({ tabs, activeTab, setActiveTab }) => (
    <div className='view-container__slider-tabs'>
        {tabs.map(tab => (
            <div
                key={tab.key}
                className={`view-container__slider-tabs__item ${activeTab === tab.key ? 'active' : ''}`}
                onClick={() => setActiveTab(tab.key)}
            >
                {tab.label}
            </div>
        ))}
    </div>
);

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    activeTab: PropTypes.string.isRequired,
    setActiveTab: PropTypes.func.isRequired,
};

export default Tabs;
