import React from 'react';
import PropTypes from 'prop-types';

const FilterOptions = ({ length, selectedValue, handleOptionClick }) => (
    <div className='filters-container__rooms-dropdown__list-item__bed-options'>
        {Array.from({ length }, (_, index) => index + 1).map((value) => (
            <div
                key={value}
                className={`filters-container__rooms-dropdown__list-item__bed-options__item ${selectedValue === value ? 'selected' : ''}`}
                onClick={() => handleOptionClick(value)}
            >
                <span>{value}</span>
            </div>
        ))}
    </div>
);

FilterOptions.propTypes = {
    length: PropTypes.number.isRequired,
    selectedValue: PropTypes.any,
    handleOptionClick: PropTypes.func.isRequired,
};

export default FilterOptions;
