// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.list a {
  text-decoration: none;
  margin-bottom: 60px;
}
.list .load {
  margin: 0 90px;
}
.list h2 {
  margin: 0 0 60px 90px;
  font-family: Raleway, sans-serif;
  font-size: 18px;
  color: #FFFFFF;
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum=1';
  -ms-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  -o-font-feature-settings: 'lnum';
}
.list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 90px;
}
.empty-item {
  width: 510px;
}
`, "",{"version":3,"sources":["webpack://./src/components/objects/list/styles/styles.less","webpack://./src/components/objects/list/styles/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;ACCF;ADJA;EAKI,qBAAA;EACA,mBAAA;ACEJ;ADRA;EASI,cAAA;ACEJ;ADXA;EAYI,qBAAA;EACA,gCAAA;EACA,eAAA;EACA,cAAA;EACA,iCAAA;EACA,6BAAA;EACA,oCAAA;EACA,iCAAA;EACA,qCAAA;EACA,gCAAA;ACEJ;ADAE;EACE,aAAA;EACA,eAAA;EACA,8BAAA;EACA,cAAA;ACEJ;ADCA;EACE,YAAA;ACCF","sourcesContent":[".list{\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  a{\n    text-decoration: none;\n    margin-bottom: 60px;\n  }\n  .load{\n    margin: 0 90px;\n  }\n  h2{\n    margin: 0 0 60px 90px;\n    font-family: Raleway, sans-serif;\n    font-size: 18px;\n    color: #FFFFFF;\n    font-variant-numeric: lining-nums;\n    font-feature-settings: 'lnum';\n    -moz-font-feature-settings: 'lnum=1';\n    -ms-font-feature-settings: 'lnum';\n    -webkit-font-feature-settings: 'lnum';\n    -o-font-feature-settings: 'lnum';\n  }\n  &-container{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin: 0 90px;\n  }\n}\n.empty-item{\n  width: 510px;\n}\n",".list {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n.list a {\n  text-decoration: none;\n  margin-bottom: 60px;\n}\n.list .load {\n  margin: 0 90px;\n}\n.list h2 {\n  margin: 0 0 60px 90px;\n  font-family: Raleway, sans-serif;\n  font-size: 18px;\n  color: #FFFFFF;\n  font-variant-numeric: lining-nums;\n  font-feature-settings: 'lnum';\n  -moz-font-feature-settings: 'lnum=1';\n  -ms-font-feature-settings: 'lnum';\n  -webkit-font-feature-settings: 'lnum';\n  -o-font-feature-settings: 'lnum';\n}\n.list-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin: 0 90px;\n}\n.empty-item {\n  width: 510px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
