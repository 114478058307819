import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageIconMobile from "../../assets/images/icon/world_black.svg";

const LanguageDropdownMobile = () => {
    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('language') || 'en');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const languageDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (languageDropdownRef.current && !languageDropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setCurrentLanguage(language);
        setIsDropdownOpen(false);
        localStorage.setItem('language', language);
    };

    const languageList = ['en', 'ru'];

    return (
        <div
            ref={languageDropdownRef}
            className={`burger-container__bar-content language ${isDropdownOpen ? 'open' : ''}`}
            onClick={toggleDropdown}
        >
            <img src={LanguageIconMobile} alt="" />
            <span>{t(currentLanguage.toUpperCase())}</span>
            {/*<img src={DownMobile} alt='' className={`down ${isDropdownOpen ? 'rotate' : ''}`} />*/}
            {isDropdownOpen && (
                <div className='burger-container__bar-content__dropdown'>
                    {languageList.map((lang) => (
                        <div
                            className='burger-container__bar-content__dropdown-list'
                            key={lang}
                            onClick={() => changeLanguage(lang)}
                        >
                            <span>{t(lang)}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LanguageDropdownMobile;
