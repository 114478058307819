/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {addToFavorites, removeFromFavorites} from "../../../action/favourite_api";
import { getUser } from "../../../action/user_api";
import {useTranslation} from "react-i18next";
import {useFavouriteCount} from "../../../context/FavouriteCountContext";
import {getAgent} from "../../../action/agent_api";
import ModalContacts from "../../modal_contacts";
import RoomInfo from "../../my_objects/card/room_info";
import AgentButton from "./agent_button";
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import HeartNotSelected from '../../../assets/images/icon/heart.svg';
import HeartSelected from '../../../assets/images/icon/heart_selected.svg';
import Bed from '../../../assets/images/icon/bed_yellow.svg';
import Bath from '../../../assets/images/icon/bath_yellow.svg';
import SQFT from '../../../assets/images/icon/sqft.svg';
import Geo from '../../../assets/images/icon/pin_yellow.svg';
import Call from '../../../assets/images/icon/phone_black.svg';
import WhatsApp from '../../../assets/images/btns/whatsapp_black.svg';
import PDF from '../../../assets/images/btns/add_pdf.svg';
import './styles/styles.css';
import './styles/@styles.css';

const Card = ({ object,onDelete }) => {
    const { t } = useTranslation();
    const { updateFavouriteCount } = useFavouriteCount();
    const isFavourite = object.hasOwnProperty('isFavourite') ? object.isFavourite : false;
    const agentInitials = `${object.user.firstName.charAt(0)}${object.user.lastName.charAt(0)}`;
    const [images, setImages] = useState([]);
    const [favorite, setFavorite] = useState(isFavourite);
    const [isHandlingClick, setIsHandlingClick] = useState(false);
    const [isContact, setIsContact] = useState(false);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const imageArray = object.media
                    .filter((media) => media.mediaType === 'ACCOMMODATION_INTERIOR')
                    .map((media) => ({
                        id: media.id,
                        storageFileName: media.storageFileName
                    }));

                setImages(imageArray.slice(0, 5));
            } catch (error) {
                console.error('Error fetching property images:', error);
            }
        };

        if (object.media.length > 0) {
            fetchImages();
        }
    }, [object.media, object.id]);

    const handleFavoriteClick = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (isHandlingClick) {
            return;
        }
        try {
            setIsHandlingClick(true);

            const currentUser = await getUser();
            if (!favorite) {
                setFavorite(true);
                await addToFavorites(object.id, currentUser.id);
                updateFavouriteCount((prevCount) => prevCount + 1);
            } else {
                setFavorite(false);
                await removeFromFavorites(object.id, currentUser.id);
                updateFavouriteCount((prevCount) => prevCount - 1);
                onDelete();
            }
        } catch (error) {
            console.error('Error toggling favorites:', error);
        } finally {
            setIsHandlingClick(false);
        }
    };
    const handleCallClick = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        setIsContact(true)
    };
    const handleCancel = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setIsContact(false);
    };
    const handleWhatsAppClick = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        try {
            const agentData = await getAgent(object.user.id);
            const whatsappNumber = agentData.whatsapp;
            window.open(`https://wa.me/${whatsappNumber}`, '_blank');
        } catch (error) {
            console.error('Error when going to WhatsApp:', error);
        }
    };
    const handlePresentationClick = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (isHandlingClick) {
            return;
        }
        try {
            setIsHandlingClick(true);

            const currentUser = await getUser();
            if (!favorite) {
                setFavorite(true);
                await addToFavorites(object.id, currentUser.id);
                updateFavouriteCount((prevCount) => prevCount + 1);
            }
        } catch (error) {
            console.error('Error toggling favorites:', error);
        } finally {
            setIsHandlingClick(false);
        }
    };
    const handleClickAgent = (event) => {
        event.stopPropagation();
        event.preventDefault();
        window.open(`/agent/${object.user.id}`, '_blank');
    };

    return (
        <div className='card'>
            <div className='card-container'>
                <div className='card-container__card'>
                    <div className='card-container__card-favorite' onClick={handleFavoriteClick}>
                        {favorite ? (
                            <div className='card-container__card-favorite__heart selected'>
                                <img src={HeartSelected} alt='' />
                            </div>
                        ) : (
                            <div className='card-container__card-favorite__heart not_selected'>
                                <img src={HeartNotSelected} alt='' />
                            </div>
                        )}
                        </div>
                        <div className="card-container__card-carousel">
                            <Slider {...sliderSettings}>
                                {Array.isArray(images) && images.length > 0 ? (
                                    images.map((image, index) => {
                                        return (
                                            <img
                                                key={index}
                                                src={image.storageFileName}
                                                alt={`Image ${index + 1}`}
                                            />
                                        );
                                    })
                                ) : (
                                    <p>{t('No images available')}</p>
                                )}
                            </Slider>
                        </div>
                        <div className='card-container__card-content'>
                            <div className='card-container__card-content__body'>
                                <div className='card-container__card-content__body-rooms'>
                                    <RoomInfo icon={Bed} label="Bed" value={object.beds} />
                                    <RoomInfo icon={Bath} label="Bath" value={object.baths} />
                                    <RoomInfo icon={SQFT} label="Area" value={object.area} />
                                </div>
                                <div className='card-container__card-content__body-id'>
                                    <span className='card-container__card-content__body-id__text id'>id:</span>
                                    <span className='card-container__card-content__body-id__text number'>{object.id}</span>
                                </div>
                            </div>
                            <div className='card-container__card-content__price'>
                                <span>{object.currency}</span>
                                <span>{object.price}</span>
                            </div>
                            <div className='card-container__card-content__location'>
                                <div className='card-container__card-content__location-name' title={object.name}>
                                    <span>{object.name}</span>
                                </div>
                                <div className='card-container__card-content__location-geo'>
                                    <img src={Geo} alt=''/>
                                    <span>{object.city} - {object.developer.name}</span>
                                </div>
                            </div>
                            <div className='card-container__card-content__agent'>
                                <div className='card-container__card-content__agent-info' onClick={handleClickAgent}>
                                    <div className='card-container__card-content__agent-info__avatar'>
                                        {object.user.avatar ? (
                                            <img src={object.user.avatar.storageFileName} alt='' />
                                        ) : (
                                            <div className='card-container__card-content__agent-info__avatar-initials'>
                                                <span>{agentInitials}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className='card-container__card-content__agent-info__name'>
                                        <span>{object.user.displayName}</span>
                                    </div>
                                </div>
                                <div className='card-container__card-content__agent-btns'>
                                    <AgentButton icon={Call} label={t('Call')} onClick={handleCallClick} />
                                    {object.user.whatsapp && (
                                        <AgentButton icon={WhatsApp} onClick={handleWhatsAppClick} />
                                    )}
                                    <AgentButton icon={HeartNotSelected} label="Favorite" onClick={handleFavoriteClick} />
                                    <AgentButton icon={PDF} label="Presentation" onClick={handlePresentationClick} />
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            {object && isContact && (
                <ModalContacts
                    onCancel={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleCancel(event);
                    }}
                    showModal
                    showCancel
                    content={object.user.phone}
                    cancelText={t('OK')}
                />
            )}
        </div>
    );
};

Card.propTypes = {
    object: PropTypes.shape({
        beds: PropTypes.number.isRequired,
        baths: PropTypes.number.isRequired,
        area: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        user: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        }).isRequired,
        currency: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        developer: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        isFavorite: PropTypes.bool.isRequired,
    }).isRequired,
};

export default Card;
