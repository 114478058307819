import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Add from '../../assets/images/btns/add_objects.svg';
import './styles/styles.css';

const AddButton = ({ to, text }) => {
    const { t } = useTranslation();

    return (
        <div className='add_btn'>
            <Link to={to}>
                <button>
                    <img src={Add} alt=''/>
                    <span>{t(text)}</span>
                </button>
            </Link>
        </div>
    );
};
AddButton.propTypes = {
    to: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default AddButton;
