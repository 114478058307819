import React from 'react';
import Header from '../header';
import Logo from '../../assets/images/icon/logo.svg';

const Forbidden = () => {
    return(
        <>
            <Header/>
            <div className='not_found'>
                <div className='not_found-logo'>
                    <span>4<img src={Logo} alt=''/>3</span>
                </div>
                <div className='not_found-text'>
                    <span>Forbidden!</span>
                </div>
            </div>;
        </>
    )
}
export default Forbidden;
