/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {Route, Routes, useParams} from 'react-router-dom';
import Main from './main';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import {getUser} from "../../../action/user_api";
import {getObjectById} from "../../../action/object_api";
import Forbidden from "../../../components/forbidden";

const EditObject = () => {

    const { id } = useParams();
    const [objectData, setObjectData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [showForbidden, setShowForbidden] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userData = await getUser();
                const objectData = await getObjectById(id);

                if (userData.id !== objectData.user.id) {
                    setShowForbidden(true);
                    return;
                }

                setObjectData(objectData);
                setUserData(userData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    return(
        <>
            {showForbidden ? <Forbidden /> : (
                <div>
                    <Header/>
                    <Routes>
                        <Route path='/' element={<Main/>} />
                    </Routes>
                    <Footer/>
                </div>
            )}
        </>
    )
}

export default EditObject;
