/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserIcon from '../../assets/images/icon/user.svg';
import Down from '../../assets/images/icon/down.svg';
import {useAuth} from "../../context/AuthContext";
import {getUser} from "../../action/user_api";

const UserDropdown = () => {
    const { t } = useTranslation();
    const { logout } = useAuth();
    const [isDropdownUserOpen, setIsDropdownUserOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [userRole, setUserRole] = useState(null);

    const userDropdownRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = await getUser();
                setUserData(user);
                setUserRole(user.role);

            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
                setIsDropdownUserOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleUserDropdown = () => {
        setIsDropdownUserOpen(!isDropdownUserOpen);
    };
    const handleLogout = () => {
        logout();
        window.location.reload();
    };

    return (
        <div ref={userDropdownRef} className={`header-container__bar-content user ${isDropdownUserOpen ? 'open' : ''}`} onClick={toggleUserDropdown}>
            <img src={UserIcon} alt="" />
            <span>{userData ? `${userData.displayName}` : t('Profile')}</span>
            <img src={Down} alt='' className={`down ${isDropdownUserOpen ? 'rotate-header' : ''}`} />
            {isDropdownUserOpen && (
                <div className='header-container__bar-content__dropdownUser'>
                    <Link to='/my_profile'>
                        <span>{t('Personal data')}</span>
                    </Link>
                    <Link to='/my_profile'>
                        <span>{t('Subscription Management')}</span>
                    </Link>
                    {userRole !== 'ROLE_USER' && (
                        <Link to='/moderation/objects'>
                            <span>{t('Moderation')}</span>
                        </Link>
                    )}
                    <span className='signout' onClick={handleLogout}>
                        {t('Sign out')}
                    </span>
                </div>
            )}
        </div>
    );
};

export default UserDropdown;
