import axios from 'axios';
import { getTokens } from './auth_token';

// Get user
export const getUser = async () => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.get(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/auth/me`,
            config
        );

        return response.data;

    } catch (error) {
        console.error('Error when performing a POST request to get user:', error);
        throw error;
    }
};

// Get users
export const getUsers = async (page) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const size = 5;
        const pageToUse = page || 0;
        const offsetToUse = (pageToUse - 1) * size || 0;

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/users?page=${pageToUse}&size=${size}&offset=${offsetToUse || 0}&startPage=${pageToUse || 0}`,
            null,
            config
        );

        const responseData = response.data;
        if (!responseData) {
            throw new Error('Response data is undefined');
        }
        const totalPages = responseData.totalPages;
        return { content: responseData.content, totalPages };

    } catch (error) {
        console.error('Error when performing a POST request to get users:', error);
        throw error;
    }
};

// Get user by ID
export const getUserById = async (userId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.get(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`,
            config
        );

        return response.data;

    } catch (error) {
        console.error('Error when executing a GET request to get a user by ID:', error);
        throw error;
    }
};

// Delete user by ID
export const deleteUserById = async (userId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
            },
        };
        const response = await axios.delete(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`,
            config
        );

        return response.data;

    } catch (error) {
        console.error('Error when executing a DELETE request to delete a user by ID:', error);
        throw error;
    }
};

// Update user by ID
export const updateUserById = async (userId, userData) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.patch(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`,
            userData,
            config
        );

        return response.data;

    } catch (error) {
        console.error('Error when executing a PATCH request to update user information by ID:', error);
        throw error;
    }
};

// Get languages users
export const getUserLanguages = async () => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.get(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_API_URL}/api/v1/users/languages`,
            config
        );

        return response.data;

    } catch (error) {
        console.error('Error when executing a GET request to get user languages:', error);
        throw error;
    }
};
