import axios from 'axios';
import { getTokens } from './auth_token';

// Create saved search
export const createSavedSearch = async (loggedInUser, requestBody) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const data = {
            loggedInUser,
            ...requestBody,
        };

        const response = await axios.put(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + '/api/v1/subscription',
            data,
            config
        );

        return response.data;
    } catch (error) {
        console.error('Error when performing a PUT request to /api/v1/subscription:', error);
        throw error;
    }
};

// Get all saved searches
export const getAllSavedSearches = async (filterData) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
            },
        };

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_API_URL}/api/v1/subscription`,
            filterData,
            config
        );

        return response.data;
    } catch (error) {
        console.error('Error when performing a POST request to /api/v1/subscription:', error);
        throw error;
    }
};

// Get saved search by ID
export const getSavedSearchById = async (subscriptionId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
            },
        };

        const response = await axios.get(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_API_URL}/api/v1/subscription/${subscriptionId}`,
            config
        );

        return response.data;
    } catch (error) {
        console.error(`Error when performing a GET request to /api/v1/subscription/${subscriptionId}:`, error);
        throw error;
    }
};

// Delete saved search by ID
export const deleteSavedSearch = async (subscriptionId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
            },
        };

        const response = await axios.delete(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_API_URL}/api/v1/subscription/${subscriptionId}`,
            config
        );

        return response.data;
    } catch (error) {
        console.error(`Error when performing a DELETE request to /api/v1/subscription/${subscriptionId}:`, error);
        throw error;
    }
};
