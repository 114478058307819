/* eslint-disable */
import React, { useEffect, useState } from 'react';
import RoomInfo from "../../my_objects/card/room_info";
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Bed from '../../../assets/images/icon/bed_yellow.svg';
import Bath from '../../../assets/images/icon/bath_yellow.svg';
import SQFT from '../../../assets/images/icon/sqft.svg';
import Geo from '../../../assets/images/icon/pin_yellow.svg';
import Modal from "../../modal";
import {deleteObjectById, submitObjectById} from "../../../action/object_api";
import Delete from "../../../assets/images/btns/delete_red.svg";

const CardAdmin = ({ object, onDelete }) => {
    const { t } = useTranslation();
    const [images, setImages] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false)
    const agentInitials = `${object.user.firstName.charAt(0)}${object.user.lastName.charAt(0)}`;
    const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const imageArray = object.media
                    .filter((media) => media.mediaType === 'ACCOMMODATION_INTERIOR')
                    .map((media) => ({
                        id: media.id,
                        storageFileName: media.storageFileName
                    }));

                setImages(imageArray.slice(0, 1));
            } catch (error) {
                console.error('Error fetching property images:', error);
            }
        };

        if (object.media.length > 0) {
            fetchImages();
        }
    }, [object.media, object.id]);

    const handlePublishClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setShowConfirmation(true);
    }
    const handleCancel = () => {
        setShowConfirmation(false);
        setShowConfirmationDelete(false);
    };
    const handleSubmit = async () => {
        try {
            await submitObjectById(object.id);
            setShowConfirmation(false);
            onDelete(object.id);
        } catch (error) {
            console.error('Error publishing object:', error);
        }
    };
    const handleReviseClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        window.open(`/${object.id}`, '_blank');
    }
    const handleClickAgent = (event) => {
        event.stopPropagation();
        event.preventDefault();
        window.open(`/agent/${object.user.id}`, '_blank');
    };
    const handleDeleteClick = (event) =>{
        event.stopPropagation();
        event.preventDefault();
        setShowConfirmationDelete(true);
    }
    const handleDelete = async () => {
        try {
            await deleteObjectById(object.id);
            setShowConfirmationDelete(false);
            onDelete(object.id);
        } catch (error) {
            console.error('Error deleting object:', error);
        }
    }

    return (
        <div className='card'>
            <div className='card-container'>
                <div className='card-container__card'>
                    <div className="card-container__card-carousel">
                        <Slider {...sliderSettings}>
                            {Array.isArray(images) && images.length > 0 ? (
                                images.map((image, index) => {
                                    return (
                                        <img
                                            key={index}
                                            src={image.storageFileName}
                                            alt={`Image ${index + 1}`}
                                        />
                                    );
                                })
                            ) : (
                                <p>{t('No images available')}</p>
                            )}
                        </Slider>
                    </div>
                    <div className='card-container__card-content'>
                        <div className='card-container__card-content__body'>
                            <div className='card-container__card-content__body-rooms'>
                                <RoomInfo icon={Bed} label="Bed" value={object.beds} />
                                <RoomInfo icon={Bath} label="Bath" value={object.baths} />
                                <RoomInfo icon={SQFT} label="Area" value={object.area} />
                            </div>
                            <div className='card-container__card-content__body-id'>
                                <span className='card-container__card-content__body-id__text id'>id:</span>
                                <span className='card-container__card-content__body-id__text number'>{object.id}</span>
                            </div>
                        </div>
                        <div className='card-container__card-content__price'>
                            <span>{object.currency}</span>
                            <span>{object.price}</span>
                        </div>
                        <div className='card-container__card-content__location'>
                            <div className='card-container__card-content__location-name' title={object.name}>
                                <span>{object.name}</span>
                            </div>
                            <div className='card-container__card-content__location-geo'>
                                <img src={Geo} alt=''/>
                                <span>{object.city} - {object.developer.name}</span>
                            </div>
                        </div>
                        {object.status === "CONSIDERATION" && (
                            <div className='card-container__card-content__controls'>
                                <div className='card-container__card-content__controls-btns'>
                                    <div className='card-container__card-content__controls-btns__btn publish'>
                                        <button onClick={handlePublishClick}>{t('Publish')}</button>
                                    </div>
                                    <div className='card-container__card-content__controls-btns__btn revise'>
                                        <button onClick={handleReviseClick}>{t('To revise')}</button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {object.status === "ACTIVE" && (
                            <div className='card-container__card-content__agent' onClick={handleClickAgent}>
                                <div className='card-container__card-content__agent-info'>
                                    <div className='card-container__card-content__agent-info__avatar'>
                                        {object.user.avatar ? (
                                            <img src={object.user.avatar.storageFileName} alt='' />
                                        ) : (
                                            <div className='card-container__card-content__agent-info__avatar-initials'>
                                                <span>{agentInitials}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className='card-container__card-content__agent-info__name'>
                                        <span>{object.user.displayName}</span>
                                    </div>
                                </div>
                                <div className='card-container__card-content__agent-delete'>
                                    <button title={t('toDelete')} onClick={handleDeleteClick}><img src={Delete} alt=''/></button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showConfirmation && (
                <Modal
                    onCancel={handleCancel}
                    onPublish={handleSubmit}
                    showModal
                    showCancel
                    showPublish
                    content={t('Do you want to publish an object?')}
                    confirmText={t('Publish')}
                    cancelText={t('Cancel')}
                />
            )}
            {showConfirmationDelete && (
                <Modal
                    onCancel={handleCancel}
                    onPublish={handleDelete}
                    showModal
                    showCancel
                    showPublish
                    content={t('Do you want to delete an object?')}
                    confirmText={t('toDelete')}
                    cancelText={t('Cancel')}
                />
            )}
        </div>
    );
};

CardAdmin.propTypes = {
    object: PropTypes.shape({
        beds: PropTypes.number.isRequired,
        baths: PropTypes.number.isRequired,
        area: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        user: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        }).isRequired,
        currency: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        developer: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        isFavorite: PropTypes.bool.isRequired,
    }).isRequired,
};

export default CardAdmin;
