import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

const ListItem = ({ label, onClick }) => {
    const { t } = useTranslation();
    return (
        <div className='sorting-container__dropdown-list__item' onClick={onClick}>
            <span>{t(label)}</span>
        </div>
    );
};

ListItem.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default ListItem;
