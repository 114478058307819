/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {dismissObjectById, getObjectById, submitObjectById} from '../../../action/object_api';
import { MoonLoader } from "react-spinners";
import Slider from 'react-slick';
import Back from '../../../assets/images/btns/left.svg';
import Bed from '../../../assets/images/icon/bed_yellow.svg';
import Bath from '../../../assets/images/icon/bath_yellow.svg';
import SQFT from '../../../assets/images/icon/sqft.svg';
import PDF from '../../../assets/images/btns/add_pdf.svg';
import Call from '../../../assets/images/icon/phone_black.svg';
import Heart from '../../../assets/images/icon/heart_yellow.svg';
import HeartSelected from '../../../assets/images/icon/heart_selected_view.svg';
import Share from '../../../assets/images/btns/share.svg';
import WhatsApp from '../../../assets/images/btns/whatsapp_black.svg';
import Gym from '../../../assets/images/facilities/gym.svg';
import Parking from '../../../assets/images/facilities/parking.svg';
import Restaurants from '../../../assets/images/facilities/cafe.svg';
import BarbequeArea from '../../../assets/images/facilities/barbeque.svg';
import SecurityStaff from '../../../assets/images/facilities/securityn.svg';
import BalconyOrTerrace from '../../../assets/images/facilities/terrace.svg';
import FirstAidMedicalCenter from '../../../assets/images/facilities/kit.svg';
import Jacuzzi from '../../../assets/images/facilities/jacuzzi.svg';
import SteamRoom from '../../../assets/images/facilities/steamRoom.svg';
import Concierge from '../../../assets/images/facilities/concierge.svg';
import View from '../../../assets/images/facilities/view.svg';
import CleaningService from '../../../assets/images/facilities/cleaning.svg';
import ReceptionWaitingRoom from '../../../assets/images/facilities/reception.svg';
import LawnOrGarden from '../../../assets/images/facilities/lawn.svg';
import Swimming from '../../../assets/images/facilities/swimming.svg';
import LobbyInBuilding from '../../../assets/images/facilities/lobby.svg';
import ConferenceRoom from '../../../assets/images/facilities/confenerce.svg';
import KidsPlayArea from '../../../assets/images/facilities/kids.svg';
import Sauna from '../../../assets/images/facilities/sauna.svg';
import BusinessCenter from '../../../assets/images/facilities/business.svg';
import MaintenanceStaff from '../../../assets/images/facilities/maintenance.svg';
import CafeteriaOrCanteen from '../../../assets/images/facilities/cafeteria.svg';
import PrayerRoom from '../../../assets/images/facilities/prayer.svg';
import CCTVSecurity from '../../../assets/images/facilities/video-security.svg';
import MapMobile from '../../../assets/images/icon/map_btn.svg';
import Down from '../../../assets/images/icon/down.svg';
import Prev from '../../../assets/images/btns/prev.svg';
import Next from '../../../assets/images/btns/next.svg';
import Header from '../../header';
import Footer from '../../footer';
import Tabs from "./tabs_desctop";
import TabItem from "./tabs_mobile";
import ContactButton from "./contact_button";
import {useTranslation} from "react-i18next";
import {addToFavorites, getFavoriteObjects, removeFromFavorites} from "../../../action/favourite_api";
import {getUser} from "../../../action/user_api";
import {useFavouriteCount} from "../../../context/FavouriteCountContext";
import Forbidden from "../../forbidden";
import {getAgent} from "../../../action/agent_api";
import Title from "../../title";
import Modal from "../../modal";
import ModalContacts from "../../modal_contacts";
import './styles/styles.css';
import './styles/@styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../custom.css'

    const CardView = () => {
    const { t } = useTranslation();
    const { updateFavouriteCount } = useFavouriteCount();
    const TABS = {
        ARCHITECTURE: t('architecture'),
        INTERIOR: t('interior'),
        LOBBY: t('lobby'),
        ACTIVE: 'active',
    };
    const TAB_LABELS = {
        ARCHITECTURE: 'Architecture',
        INTERIOR: 'Interior',
        LOBBY: 'Lobby',
        ACTIVE_MOBILE: 'active-mobile',
    };
    const tabs = [
        { key: TABS.ARCHITECTURE, label: t(TAB_LABELS.ARCHITECTURE) },
        { key: TABS.INTERIOR, label: t(TAB_LABELS.INTERIOR) },
        { key: TABS.LOBBY, label: t(TAB_LABELS.LOBBY) },
    ];

    const { id } = useParams();
    const [object, setObject] = useState(null);
    const [user, setUser] = useState(null);
    const [objectStatus, setObjectStatus] = useState(null);
    const [media, setMedia] = useState([]);
    const [mobileMedia, setMobileMedia] = useState([]);
    const [activeTab, setActiveTab] = useState(TABS.ARCHITECTURE);
    const [selectedTabOption, setSelectedTabOption] = useState(TAB_LABELS.ARCHITECTURE);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMobile, setIsLoadingMobile] = useState(true);
    const [isFavorite, setIsFavorite] = useState(false);
    const [isHandlingClick, setIsHandlingClick] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmationRevision, setShowConfirmationRevision] = useState(false);
    const [whatsappAvailable, setWhatsappAvailable] = useState(true);
    const [isContact, setIsContact] = useState(false);
    const [formValue, setFormValue] = useState({ moderatorComment: '' });
    const navigate = useNavigate();
    const [errors, setErrors] = useState({
        moderatorComment: ''
    });

    const dropdownRef = useRef(null);
    const sliderRefDesktop = useRef(null);
    const sliderRefMobile = useRef(null);

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        initialSlide: 0,
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                setIsLoadingMobile(true);

                const userData = await getUser();
                setUser(userData);
                const responseObject = await getObjectById(id);
                setObject(responseObject);
                setObjectStatus(responseObject.status);

                if (!responseObject.user.whatsapp) {
                    setWhatsappAvailable(false);
                }

                setMedia(responseObject.media
                    .filter(mediaItem => mediaItem.mediaType === `ACCOMMODATION_${activeTab.toUpperCase()}`)
                    .map(mediaItem => ({
                        id: mediaItem.id,
                        storageFileName: mediaItem.storageFileName
                    }))
                );

                if (window.innerWidth < 768) {
                    setMobileMedia(responseObject.media
                        .filter(mediaItem => mediaItem.mediaType === `ACCOMMODATION_${selectedTabOption.toUpperCase()}`)
                        .map(mediaItem => ({
                            id: mediaItem.id,
                            storageFileName: mediaItem.storageFileName
                        }))
                    );
                }

                setCurrentSlide(0);
            } catch (error) {
                console.error('Error fetching object by ID:', error);
                navigate('/');
            } finally {
                setIsLoading(false);
                setIsLoadingMobile(false);
            }
        };

        fetchData();
    }, [id, activeTab, selectedTabOption, navigate]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        const checkFavoriteStatus = async () => {
            try {
                if (!object) {
                    return;
                }
                const user = await getUser();
                const userId = user.id;
                const favoriteObjects = await getFavoriteObjects(userId);
                const isObjectFavorite = favoriteObjects.some(obj => obj.id === object.id);
                setIsFavorite(isObjectFavorite);
            } catch (error) {
                console.error('Error checking favorite status:', error);
            }
        };

        checkFavoriteStatus();
    }, [object]);

    const validateForm = () => {
        const newErrors = {
            moderatorComment: formValue.moderatorComment.length  ? '' : t('Fill in the comment field.'),
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some((error) => error !== '');
    };
    const handleTextareaChange = (field, value) => {
        setFormValue((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            moderatorComment: '',
        }));
    };
    const goToPrevSlide = () => {
        if (window.innerWidth >= 768) {
            if (sliderRefDesktop.current) {
                sliderRefDesktop.current.slickPrev();
            }
        } else {
            if (sliderRefMobile.current) {
                sliderRefMobile.current.slickPrev();
            }
        }
    };
    const goToNextSlide = () => {
        if (window.innerWidth >= 768) {
            if (sliderRefDesktop.current) {
                sliderRefDesktop.current.slickNext();
            }
        } else {
            if (sliderRefMobile.current) {
                sliderRefMobile.current.slickNext();
            }
        }
    };
    const handleTabOptionClick = (option) => {
        setSelectedTabOption(option);
        setDropdownOpen(false);
    };
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen)
    };
    const filterUniqueFacilities = (facilities) => {
        const uniqueFacilities = [];
        const uniqueIds = new Set();

        for (const facility of facilities) {
            if (!uniqueIds.has(facility.id)) {
                uniqueFacilities.push(facility);
                uniqueIds.add(facility.id);
            }
        }

        return uniqueFacilities;
    };
    const handleSaveButtonClick = async () => {
        if (isHandlingClick) {
            return;
        }
        try {
            setIsHandlingClick(true);
            const user = await getUser();
            const userId = user.id;

            if (isFavorite) {
                await removeFromFavorites(object.id, userId);
                setIsFavorite(false);
                updateFavouriteCount((prevCount) => prevCount + 1);
            } else {
                await addToFavorites(object.id, userId);
                setIsFavorite(true);
                updateFavouriteCount((prevCount) => prevCount - 1);
            }
        } catch (error) {
            console.error('Error managing favorite status:', error);
        } finally {
            setIsHandlingClick(false);
        }
    };
    const handleShareButtonClick = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: object.name,
                    text: 'Check out this property!',
                    url: window.location.href,
                });
            } else {
                const textField = document.createElement('textarea');
                textField.innerText = window.location.href;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                document.body.removeChild(textField);
                alert('Link copied to clipboard!');
            }
        } catch (error) {
            console.error('Error sharing:', error);
        }
    };
    const handleClickAgent = () => {
        window.open(`/agent/${object.user.id}`, '_blank');
    };
    const handleCallClick = async () => {
        setIsContact(true)
    };
    const handleWhatsAppClick = async () => {
        try {
            const agentData = await getAgent(object.user.id);
            const whatsappNumber = agentData.whatsapp;
            window.open(`https://wa.me/${whatsappNumber}`, '_blank');
        } catch (error) {
            console.error('Error when going to WhatsApp:', error);
        }
    };
    const handlePublishClick = () => {
        setShowConfirmation(true);
    }
    const handleSubmit = async () => {
        try {
            await submitObjectById(object.id);
            setShowConfirmation(false);
            console.log('Object published successfully!');
            // navigate('/moderation');
            window.close();
        } catch (error) {
            console.error('Error publishing object:', error);
        }
    };
    const handleRevisionClick = () => {
        const isValid = validateForm();
        if(isValid){
            setErrors({
                moderatorComment:''
            })
            setShowConfirmationRevision(true);
        }
    }
    const handleRevision = async () => {
        try {
            const dismissObject = {
                moderatorComment: formValue.moderatorComment
            };
            console.log('formValue',formValue)
         await dismissObjectById(object.id, dismissObject);
            setShowConfirmationRevision(false);
            window.close();
        } catch (error) {
            console.error('Error publishing object:', error);
        }
    };
    const handleCancel = () => {
        setShowConfirmation(false);
        setShowConfirmationRevision(false);
        setIsContact(false);
    };

    const uniqueFacilities = object?.facilities ? filterUniqueFacilities(object.facilities) : [];
    const categoryImages = {
        1: Gym,
        2: Parking,
        3: BarbequeArea,
        4: Jacuzzi,
        5: CleaningService,
        6: ReceptionWaitingRoom,
        7: LawnOrGarden,
        8: Swimming,
        9: SecurityStaff,
        10: SteamRoom,
        11: LobbyInBuilding,
        12: ConferenceRoom,
        13: KidsPlayArea,
        14: Sauna,
        15: BalconyOrTerrace,
        16: Concierge,
        17: BusinessCenter,
        18: MaintenanceStaff,
        19: CafeteriaOrCanteen,
        20: Restaurants,
        21: FirstAidMedicalCenter,
        22: View,
        23: PrayerRoom,
        24: CCTVSecurity,
    };

    if (!object) {
        return <div className='loading'><MoonLoader color={'#E7C78B'} size={30} /></div>;
    }
    if (objectStatus !== 'ACTIVE' && user && user.role === 'ROLE_USER') {
        return <div><Forbidden/></div>
    }
    if (objectStatus === 'ACTIVE' || objectStatus === 'CONSIDERATION') {
        return <div className='view'>
            <Header/>
            <div className='view-container'>
                <div className='back'>
                    <button onClick={() => navigate('/')}><img src={Back} alt=''/>{t('Back')}</button>
                </div>
                <div className='view-container__name'>
                    <h1>{object.name}</h1>
                </div>
                <div className='view-container__info'>
                    <div className='view-container__info-location'>
                        <span>{object.city} - {object.developer.name}</span>
                    </div>
                    <div className='view-container__info-id'>
                        <span className='view-container__info-id__info id'>ID:</span>
                        <span className='view-container__info-id__info number'>{object.id}</span>
                    </div>
                </div>
                <div className='view-container__slider'>
                    <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                    <div className='view-container__slider-slides'>
                        {isLoading ? (
                            <div className='load-slide'>
                                <MoonLoader color={'#E7C78B'} loading={isLoading} size={30} />
                            </div>
                        ) : (
                            <>
                                {media.length > 0 ? (
                                    <Slider
                                        {...sliderSettings}
                                        ref={sliderRefDesktop}
                                        afterChange={(index) => setCurrentSlide(index)}
                                        beforeChange={(oldIndex, newIndex) => setCurrentSlide(newIndex)}
                                    >
                                        {media.map((mediaItem, index) => (
                                            <img
                                                key={mediaItem.id}
                                                src={mediaItem.storageFileName}
                                                alt=''
                                            />
                                        ))}
                                    </Slider>
                                ) : (
                                    <div className='no-photo'><p>{t('No photos available')}</p></div>
                                )}
                                <div className='view-container__slider-slides__counter'>
                                    <button onClick={goToPrevSlide}><img src={Prev} alt=''/></button>
                                    <span>{currentSlide + 1} - {media.length}</span>
                                    <button onClick={goToNextSlide}>< img src={Next} alt=''/></button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className='view-container__sliderMobile'>
                    <div className='view-container__sliderMobile-dropdown' onClick={toggleDropdown} ref={dropdownRef}>
                        <span>{t(selectedTabOption)}</span>
                        <img src={Down} alt='' className={isDropdownOpen ? 'active-rotate' : ''}/>
                        {isDropdownOpen && (
                            <div className='view-container__sliderMobile-dropdown__list'>
                                <TabItem tabLabel="Architecture" selectedTabOption={selectedTabOption} handleTabOptionClick={handleTabOptionClick} />
                                <TabItem tabLabel="Interior" selectedTabOption={selectedTabOption} handleTabOptionClick={handleTabOptionClick} />
                                <TabItem tabLabel="Lobby" selectedTabOption={selectedTabOption} handleTabOptionClick={handleTabOptionClick} />
                            </div>
                        )}
                    </div>
                    <div className='view-container__sliderMobile-map'>
                        <button><img src={MapMobile} alt=''/></button>
                    </div>
                </div>
                <div className='view-container__slidesMobile'>
                    {isLoadingMobile ? (
                        <div className='load-slide'>
                            <MoonLoader color={'#E7C78B'} loading={isLoadingMobile} size={30} />
                        </div>
                    ) : (
                        <>
                            {mobileMedia.length > 0 ? (
                                <Slider
                                    {...sliderSettings}
                                    ref={sliderRefMobile}
                                    afterChange={(index) => setCurrentSlide(index)}
                                    beforeChange={(oldIndex, newIndex) => setCurrentSlide(newIndex)}
                                >
                                    {mobileMedia.map((mediaItem, index) => (
                                        <img
                                            key={mediaItem.id}
                                            src={mediaItem.storageFileName}
                                            alt=''
                                        />
                                    ))}
                                </Slider>
                            ) : (
                                <div className='no-photo'><p>{t('No photos available')}</p></div>
                            )}
                            <div className='view-container__slidesMobile-counter'>
                                <button onClick={goToPrevSlide}><img src={Prev} alt=''/></button>
                                <span>{currentSlide + 1} - {mobileMedia.length}</span>
                                <button onClick={goToNextSlide}>< img src={Next} alt=''/></button>
                            </div>
                        </>
                    )}
                </div>
                <div className='view-container__more'>
                    <div className='view-container__more-container'>
                        <div className='view-container__more-container__info'>
                            <Title text='Information'/>
                            <div className='view-container__more-container__info-body'>
                                <div className='view-container__more-container__info-body__description'>
                                    <h3>{t('General')}</h3>
                                    <span>{object.description}</span>
                                </div>
                                {object.furnishing && (
                                    <div className='view-container__more-container__info-body__description'>
                                        <h3>{t('Furnishing')}</h3>
                                        <span>{object.furnishing}</span>
                                    </div>
                                )}
                                {object.parking && (
                                    <div className='view-container__more-container__info-body__description'>
                                        <h3>{t('Parking')}</h3>
                                        <span>{object.parking}</span>
                                    </div>
                                )}
                                {object.advantages && (
                                    <div className='view-container__more-container__info-body__description'>
                                        <h3>{t('Advantages')}</h3>
                                        <span>{object.advantages}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='view-container__more-container__agent'>
                            <div className='view-container__more-container__agent-priceMobile'>
                                <span>{object.currency} {object.price}</span>
                            </div>
                            <div className='view-container__more-container__agent-rooms'>
                                <div className='view-container__more-container__agent-rooms__item beds'>
                                    <img src={Bed} alt=''/>
                                    <span>{object.beds}</span>
                                </div>
                                <div className='view-container__more-container__agent-rooms__item baths'>
                                    <img src={Bath} alt=''/>
                                    <span>{object.baths}</span>
                                </div>
                                <div className='view-container__more-container__agent-rooms__item area'>
                                    <img src={SQFT} alt=''/>
                                    <span>{object.area} m²</span>
                                </div>
                            </div>
                            <div className='view-container__more-container__agent-price'>
                                <span>{object.currency} {object.price}</span>
                            </div>
                            <div className='view-container__more-container__agent-btns'>
                                <div className='view-container__more-container__agent-btns__presintation'>
                                    <button><img src={PDF} alt=''/>{t('Add to Presentation')}</button>
                                </div>
                                <div className='view-container__more-container__agent-btns__more'>
                                    <div className='view-container__more-container__agent-btns__more-save'>
                                        <button onClick={handleSaveButtonClick}>
                                            {isFavorite ? (
                                                <>
                                                    <img src={HeartSelected} alt='' />
                                                    <span>{t('Save')}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <img src={Heart} alt='' />
                                                    <span>{t('Save')}</span>
                                                </>
                                            )}
                                        </button>
                                    </div>
                                    <div className='view-container__more-container__agent-btns__more-share'>
                                        <button onClick={handleShareButtonClick}>
                                            <img src={Share} alt='' />
                                            <span>{t('Share')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='view-container__more-container__agent-contact'>
                                <div className='view-container__more-container__agent-contact__agent'>
                                    {object.user.avatar ? (
                                        <img src={object.user.avatar.storageFileName} alt='Agent' onClick={handleClickAgent}/>
                                    ) : (
                                        <div className='view-container__more-container__agent-contact__agent-initials' onClick={handleClickAgent}>
                                            <span>{object.user.firstName.charAt(0)}{object.user.lastName.charAt(0)}</span>
                                        </div>
                                    )}
                                    <div className='view-container__more-container__agent-contact__agent-name'>
                                        <span className='view-container__more-container__agent-contact__agent-name__item name' onClick={handleClickAgent}>{object.user.firstName} {object.user.lastName}</span>
                                        <span className='view-container__more-container__agent-contact__agent-name__item agency'>{object.user.agency}</span>
                                    </div>
                                </div>
                                <div className='view-container__more-container__agent-contact__btns'>
                                    {whatsappAvailable && (
                                        <ContactButton icon={WhatsApp} text="Whats App" onClick={() => handleWhatsAppClick('WhatsApp')} />
                                    )}
                                    <ContactButton icon={Call} text={t('toCall')} onClick={() => handleCallClick('Call')} />
                                </div>
                            </div>
                            <div className='view-container__more-container__agent-developer'>
                                <div className='view-container__more-container__agent-developer__dev'>
                                    <span>{object.developer.name}</span>
                                    <p>{t('Developer')}</p>
                                </div>
                                {/*<div className='view-container__more-container__agent-developer__date'>*/}
                                {/*    <span>JUN 2026</span>*/}
                                {/*    <p>{t('Completion date')}</p>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className='view-container__more-facilities'>
                        <Title text='Facilities'/>
                        <div className='view-container__more-facilities__container'>
                            {uniqueFacilities.length > 0 ? (
                                uniqueFacilities.map(facility => (
                                    <div className='view-container__more-facilities__container-list' key={facility.id}>
                                        <img src={categoryImages[facility.id]} alt=''/>
                                        <span key={facility.id}>{t(facility.name)}</span>
                                    </div>
                                ))
                            ) : (
                                <div className='no-photo'><p>{t('No facilities available')}</p></div>
                            )}
                        </div>
                    </div>
                </div>
                {objectStatus === 'CONSIDERATION' && (
                    <div className='view-container__admin'>
                        <div className='view-container__admin-comments'>
                            <div className='view-container__admin-comments__title'>
                                <span>{t('Moderator\'s comments')}</span>
                            </div>
                            <div className='view-container__admin-comments__area'>
                                <textarea
                                    value={formValue.moderatorComment}
                                    placeholder={t('Write comment to the owner of the object.')}
                                    onChange={(e) => handleTextareaChange('moderatorComment', e.target.value)}
                                />
                            </div>
                            <div className='error-message'>{errors.moderatorComment}</div>
                        </div>
                        <div className='view-container__admin-btns'>
                            <div className='view-container__admin-btns__btn publish'>
                                <button onClick={handlePublishClick}>{t('Publish')}</button>
                            </div>
                            <div className='view-container__admin-btns__btn revision'>
                                <button onClick={handleRevisionClick}>{t('For revision')}</button>
                            </div>
                        </div>
                    </div>
                )}
                {showConfirmation && (
                    <Modal
                        onCancel={handleCancel}
                        onPublish={handleSubmit}
                        showModal
                        showCancel
                        showPublish
                        content={t('Do you want to publish an object?')}
                        confirmText={t('Publish')}
                        cancelText={t('Cancel')}
                    />
                )}
                {showConfirmationRevision && (
                    <Modal
                        onCancel={handleCancel}
                        onPublish={handleRevision}
                        showModal
                        showCancel
                        showPublish
                        content={t('Do you want to send an object for revision?')}
                        confirmText={t('For revision')}
                        cancelText={t('Cancel')}
                    />
                )}
                {showConfirmationRevision && (
                    <Modal
                        onCancel={handleCancel}
                        onPublish={handleRevision}
                        showModal
                        showCancel
                        showPublish
                        content={t('Do you want to send an object for revision?')}
                        confirmText={t('For revision')}
                        cancelText={t('Cancel')}
                    />
                )}
                {object && isContact && (
                    <ModalContacts
                        onCancel={handleCancel}
                        showModal
                        showCancel
                        content={object.user.phone}
                        cancelText={t('OK')}
                    />
                )}
            </div>
            <Footer/>
        </div>
    }
};

export default CardView;
