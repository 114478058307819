import React from 'react';
import PropTypes from 'prop-types';
import './styles/styles.css';

// eslint-disable-next-line react/prop-types
function CustomCheckbox({ isChecked, onChange }) {

    return (
        <label className="custom-checkbox">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={onChange}
            />
            <span className="checkmark"></span>
        </label>
    );
}

CustomCheckbox.propTypes = {
    onChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
};

export default CustomCheckbox;
