import React, { useEffect, useRef, useState } from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import ListItem from '../sorting/list_item';
import Sort from '../../assets/images/icon/sort.svg';
import Down from '../../assets/images/icon/down.svg';
import './styles/styles.css';
import './styles/@styles.css';

const Sorting = ({ onSortChange, currentPage }) => {
    const { t } = useTranslation();
    const [isDropdownUserOpen, setDropdownUserOpen] = useState(false);
    const [selectedSortOption, setSelectedSortOption] = useState('');

    const dropdownRef = useRef(null);

    const LOWEST_PRICE = t('Lowest Price');
    const HIGHEST_PRICE = t('Highest Price');

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        const storedSortOption = localStorage.getItem('selectedSortOption');
        if (storedSortOption) {
            setSelectedSortOption(storedSortOption);
            onSortChange(getSortParam(storedSortOption));
        } else {
            handleSortOptionClick(LOWEST_PRICE);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onSortChange, currentPage]);

    const toggleSortDropdown = () => {
        setDropdownUserOpen(!isDropdownUserOpen);
    };

    const handleSortOptionClick = (option) => {
        setSelectedSortOption(option);
        setDropdownUserOpen(false);
        const sortParam = getSortParam(option);
        onSortChange(sortParam, currentPage);
        localStorage.setItem('selectedSortOption', option);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownUserOpen(false);
        }
    };

    const getSortParam = (option) => {
        switch (option) {
            case LOWEST_PRICE:
                return 'price,asc';
            case HIGHEST_PRICE:
                return 'price,desc';
            default:
                return '';
        }
    };

    return (
        <div className='sorting'>
            <div className='sorting-container'>
                <div
                    className={`sorting-container__dropdown ${isDropdownUserOpen ? 'open' : ''}`}
                    onClick={toggleSortDropdown}
                    ref={dropdownRef}
                >
                    <img src={Sort} alt='' />
                    <span>{t(selectedSortOption)}</span>
                    <img src={Down} alt='' className={`down ${isDropdownUserOpen ? 'rotate' : ''}`} />
                    {isDropdownUserOpen && (
                        <div className='sorting-container__dropdown-list'>
                            <ListItem label={t(LOWEST_PRICE)} onClick={() => handleSortOptionClick(LOWEST_PRICE)} />
                            <ListItem label={t(HIGHEST_PRICE)} onClick={() => handleSortOptionClick(HIGHEST_PRICE)} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Sorting.propTypes = {
    onSortChange: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
};

export default Sorting;
