import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import './styles/styles.css';
import './styles/@styles.css';

const Pagination = ({ totalPages, currentPage, onPageClick }) => {
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 5;

        const addEllipsis = (key) => (
            <p key={key} className="ellipsis">
                ...
            </p>
        );

        const addPageNumber = (number) => (
            <p
                key={uuidv4()}
                onClick={() => onPageClick(number - 1)}
                className={number === currentPage + 1 ? 'pagination-item active-pagination' : 'pagination-item'}
            >
                {number}
            </p>
        );

        if (totalPages <= maxPagesToShow) {
            pageNumbers.push(...Array.from({ length: totalPages }, (_, index) => addPageNumber(index + 1)));
        } else {
            let startPage = Math.max(1, Math.min(currentPage - 1, totalPages - maxPagesToShow + 1));
            let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

            if (startPage > 1) {
                if (startPage > 2) {
                    pageNumbers.push(addPageNumber(1));
                }
                pageNumbers.push(addEllipsis('start-ellipsis'));
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(addPageNumber(i));
            }

            if (endPage < totalPages) {
                pageNumbers.push(addEllipsis('end-ellipsis'));
                pageNumbers.push(addPageNumber(totalPages));
            }
        }

        return pageNumbers;
    };

    return <div className="pagination">{renderPageNumbers()}</div>;
};

Pagination.propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageClick: PropTypes.func.isRequired,
};

export default Pagination;
