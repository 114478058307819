import React from 'react';
import HeaderModeration from '../../header';
import UserModeration from './main';
import Footer from "../../../footer";

const MainUserModeration = () =>{

    return(
        <div className='main' >
            <HeaderModeration />
            <UserModeration/>
            <Footer/>
        </div>
    )
}

export default MainUserModeration;
