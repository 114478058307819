/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {Link, useMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Logo from '../../../assets/images/icon/logo.svg';
import Find from '../../../assets/images/btns/search.svg';
import LanguageDropdown from '../../language_dropdown';
import UserDropdown from '../../user_dropdown';
import UserIconMobile from "../../../assets/images/icon/user_black.svg";
import SearchIconMobile from "../../../assets/images/btns/search_black.svg";
import ObjectsIconMobile from "../../../assets/images/icon/home_black.svg";
import Admin from "../../../assets/images/icon/admin.svg";
import UsersIconMobile from "../../../assets/images/icon/users.svg";
import LanguageDropdownMobile from "../../language_dropdown_mobile";
import {getUser} from "../../../action/user_api";
import {useAuth} from "../../../context/AuthContext";
import './styles/styles.css'
import './styles/@styles.css'

const HeaderModeration = () => {
    const { t } = useTranslation();
    const { logout } = useAuth();
    const [userRole, setUserRole] = useState(null);
    const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
    const matchObjects = useMatch('/moderation/objects');
    const matchUsers = useMatch('/moderation/users/*');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = await getUser();
                setUserRole(user.role);

            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();
    }, []);

    const handleLogoClick = () => {
        const landingUrl = process.env.REACT_APP_LANDING_URL;
        window.open(landingUrl, '_blank');
    };
    const toggleBurgerMenu = () => {
        setIsBurgerMenuOpen(!isBurgerMenuOpen);
    };
    const handleLogout = () => {
        logout();
        window.location.reload();
    };

    return(
        <div className={`header-moderation ${isBurgerMenuOpen ? 'open' : ''}`}>
            <div className='header-moderation-container'>
                <div className='header-moderation-container__logo' onClick={handleLogoClick}>
                    <img src={Logo} alt=''/>
                    <span>connect.</span>
                </div>
                <div className='header-moderation-container__links'>
                    <div className={`header-moderation-container__links-item objects ${matchObjects ? 'selected-link' : ''}`}>
                        <Link to='/moderation/objects'>{t('Objects')}</Link>
                    </div>
                    <div className={`header-moderation-container__links-item users ${matchUsers ? 'selected-link' : ''}`}>
                        <Link to='/moderation/users'>{t('Users')}</Link>
                    </div>
                </div>
                <div className='header-moderation-container__dropdowns'>
                    <div className='header-moderation-container__dropdowns-find'>
                        <Link to='/'><img src={Find} alt=''/><span>{t('Find housing')}</span></Link>
                    </div>
                    <div className='header-moderation-container__dropdowns-lng'>
                        <LanguageDropdown/>
                    </div>
                    <div className='header-moderation-container__dropdowns-user'>
                        <UserDropdown/>
                    </div>
                </div>
                <div className='burger-menu' onClick={toggleBurgerMenu}>
                    <div className={`burger-menu__line ${isBurgerMenuOpen ? 'open-line' : ''}`}></div>
                    <div className={`burger-menu__line ${isBurgerMenuOpen ? 'open-line' : ''}`}></div>
                    <div className={`burger-menu__line ${isBurgerMenuOpen ? 'open-line' : ''}`}></div>
                </div>
            </div>
            {isBurgerMenuOpen && (
                <div className='burger'>
                    <div className='burger-container'>
                        <div className='burger-container__bar'>
                            <div className='burger-container__bar-content user'>
                                <img src={UserIconMobile} alt="" />
                                <Link to='/my_profile'>
                                    <span>{t('Profile')}</span>
                                </Link>
                            </div>
                            <div className='burger-container__bar-content search'>
                                <a href='https://app.connect.tw'>
                                    <img src={SearchIconMobile} alt="" />
                                    <span>{t('Find housing')}</span>
                                </a>
                            </div>
                            <div className='burger-container__bar-content favourite'>
                                <img src={ObjectsIconMobile} alt="" />
                                <Link to='/moderation/objects'><span>{t('Objects')}</span></Link>
                            </div>
                            <div className='burger-container__bar-content favourite'>
                                <img src={UsersIconMobile} alt="" />
                                <Link to='/moderation/users'><span>{t('Users')}</span></Link>
                            </div>
                            <div className='burger-container__bar-content moderation'>
                                <Link to='/moderation'>
                                    <img src={Admin} alt=''/>
                                    <span>{t('Moderation')}</span>
                                </Link>
                            </div>
                            <LanguageDropdownMobile/>
                            <div className='burger-container__bar-content logout'>
                                <span onClick={handleLogout}>
                                    {t('Sign out')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default HeaderModeration;
