/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {getObjectById, updateObjectById} from "../../../../action/object_api";
import {getUser} from "../../../../action/user_api";
import Modal from "../../../../components/modal";
import PropTypes from 'prop-types';
import Back from '../../../../assets/images/btns/back.svg';
import Geo from '../../../../assets/images/icon/pin_black.svg';
import Info from '../../../../assets/images/icon/info_white.svg';
import InfoSelected from '../../../../assets/images/icon/info_black.svg';
import Photo from '../../../../assets/images/icon/photo_white.svg';
import PhotoSelected from '../../../../assets/images/icon/photo_black.svg';
import StepOne from "../step_one";
import StepTwo from "../step_two";
import StepThree from "../step_three";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Forbidden from "../../../../components/forbidden";

const Main = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [step, setStep] = useState(1);
    const [prevStep, setPrevStep] = useState(null);
    const [filledSteps, setFilledSteps] = useState([]);
    const [stepOneClass, setStepOneClass] = useState('one');
    const [stepTwoClass, setStepTwoClass] = useState('two');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showSubmittedConfirmation, setShowSubmittedConfirmation] = useState(false);
    const [form, setForm] = useState({
        name: '',
        developer: {
            id: 0,
            name: '',
        },
        district: '',
        city: '',
        fullAddress: '',
        propertyType: '',
        description: '',
        furnishing: '',
        parking: '',
        advantages: '',
        beds: 0,
        baths: 0,
        registrationNumber: '',
        links: '',
        facilities: [],
        area: 0,
        price: 0,
        currency: 'AED',
        phone: '',
        moderatorComment: null
    });
    const [stepData, setStepData] = useState({
        1: { name: '',
            developer: {
                id: 0,
                name: '',
            },
            district: '',
            city: '',
            fullAddress: '', },
        2: { propertyType: '',
            description: '',
            furnishing: '',
            parking: '',
            advantages: '',
            beds: 0,
            baths: 0,
            registrationNumber: '',
            links: '',
            facilities: [],
            area: '',
            price: '',
            phone: '',
            currency: 'AED',
            moderatorComment: null },
    });
    const [mediaFiles, setMediaFiles] = useState([]);
    const [createdDeveloper, setCreatedDeveloper] = useState(null);

    useEffect(() => {
        if (step === 2) {
            setStepOneClass('one filled-step');
        } else {
            setStepOneClass('one');
        }
        if (step === 3) {
            setStepTwoClass('two filled-step');
        } else {
            setStepTwoClass('two');
        }
    }, [step]);

    const updateForm = (updatedData) => {
        setForm((prevForm) => ({
            ...prevForm,
            ...updatedData,
        }));
    };
    const handleFormChange = (formData) => {
        setForm((prevForm) => ({
            ...prevForm,
            ...formData,
        }));

        setStepData((prevStepData) => ({
            ...prevStepData,
            [step]: {
                ...prevStepData[step],
                ...formData,
            },
        }));
    };
    const handleNext = () => {
        setPrevStep(step);
        setStep((prevStep) => prevStep + 1);
        setFilledSteps([...filledSteps, prevStep]);
    };
    const handlePrev = () => {
        if (step > 1) {
            setStep((prevStep) => prevStep - 1);
            setPrevStep((prevStep) => Math.max(1, prevStep - 1));
        } else {
            window.location.href = '/my_objects';
        }
    };
    const getStepClass = (currentStep, targetStep) => {
        return currentStep === targetStep ? 'active-steps' : '';
    };
    const isStepFilled = (currentStep) => {
        return filledSteps.includes(currentStep);
    };
    const handleCancel = () => {
        setShowConfirmation(false);
    };
    const handleConfirm = async () => {
        try {
            const userData = await getUser();
            let developerToUse = form.developer;

            if (createdDeveloper) {
                developerToUse = {
                    id: createdDeveloper.id,
                    name: createdDeveloper.name,
                };
            }

            const objectDataWithUser = {
                ...form,
                user: {
                    id: userData.id,
                    enabled: userData.enabled,
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    role: userData.role,
                    favouriteAccommodations: userData.favouriteAccommodations
                },
                media: JSON.parse(sessionStorage.getItem('mediaFiles')),
                developer: developerToUse,
            };

            await updateObjectById(id, objectDataWithUser);
            console.log('Object created successfully', objectDataWithUser);

            setShowConfirmation(false);
            setShowSubmittedConfirmation(true);
            sessionStorage.removeItem('mediaFiles');
        } catch (error) {
            console.error('Error creating object:', error);
            sessionStorage.removeItem('mediaFiles');
        }
    };
    const updateMediaFiles = (newMediaFiles) => {
        setMediaFiles((prevMediaFiles) => {
            const updatedMediaFiles = prevMediaFiles.concat(newMediaFiles);
            sessionStorage.setItem('mediaFiles', JSON.stringify(updatedMediaFiles));
            return updatedMediaFiles;
        });
    };
    const removeMediaFile = (removedFile) => {
        setMediaFiles((prevMediaFiles) => {
            return prevMediaFiles.filter(file => file !== removedFile);
        });

        const storedMediaFiles = JSON.parse(sessionStorage.getItem('mediaFiles'));
        if (storedMediaFiles) {
            const updatedStoredMediaFiles = storedMediaFiles.filter(file => file !== removedFile);
            sessionStorage.setItem('mediaFiles', JSON.stringify(updatedStoredMediaFiles));
        }
    };
    const handleSubmittedConfirm = () => {
        setShowSubmittedConfirmation(false);
        window.close();
    };
    const handleDeveloperCreated = (createdDeveloper) => {
        console.log("Developer created in Main:", createdDeveloper);
        updateForm({
            developer: {
                id: createdDeveloper.id,
                name: createdDeveloper.name,
            },
        });
        setCreatedDeveloper(createdDeveloper);
    };

    return (
        <div className='create'>
            <div className='create-container'>
                <div className='create-container__stepper'>
                    <div className='create-container__stepper-container'>
                        <div className={`create-container__stepper-container__steps one ${getStepClass(step, 1)} ${isStepFilled(1) ? 'filled-step' : ''} ${stepOneClass}`}>
                            <img src={Geo} alt=''/>
                            <p>{t('Location')}</p>
                        </div>
                        <div className={`create-container__stepper-container__steps two ${getStepClass(step, 2)} ${isStepFilled(2) ? 'filled-step' : ''} ${stepTwoClass}`}>
                            <img src={Info} alt=''/>
                            <img className='selected-img' src={InfoSelected} alt=''/>
                            <p>{t('About object')}</p>
                        </div>
                        <div className={`create-container__stepper-container__steps three ${getStepClass(step, 3)} `}>
                            <img src={Photo} alt=''/>
                            <img className='selected-img' src={PhotoSelected} alt=''/>
                            <p>{t('Images')}</p>
                        </div>
                    </div>
                </div>
                <div className='create-container__back'>
                    <button onClick={handlePrev}><img src={Back} alt='' />{t('Back')}</button>
                </div>
                <>
                    <>
                        {step === 1 && (
                            <StepOne
                                onDeveloperCreated={handleDeveloperCreated}
                                onNext={handleNext}
                                onFormChange={handleFormChange}
                                stepData={stepData}
                            />
                        )}
                    </>
                    <>
                        {step === 2 && (
                            <StepTwo
                                onNext={handleNext}
                                onFormChange={handleFormChange}
                                stepData={stepData}
                            />
                        )}
                    </>
                    <>
                        {step === 3 && (
                            <StepThree
                                onPublish={handleConfirm}
                                removeMediaFile={(removedFile) => removeMediaFile(removedFile)}
                                updateMediaFiles={updateMediaFiles}
                            />
                        )}
                    </>
                </>
            </div>
            {showConfirmation && (
                <Modal
                    onCancel={handleCancel}
                    onPublish={handleConfirm}
                    showModal
                    showCancel
                    showPublish
                    content={t('Do you want to publish an object?')}
                    confirmText={t('Publish')}
                    cancelText={t('Cancel')}
                />
            )}
            {showSubmittedConfirmation && (
                <Modal
                    onPublish={handleSubmittedConfirm}
                    showPublish
                    content={t('The ad has been saved as a draft. Publish it or edit it.')}
                    confirmText={t('OK')}
                />
            )}
        </div>
    );
};
Main.propTypes = {
    onNext: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
    onPublish: PropTypes.func.isRequired,
};

export default Main;
