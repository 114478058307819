import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Main from './main';
import Header from '../../components/header';
import Footer from '../../components/footer';

const MyProfile = () => {

    return(
        <div className='main'>
            <Header/>
            <Routes>
                <Route path='/' element={<Main/>} />
            </Routes>
            <Footer/>
        </div>
    )
}

export default MyProfile;
