import axios from 'axios';
import { getTokens } from './auth_token';

// Add object to favorites
export const addToFavorites = async (accommodationId, userId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const data = {
            accommodationId,
            userId,
        };

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/objects/favourite?accommodationId=${accommodationId}&userId=${userId}`,
            data,
            config
        );

        return response.data;
    } catch (error) {
        console.error(`Error when performing a POST request to /api/v1/objects/favourite:`, error);
        throw error;
    }
};

// Get favorite objects by user ID
export const getFavoriteObjects = async (userId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
            },
        };

        const response = await axios.get(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/objects/favourite/${userId}`,
            config
        );

        return response.data;
    } catch (error) {
        console.error(`Error when performing a GET request to /api/v1/objects/favourite/${userId}:`, error);
        throw error;
    }
};

// Remove object from favorites
export const removeFromFavorites = async (accommodationId, userId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
            },
            params: {
                accommodationId,
                userId,
            },
        };

        const response = await axios.delete(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + '/api/v1/objects/favourite',
            config
        );

        return response.data;
    } catch (error) {
        console.error('Error when performing a DELETE request to /api/v1/objects/favourite:', error);
        throw error;
    }
};

// Get count of favorite objects by user ID
export const getFavoriteObjectsCount = async (userId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
            },
        };

        const response = await axios.get(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/objects/count/${userId}`,
            config
        );

        return response.data;
    } catch (error) {
        console.error('Error when performing a GET request to /api/v1/objects/count:', error);
        throw error;
    }
};
