import React from 'react';
import PropTypes from 'prop-types';
import Down from '../../../assets/images/icon/down.svg';
import CheckIcon from '../../check_icon';
import { useTranslation } from 'react-i18next';

const PropertyFilter = (
    {
        isPropertyTypeActive,
        isPropertyTypeDropdownOpen,
        selectedPropertyTypeOptions,
        propertyTypes,
        togglePropertyTypeDropdown,
        handlePropertyTypeOptionClick,
    }) => {
    const { t } = useTranslation();

    const getPropertyTypeStatus = (type) => {
        return selectedPropertyTypeOptions.includes(type);
    };

    const handleClick = (type) => (event) => {
        event.stopPropagation();
        handlePropertyTypeOptionClick(type, event);
    };

    return (
        <div
            className={`filters-container__property ${isPropertyTypeActive ? 'active' : ''}`}
            onClick={togglePropertyTypeDropdown}
        >
            <div className={`filters-container__property-dropdown ${isPropertyTypeDropdownOpen ? 'open-dropdown' : ''}`}>
                {selectedPropertyTypeOptions.length > 0 ? (
                    <div className='filters-container__property-dropdown__selected'>
                        <span>{selectedPropertyTypeOptions.map(type => t(type)).join(' / ')}</span>
                    </div>
                ) : (
                    <span>{t('Property type')}</span>
                )}
                <img src={Down} alt='' className={`down-option ${isPropertyTypeDropdownOpen ? 'rotate' : ''}`} />
                {isPropertyTypeDropdownOpen && (
                    <div className='filters-container__property-dropdown__list'>
                        {propertyTypes.map((type) => (
                            <div
                                key={type}
                                className='filters-container__property-dropdown__list-item'
                                onClick={handleClick(type)}
                            >
                                <span>
                                    {t(type)} {getPropertyTypeStatus(type) && <CheckIcon />}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

PropertyFilter.propTypes = {
    isPropertyTypeActive: PropTypes.bool.isRequired,
    isPropertyTypeDropdownOpen: PropTypes.bool.isRequired,
    selectedPropertyTypeOptions: PropTypes.array.isRequired,
    propertyTypes: PropTypes.array.isRequired,
    togglePropertyTypeDropdown: PropTypes.func.isRequired,
    handlePropertyTypeOptionClick: PropTypes.func.isRequired,
};

export default PropertyFilter;
