import React from 'react';
import PropTypes from 'prop-types';
import Down from '../../../assets/images/icon/down.svg';
import {useTranslation} from "react-i18next";

const PriceFilter = (
    {
        isPriceActive,
        isPriceDropdownOpen,
        priceFrom,
        priceTo,
        handlePriceInputClick,
        handlePriceInputChange,
        togglePriceDropdown,
    }) => {
    const isPriceFilled = priceFrom.length > 0 || priceTo.length > 0;
    const { t } = useTranslation();

    return (
        <div className={`filters-container__price ${isPriceActive ? 'active' : ''}`} onClick={togglePriceDropdown}>
            <img src={Down} alt='' className={`down-option ${isPriceDropdownOpen ? 'rotate' : ''}`} />
            <div className='filled'>
                {(priceFrom.length > 0 || priceTo.length > 0) && <p>{t('Price (AED)')}</p>}
            </div>
            <div className={`filters-container__price-dropdown ${isPriceFilled ? 'field-filled' : ''}`}>
                <span>{isPriceFilled ? `${priceFrom} - ${priceTo}` : t('Price (AED)')}</span>
            </div>
            {isPriceDropdownOpen && (
                <div className='filters-container__price-dropdown__list' onClick={handlePriceInputClick}>
                    <div className='filters-container__price-dropdown__list-container'>
                        <div className='filters-container__price-dropdown__list-container__from'>
                            <p>{t('from')}</p>
                            <input
                                placeholder=''
                                value={priceFrom}
                                onChange={(event) => handlePriceInputChange('from', event)}
                            />
                        </div>
                        <div className='filters-container__price-dropdown__list-container__to'>
                            <p>{t('to')}</p>
                            <input
                                placeholder=''
                                value={priceTo}
                                onChange={(event) => handlePriceInputChange('to', event)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
PriceFilter.propTypes = {
    isPriceActive: PropTypes.bool.isRequired,
    isPriceDropdownOpen: PropTypes.bool.isRequired,
    priceFrom: PropTypes.string.isRequired,
    priceTo: PropTypes.string.isRequired,
    handlePriceInputClick: PropTypes.func.isRequired,
    handlePriceInputChange: PropTypes.func.isRequired,
    togglePriceDropdown: PropTypes.func.isRequired,
};

export default PriceFilter;
