import React from 'react';
import { useTranslation } from 'react-i18next';
import Instagram from '../../assets/images/networks/instagram.svg';
import Facebook from '../../assets/images/networks/facebook.svg';
import Twitter from '../../assets/images/networks/twitter.svg';
import Telegram from '../../assets/images/networks/telegram.svg';
import WhatsApp from '../../assets/images/networks/whatsapp.svg';
import './styles/styles.css';
import './styles/@styles.css';

const Footer = () => {
    const { t } = useTranslation();

    const getSectionLink = (section) => {
        const baseUrl = 'https://connect.tw/';
        return `${baseUrl}?section=${section}`;
    };

    return(
        <div className='footer'>
            <div className='footer-container'>
                <div className='footer-container__content'>
                    <div className='footer-container__content-logo'>
                        <span>connect.</span>
                    </div>
                    <div className='footer-container__content-links'>
                        <a href={getSectionLink('about')} target='_blank' rel='noopener noreferrer'>
                            {t('About')}
                        </a>
                        <a href={getSectionLink('footer')} target='_blank' rel='noopener noreferrer'>
                            {t('Contacts')}
                        </a>
                    </div>
                    <div className='footer-container__content-network'>
                        <div className='footer-container__content-network__links'>
                            <a href='/'><img src={Instagram} alt=''/></a>
                            <a href='/'><img src={Facebook} alt=''/></a>
                            <a href='/'><img src={Twitter} alt=''/></a>
                            <a href='/'><img src={Telegram} alt=''/></a>
                            <a href='/'><img src={WhatsApp} alt=''/></a>

                        </div>
                    </div>
                </div>
                <div className='footer-container__policy'>
                    <a href='https://connect.tw/privacy-policy' target = '_blank' rel="noopener noreferrer">{t('Privacy Policy')}</a>
                </div>
                <div className='footer-container__copyright'>
                    <span>{t('© 2023 all rights reserved')}</span>
                </div>
            </div>
        </div>
    )
}

export default Footer;
