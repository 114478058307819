import axios from 'axios';
import { getTokens } from './auth_token';

// Get agents by id
export const getAgent = async (id) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.get(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/users/${id}`,
            config
        );

        return response.data;

    } catch (error) {
        console.error('Error when performing a GET request to get user:', error);
        throw error;
    }
};

// Get agents
export const getAgents = async (userData) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/users`,
            userData,
            config
        );

        return response.data;

    } catch (error) {
        console.error('Error when performing a POST request to create user:', error);
        throw error;
    }
};

// Delete agent by ID
export const deleteAgent = async (id) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.delete(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/users/${id}`,
            config
        );

        return response.data;

    } catch (error) {
        console.error('Error when performing a DELETE request to delete user:', error);
        throw error;
    }
};

// Update agent by id
export const updateAgent = async (id, updatedUserData) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.patch(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/users/${id}`,
            updatedUserData,
            config
        );

        return response.data;

    } catch (error) {
        console.error('Error when performing a PATCH request to update user:', error);
        throw error;
    }
};

// Get agent languages
export const getAgentLanguages = async () => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.get(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/users/languages`,
            config
        );

        return response.data;

    } catch (error) {
        console.error('Error when performing a GET request to fetch user languages:', error);
        throw error;
    }
};
