import React from 'react';
import PersonalData from "../../../components/my_profile";

const Main = () => {

    return(
        <div>
            <PersonalData/>
        </div>
    )
}

export default Main;
