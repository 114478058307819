import axios from 'axios';
import { getTokens } from './auth_token';

// Get property type
export const getPropertyType = async () => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        // eslint-disable-next-line no-undef
        return await axios.get(process.env.REACT_APP_API_URL + `/api/v1/objects/propertyTypes`, config);
    } catch (error) {
        console.error("Error when performing a GET request to /api/object/property:", error);
        throw error;
    }
};

// Get property objects using POST with filters
export const getPropertyObjects = async (filters, page) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const size = 12;
        const pageToUse = page || 0;
        const offsetToUse = (pageToUse - 1) * size || 0;
        const storedSortOption = localStorage.getItem('selectedSortOption');
        const sortToUse = storedSortOption === 'Lowest Price' ? 'price,asc' : 'price,desc';

        if (filters && filters.location) {
            const locationResponse = await searchByCity(filters.location);
            if (locationResponse && locationResponse.location !== filters.location) {
                console.warn('Warning: The response location does not match the selected location.');
            }
            return { content: locationResponse.content, totalPages: 1 };
        }

        const data = {
            ...filters,
            status: ['ACTIVE']
        };

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/objects?page=${pageToUse}&size=${size}&offset=${offsetToUse || 0}&startPage=${pageToUse || 0}&sort=${sortToUse || 0}`,
            data,
            config
        );

        const responseData = response.data;
        if (!responseData) {
            throw new Error('Response data is undefined');
        }

        const totalPages = responseData.totalPages;

        return { content: responseData.content, totalPages };
    } catch (error) {
        console.error('Error when performing a POST request to /api/v1/objects:', error);
        throw error;
    }
};

//Get objects consideration for admin
export const getPropertyObjectsConsiderationAdmin = async (page) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const size = 12;
        const pageToUse = page || 0;
        const offsetToUse = (pageToUse - 1) * size || 0;

        const data = {
            status: ['CONSIDERATION']
        };
        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/objects?page=${pageToUse}&size=${size}&offset=${offsetToUse || 0}&startPage=${pageToUse || 0}`,
            data,
            config
        );

        const responseData = response.data;
        if (!responseData) {
            throw new Error('Response data is undefined');
        }
        const totalPages = responseData.totalPages;
        return { content: responseData.content, totalPages };
    } catch (error) {
        console.error('Error when performing a POST request to /api/v1/objects:', error);
        throw error;
    }
};

//Get objects active for admin
export const getPropertyObjectsActiveAdmin = async (page) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const size = 12;
        const pageToUse = page || 0;
        const offsetToUse = (pageToUse - 1) * size || 0;

        const data = {
            status: ['ACTIVE'],
        };
        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/objects?page=${pageToUse}&size=${size}&offset=${offsetToUse || 0}&startPage=${pageToUse || 0}`,
            data,
            config
        );

        const responseData = response.data;
        if (!responseData) {
            throw new Error('Response data is undefined');
        }
        const totalPages = responseData.totalPages;
        return { content: responseData.content, totalPages };
    } catch (error) {
        console.error('Error when performing a POST request to /api/v1/objects:', error);
        throw error;
    }
};

// Search property objects by city
export const searchByCity = async (city) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/objects?city=${city}`,
            { city },
            config
        );

        const uniqueCities = new Set();
        const filteredData = response.data.content.filter(obj => {
            if (obj.status === 'ACTIVE' && !uniqueCities.has(obj.city)) {
                uniqueCities.add(obj.city);
                return true;
            }
            return false;
        });

        return { content: filteredData };
    } catch (error) {
        console.error(`Error when performing a POST request to /api/v1/objects/search:`, error);
        throw error;
    }
};

// Get object by id
export const getObjectById = async (objectId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        // eslint-disable-next-line no-undef
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/v1/objects/${objectId}`, config);
        return response.data;
    } catch (error) {
        console.error(`Error when performing a GET request to /api/v1/objects/${objectId}:`, error);
        throw error;
    }
};

// Get object by status with pagination
export const getObjectsByStatus = async (status, userId, page) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const size = 15;
        const pageToUse = page || 0;
        const offsetToUse = (pageToUse - 1) * size || 0;

        const data = {
            status: [status],
            users: [userId],
        };

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/objects?page=${pageToUse}&size=${size}&offset=${offsetToUse}`,
            data,
            config
        );

        const responseData = response.data;
        if (!responseData) {
            throw new Error('Response data is undefined');
        }

        return responseData;
    } catch (error) {
        console.error('Error when performing a POST request to /api/v1/objects/status:', error);
        throw error;
    }
};

// Create property object
export const createObject = async (objectData) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const response = await axios.put(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + '/api/v1/objects',
            objectData,
            config
        );

        return response.data;
    } catch (error) {
        console.error("Error when performing a PUT request to /api/v1/objects:", error);
        throw error;
    }
};

// Edit object by id
export const updateObjectById = async (objectId, updatedData) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.patch(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_API_URL}/api/v1/objects/${objectId}`,
            updatedData,
            config
        );

        return response.data;
    } catch (error) {
        console.error(`Error when performing a PATCH request to /api/v1/objects/${objectId}:`, error);
        throw error;
    }
};

// Delete object by id
export const deleteObjectById = async (objectId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        // eslint-disable-next-line no-undef
        const response = await axios.delete(process.env.REACT_APP_API_URL + `/api/v1/objects/${objectId}`, config);
        return response.data;
    } catch (error) {
        console.error(`Error when performing a DELETE request to /api/v1/objects/${objectId}:`, error);
        throw error;
    }
};

// Archive object by id
export const archiveObjectById = async (objectId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };
        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/objects/${objectId}/archive`,
            null,
            config
        );
        return response.data;
    } catch (error) {
        console.error(`Error when performing a POST request to /api/v1/objects/${objectId}/archive:`, error);
        throw error;
    }
};

// Publish object by id
export const publishObjectById = async (objectId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/objects/${objectId}/publish`,
            null,
            config
        );

        return response.data;
    } catch (error) {
        console.error(`Error when performing a POST request to /api/v1/objects/${objectId}/publish:`, error);
        throw error;
    }
};

//Submit object by id
export const submitObjectById = async (objectId) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/objects/${objectId}/submit`,
            null,
            config
        );

        return response.data;
    } catch (error) {
        console.error(`Error when performing a POST request to /api/v1/objects/${objectId}/submit:`, error);
        throw error;
    }
};

//Dismiss object by id
export const dismissObjectById = async (objectId, dismissObject) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/objects/${objectId}/dismiss`,
            dismissObject,
            config
        );

        return response.data;
    } catch (error) {
        console.error(`Error when performing a POST request to /api/v1/objects/${objectId}/submit:`, error);
        throw error;
    }
};

// Object for revision
export const objectForRevision = async (id, objectData) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_API_URL}/api/v1/objects/${id}/forRevision`,
            objectData,
            config
        );

        return response.data;
    } catch (error) {
        console.error("Error when performing a POST request to /api/v1/objects/{id}/forRevision:", error);
        throw error;
    }
};
