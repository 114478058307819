import axios from 'axios';
import { getTokens } from './auth_token';

// Get media by ID
export const getMediaById = async (id) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        // eslint-disable-next-line no-undef
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/v1/media/${id}`, config);
        return response.data;
    } catch (error) {
        console.error(`Error when performing a GET request to /api/v1/media/${id}:`, error);
        throw error;
    }
};

// Get the list of all content types
export const getContentTypes = async () => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const response = await axios.get(
            // eslint-disable-next-line no-undef
        process.env.REACT_APP_API_URL + `/api/v1/media/content-types`,
            config
        );

        return response.data;
    } catch (error) {
        console.error('Error when performing a GET request to /api/v1/media/content-types:', error);
        throw error;
    }
};

// Get the list of all file types
export const getFileTypes = async () => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const response = await axios.get(
            // eslint-disable-next-line no-undef
        process.env.REACT_APP_API_URL + `/api/v1/media/file-types`,
            config
        );

        return response.data;
    } catch (error) {
        console.error('Error when performing a GET request to /api/v1/media/file-types:', error);
        throw error;
    }
};

// Generating links for downloading media
export const generateUploadLinks = async (resources) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const requestBody = {
            neededLinks: resources,
        };

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + `/api/v1/media/new-upload-link`,
            requestBody,
            config
        );

        return response.data.uploadLinks;
    } catch (error) {
        console.error('Error when generating upload links:', error);
        throw error;
    }
};

// Uploading a file to temporary storage
export const uploadFileToTempStorage = async (uploadURL, fileData) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'image/jpeg',
            },
        };

        const response = await axios.put(
            uploadURL,
            fileData,
            config
        );

        return response.data;
    } catch (error) {
        console.error('Error when uploading file to temp storage:', error);
        throw error;
    }
};

// File upload confirmation
export const confirmFileUpload = async (uploadData) => {
    try {
        const tokenToUse = getTokens();
        const config = {
            headers: {
                Authorization: `Bearer ${tokenToUse}`,
                'Content-Type': 'application/json',
            },
        };

        const response = await axios.post(
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_API_URL + '/api/v1/media/confirm-upload',
            uploadData,
            config
        );

        return response.data;
    } catch (error) {
        console.error('Error when confirming file upload:', error);
        throw error;
    }
};
