import React from 'react';
import PropTypes from 'prop-types';

const ContactButton = ({ icon, text, onClick }) => (
    <div className={`view-container__more-container__agent-contact__btns-btn ${icon}`}>
        <button onClick={onClick}>
            <img src={icon} alt='' />
            {text && <span>{text}</span>}
        </button>
    </div>
);

ContactButton.propTypes = {
    icon: PropTypes.string.isRequired,
    text: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export default ContactButton;
