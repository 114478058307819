import React from 'react';
import PropTypes from "prop-types";

const TabsContainer = ({ activeTab, handleTabChange, tabs }) => {
    return (
        <div className='favourites-container__tabs-container'>
            {tabs.map((tab) => (
                <div
                    key={tab.id}
                    className={`favourites-container__tabs-container__item ${activeTab === tab.id ? 'active-tab' : ''}`}
                    onClick={() => handleTabChange(tab.id)}
                >
                    {tab.label}
                </div>
            ))}
        </div>
    );
};
TabsContainer.propTypes = {
    activeTab: PropTypes.string.isRequired,
    handleTabChange: PropTypes.func.isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
};
export default TabsContainer;
