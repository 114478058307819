import { createContext, useContext, useState } from 'react';

const ObjectCountContext = createContext();

// eslint-disable-next-line react/prop-types
export const ObjectCountProvider = ({ children }) => {
    const [objectCount, setObjectCount] = useState(0);

    const updateObjectCount = (count) => {
        setObjectCount(count);
    };

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <ObjectCountContext.Provider value={{ objectCount, updateObjectCount }}>
            {children}
        </ObjectCountContext.Provider>
    );
};

export const useObjectCount = () => {
    return useContext(ObjectCountContext);
};
