/* eslint-disable */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    confirmFileUpload,
    generateUploadLinks,
    uploadFileToTempStorage
} from "../../../../action/media_api";
import Upload from "../../../../assets/images/icon/photo.svg";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {getObjectById} from "../../../../action/object_api";
import { MoonLoader } from "react-spinners";
import Title from "../../../../components/title";

const StepThree = ({ onPublish }) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [mediaFile, setMediaFile] = useState({
        architecture: [],
        interior: [],
        lobby: [],
    });
    const [imagePreviews, setImagePreviews] = useState({
        architecture: [],
        interior: [],
        lobby: [],
    });
    const [mediaFiles, setMediaFiles] = useState([]);
    const [errors, setErrors] = useState({
        architecture: '',
        interior: '',
        lobby: '',
    });
    const [loadingArchitecture, setLoadingArchitecture] = useState(false);
    const [loadingInterior, setLoadingInterior] = useState(false);
    const [loadingLobby, setLoadingLobby] = useState(false);

    useEffect(() => {
        const storedMediaFiles = JSON.parse(sessionStorage.getItem('mediaFiles'));
        if (storedMediaFiles) {
            setMediaFiles(storedMediaFiles);
            categorizeMediaFiles(storedMediaFiles);
        }
        const fetchObjectById = async (objectId) => {
            try {
                const object = await getObjectById(objectId);
                if (object.media) {
                    sessionStorage.setItem('mediaFiles', JSON.stringify(object.media));
                    setMediaFiles(object.media);
                    categorizeMediaFiles(object.media);
                }
            } catch (error) {
                console.error('Error fetching object:', error);
            }
        };

        if (id) {
            fetchObjectById(id);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleBeforeUnload = () => {
        sessionStorage.removeItem('mediaFiles');
    };
    const categorizeMediaFiles = (files) => {
        const categorizedFiles = {
            architecture: [],
            interior: [],
            lobby: [],
        };

        files.forEach(file => {
            const category = file.mediaType.split('_')[1].toLowerCase();
            categorizedFiles[category].push(file);
        });

        setMediaFile(categorizedFiles);
    };
    const handleGenerateUploadLinks = async (resources) => {
        try {
            return await generateUploadLinks(resources);
        } catch (error) {
            console.error('Error while generating upload links:', error);
            throw error;
        }
    };
    const handleUploadFileToTempStorage = async (uploadURL, fileData) => {
        try {
            await uploadFileToTempStorage(uploadURL, fileData);
        } catch (error) {
            console.error('Error while uploading file to temp storage:', error);
            throw error;
        }
    };
    const handleConfirmFileUpload = async (uploadData, entityId) => {
        try {
            const confirmedUpload = await confirmFileUpload({
                ...uploadData,
                entityId: entityId,
            });
            const storedMediaFiles = JSON.parse(sessionStorage.getItem('mediaFiles'));
            const updatedMediaFiles = storedMediaFiles ? [...storedMediaFiles, confirmedUpload] : [confirmedUpload];
            sessionStorage.setItem('mediaFiles', JSON.stringify(updatedMediaFiles));
            setMediaFiles(updatedMediaFiles);

            return confirmedUpload;
        } catch (error) {
            console.error('Error while confirming file upload:', error);
            throw error;
        }
    };
    const handlePublish = () => {
        let hasErrors = false;

        Object.keys(mediaFile).forEach(category => {
            if (mediaFile[category].length === 0) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [category]: t(`Please select an ${category} photo.`),
                }));
                hasErrors = true;
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [category]: '',
                }));
            }
        });

        if (!hasErrors) {
            onPublish();
        }
    };
    const handleFileUpload = (category) => {
        const fileInput = document.getElementById(`fileInput_${category}`);
        fileInput.click();
    };
    const handleFileChange = async (e, category) => {
        const files = e.target.files;
        switch (category) {
            case 'architecture':
                setLoadingArchitecture(true);
                break;
            case 'interior':
                setLoadingInterior(true);
                break;
            case 'lobby':
                setLoadingLobby(true);
                break;
            default:
                break;
        }
        try {
            const uploadLinks = await handleGenerateUploadLinks(
                Array.from(files).map(file => ({
                    contentType: 'image/jpeg',
                    extension: file.name.split('.').pop(),
                }))
            );

            const uploadPromises = Array.from(files).map(async (file, index) => {
                await handleUploadFileToTempStorage(uploadLinks[index].uploadURL, file);
                return handleConfirmFileUpload({
                    uploadURL: uploadLinks[index].uploadURL,
                    originalFilename: uploadLinks[index].originalFilename,
                    mediaType: `ACCOMMODATION_${category.toUpperCase()}`,
                    fileType: 'IMAGE',
                    token: uploadLinks[index].token,
                }, uploadLinks[index].entityId);
            });

            const confirmedUploads = await Promise.all(uploadPromises);
            setMediaFile((prevFiles) => ({
                ...prevFiles,
                [category]: [...prevFiles[category], ...confirmedUploads],
            }));

            setErrors(prevErrors => ({
                ...prevErrors,
                [category]: '',
            }));

            e.target.value = null;
        } catch (error) {
            console.error('Error handling file change:', error);
            setErrors(prevErrors => ({
                ...prevErrors,
                [category]: t('Error occurred while uploading photo.'),
            }));
        } finally {
            setLoadingArchitecture(false);
            setLoadingInterior(false);
            setLoadingLobby(false);
        }
    };
    const handleRemoveFile = (fileIndex, category) => {
        const storedMediaFiles = JSON.parse(sessionStorage.getItem('mediaFiles'));
        const removedFile = mediaFile[category][fileIndex];
        setMediaFile((prevFiles) => ({
            ...prevFiles,
            [category]: prevFiles[category].filter((_, index) => index !== fileIndex),
        }));
        const updatedMediaFiles = storedMediaFiles.filter(file => file.id !== removedFile.id);
        sessionStorage.setItem('mediaFiles', JSON.stringify(updatedMediaFiles));
        setMediaFiles(updatedMediaFiles);
        setImagePreviews((prevPreviews) => {
            const updatedPreviews = [...prevPreviews[category]];
            updatedPreviews.splice(fileIndex, 1);
            return {
                ...prevPreviews,
                [category]: updatedPreviews,
            };
        });
    };

    return (
        <div className='three'>
            <Title text='Images'/>
            <div className='three-container'>
                {Object.keys(mediaFile).map((category) => (
                    <div className={`three-container__item ${category}`} key={category}>
                        <span>{t(`Upload a photo of the object's ${category}`)}</span>
                        <div className='three-container__item-upload' onClick={() => handleFileUpload(category)}>
                            {category === 'architecture' && loadingArchitecture ? (
                                <MoonLoader color={'#E7C78B'} size={20} loading={true} />
                            ) : category === 'interior' && loadingInterior ? (
                                <MoonLoader color={'#E7C78B'} size={20} loading={true} />
                            ) : category === 'lobby' && loadingLobby ? (
                                <MoonLoader color={'#E7C78B'} size={20} loading={true} />
                            ) : (
                                <img src={Upload} alt='' />
                            )}
                            <input
                                id={`fileInput_${category}`}
                                type='file'
                                accept='image/*'
                                style={{ display: 'none' }}
                                onChange={(e) => handleFileChange(e, category)}
                                multiple
                            />
                        </div>
                        <div className='three-container__item-files'>
                            {mediaFile[category].map((file, index) => (
                                <div className='three-container__item-files__selected' key={index}>
                                    <img src={file.storageFileName} alt={file.name} />
                                    <div className='three-container__item-files__selected-remove' onClick={() => handleRemoveFile(index, category)}>
                                        &#10006;
                                    </div>
                                </div>
                            ))}
                        </div>
                        {errors[category] && <div className='error-message'>{errors[category]}</div>}
                    </div>
                ))}
            </div>
            <div className='three-btn'>
                <button onClick={handlePublish}>{t('Publish')}</button>
            </div>
        </div>
    );
};
StepThree.propTypes = {
    onPublish: PropTypes.func.isRequired,
};

export default StepThree;
