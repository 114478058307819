/* eslint-disable */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Save from '../../../assets/images/btns/save_search.svg';
import Remove from '../../../assets/images/btns/remove.svg';
import Clear from '../../../assets/images/btns/clear.svg';
import Saving from '../../../assets/images/icon/saving.svg';
import {useTranslation} from "react-i18next";

const SavedFilters = ({ isFilterActive, savedFilters, removeFilter, clearAllFilters, handleSaveSearch }) => {
    const { t } = useTranslation();
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [filterChanged, setFilterChanged] = useState(false);

    const handleSaveSearchWithFeedback = async () => {
        try {
            await handleSaveSearch();
            setIsSaveSuccess(true);
            setTimeout(() => {
                setIsSaveSuccess(false);
            }, 3000);
        } catch (error) {
            console.error('Error saving filters:', error);
        }
    };

    return (
        isFilterActive && (
            <div className='filters-container__save'>
                <div className='filters-container__save-container'>
                    {savedFilters.city !== '' && (
                        <FilterBlock
                            key="city"
                            label="City"
                            value={savedFilters.city}
                            removeFilter={() => removeFilter('city')}
                        />
                    )}
                    {savedFilters.propertyType.length > 0 && (
                        <FilterBlock
                            key="propertyType"
                            label={t('Property Type')}
                            value={savedFilters.propertyType.map(type => t(type)).join(', ')}
                            removeFilter={() => removeFilter('propertyType')}
                        />
                    )}
                    {savedFilters.beds !== '' && (
                        <FilterBlock
                            key="beds"
                            label="Beds"
                            value={`${savedFilters.beds} ${t(savedFilters.beds > 1 ? 'Beds' : 'Bed')}`}
                            removeFilter={() => removeFilter('beds')}
                        />
                    )}
                    {savedFilters.baths !== '' && (
                        <FilterBlock
                            key="baths"
                            label="Baths"
                            value={`${savedFilters.baths} ${t(savedFilters.baths > 1 ? 'Baths' : 'Bath')}`}
                            removeFilter={() => removeFilter('baths')}
                        />
                    )}
                    {(savedFilters.priceFrom !== '' || savedFilters.priceTo !== '') && (
                        <FilterBlock
                            label={t('Price')}
                            value={`${t('Price')}: ${savedFilters.priceFrom} - ${savedFilters.priceTo}`}
                            removeFilter={() => {
                                removeFilter('priceFrom');
                                removeFilter('priceTo');
                            }}
                        />
                    )}
                    {(savedFilters.areaFrom !== '' || savedFilters.areaTo !== '') && (
                        <FilterBlock
                            label={t('Area')}
                            value={`${t('Area')}: ${savedFilters.areaFrom} - ${savedFilters.areaTo}`}
                            removeFilter={() => {
                                removeFilter('areaFrom');
                                removeFilter('areaTo');
                            }}
                        />
                    )}
                </div>
                <div className='filters-container__save-btns'>
                    <div className='filters-container__save-btns__clear'>
                        <button onClick={clearAllFilters}>
                            <img src={Clear} alt=''/>
                            <span>{t('Clear All')}</span>
                        </button>
                    </div>
                    <div className='filters-container__save-btns__btn'>
                        <button onClick={handleSaveSearchWithFeedback}
                                className={isSaveSuccess ? 'saved' : ''}
                        >
                            <img src={Save} alt='' />
                            <span>
                                {isSaveSuccess
                                    ? t('Search Saved')
                                    : t('Save Search')}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};
const FilterBlock = ({ label, value, removeFilter }) => (
    <div className={`filters-container__save-container__filter ${label.toLowerCase()} ${value.length === 0 ? 'property-empty' : ''}`}>
        <span>{value}</span>
        <button
            className='remove-filter-button'
            onClick={removeFilter}
        >
            <img src={Remove} alt=''/>
        </button>
    </div>
);
SavedFilters.propTypes = {
    isFilterActive: PropTypes.bool.isRequired,
    savedFilters: PropTypes.object.isRequired,
    selectedPropertyTypeOptions: PropTypes.array.isRequired,
    priceTo: PropTypes.number.isRequired,
    areaTo: PropTypes.number.isRequired,
    removeFilter: PropTypes.func.isRequired,
    clearAllFilters: PropTypes.func.isRequired,
    handleSaveSearch: PropTypes.func.isRequired,
};

export default SavedFilters;
