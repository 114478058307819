import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {getAgent} from '../../action/agent_api';
import {useTranslation} from 'react-i18next';
import Back from '../../assets/images/btns/left.svg';
import Call from '../../assets/images/icon/phone_black.svg';
import WhatsApp from '../../assets/images/btns/whatsapp_black.svg';
import './styles/styles.css';
import './styles/@styles.css';

const AgentInfo = () =>{
    const { t } = useTranslation();
    const { id } = useParams();
    const [agent, setAgent] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAgent = async () => {
            try {
                const agentData = await getAgent(id);
                setAgent(agentData);
                console.log('agentData', agentData)
            } catch (error) {
                console.error('Error fetching agent:', error);
            }
        };

        fetchAgent();
    }, [id]);

    const callNumber = () => {
        if (agent && agent.phone) {
            window.location.href = `tel:${agent.phone}`;
        }
    };
    const callWhatsapp = () => {
        if (agent && agent.whatsapp) {
            window.open(`https://wa.me/${agent.whatsapp}`, '_blank');
        }
    };

    return(
        <div className='agent'>
            <div className='agent-container'>
                <div className='back'>
                    <button onClick={() => navigate('/')}><img src={Back} alt=''/>{t('Back')}</button>
                </div>
                <div className='agent-container__content'>
                    <div className='agent-container__content-avatar'>
                        {agent && agent.avatar ? (
                            <img src={agent.avatar.storageFileName} alt='' />
                        ) : (
                            <div className='agent-container__content-avatar__not-photo'>
                                <span>
                                    {agent && agent.firstName && agent.firstName.charAt(0)}
                                    {agent && agent.lastName && agent.lastName.charAt(0)}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className='agent-container__content-container'>
                        <div className='agent-container__content-container__content'>
                            <div className='agent-container__content-container__content-name'>
                                <span>{agent && agent.displayName}</span>
                            </div>
                            <div className='agent-container__content-container__content-lng'>
                                <p>{t('Speaks')}</p>
                                <span>:&nbsp;{agent && agent.languages && agent.languages.join(', ')}</span>
                            </div>
                            <div className='agent-container__content-container__content-agency'>
                                <p>{t('Agency')}</p>
                                <span>:&nbsp;{agent && agent.agency}</span>
                            </div>
                        </div>
                        <div className='agent-container__content-container__btns'>
                            <button onClick={callNumber}><img src={Call} alt=''/><span>{t('toCall')}</span></button>
                            {agent && agent.whatsapp !== '' && (
                                <button onClick={callWhatsapp}><img src={WhatsApp} alt=''/><span>{t('WhatsApp')}</span></button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AgentInfo;
